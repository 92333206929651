import axios from 'axios';
import useAPI from '../utils/useAPI';
import CommonFunctions from '../utils/commonFunctions';
import EventTracking from '../utils/eventTracking';
import CustomerUtils from '../components/mainContainer/customerComponent/CustomerUtils';
import PreConfirmationUtils from '../components/mainContainer/preconfirmation/PreConfirmationUtils';
import {
  STAFFKEY,
  APPT_KEY,
  PAGE_DETAILS,
  APP_VERSION,
  TOKEN,
  BRAND_ID,
  IS_SE_SLOT,
  HEADERS,
  BASEURL,
  IS_PWA_ENABLED,
  SHOW_PWA_POPUP_CONFIRMATION,
} from '../utils/constants';
import store from './store';

// List of action creators returns actions
export const setA2HSPopup = (payload) => {
  return {
    type: 'A2HS_POPUP',
    payload,
  };
};

export const setConfirmationPWA = (payload) => {
  return {
    type: 'CONFIRMATION_PWA',
    payload,
  };
};

export const setIsPWAAlreadyInstalled = (payload) => {
  return {
    type: 'IS_PWA_ALREADY_INSTALLED',
    payload,
  };
};

export const stepProgress = (step) => {
  if ((IS_PWA_ENABLED || window.isPWARedirect) && step === 'confirmation') {
    if (window.isMobile.matches && SHOW_PWA_POPUP_CONFIRMATION) {
      store.dispatch(setConfirmationPWA(true));
    }
  }
  return {
    type: 'STEP',
    payload: step,
  };
};

export const fetchViewSettings = () => {
  return async (dispatch) => {
    const response = await useAPI('/newbookingpage/view', 'GET');

    const { view } = response;
    if (view && !view.menu) {
      view.menu = [];
    }

    dispatch({
      type: 'FETCH_VIEW_SETTINGS',
      payload: view,
    });
  };
};

export const fetchCompanyDetails = (companyDetails, bannerUrl) => {
  if (!companyDetails || !bannerUrl) {
    return async (dispatch) => {
      const response = await useAPI('/company/details', 'GET');
      if (response) {
        window.companyJson = response.companyDetails;
        window.companyJson.companyDate = new Date();
        dispatch({
          type: 'FETCH_COMPANY_DETAILS',
          payload: response.companyDetails,
        });

        if (response.bannerUrl) {
          dispatch({
            type: 'FETCH_BANNER',
            payload: response.bannerUrl,
          });
        }
      }
    };
  }
  return async (dispatch) => {
    dispatch({
      type: 'FETCH_COMPANY_DETAILS',
      payload: companyDetails,
    });

    dispatch({
      type: 'FETCH_BANNER',
      payload: bannerUrl,
    });
  };
};

export const updatePaypalScriptError = (value) => {
  return {
    type: 'PAYPAL_SCRIPT_FAILED',
    payload: value,
  };
};

export const fetchPaymentGatewayDetails = () => {
  return async (dispatch) => {
    const response = await useAPI('/payments/policy/details', 'GET');
    dispatch({
      type: 'FETCH_PAYMENT_DETAILS',
      payload: response.paymentGatewayInfo,
    });

    if (
      response?.paymentGatewayInfo?.isPaymentEnabled &&
      response.paymentGatewayInfo.isPaymentEnabled === 1 &&
      response.paymentGatewayInfo.gateWayType
    ) {
      switch (response.paymentGatewayInfo.gateWayType.toLowerCase()) {
        case 'paypal': {
          const authResponse = await useAPI('/payments/paypal/authInfo', 'GET');
          if (authResponse.response) {
            dispatch({
              type: 'FETCH_PAYMENT_AUTH',
              payload: authResponse.response,
            });
            CommonFunctions.validateAndLoadPaypal(
              authResponse,
              dispatch,
              updatePaypalScriptError,
            );
          }
          break;
        }

        case 'stripe': {
          const stripeApiResponse = await useAPI(
            '/payments/stripe/getstripepushablekey',
            'GET',
          );
          dispatch({
            type: 'GET_STRIPE_PUBLICKEY',
            payload: stripeApiResponse,
          });
          if (!window.location.host.includes('localhost')) {
            try {
              useAPI('/payments/register/apple/domain/stripe', 'POST', {
                domain_name: window.location.host,
              });
            } catch (error) {
              console.error(error);
            }
          }
          break;
        }

        case 'square': {
          const squareLocationResp = await useAPI(
            '/payments/squareLocationId',
            'GET',
          );
          dispatch({
            type: 'FETCH_SQUARE_DETAILS',
            payload: squareLocationResp.squareAccountInfo,
          });
          CommonFunctions.validateApplepay(squareLocationResp);
          break;
        }
        case 'lawpay': {
          const lawPayLocationResp = await useAPI(
            '/payments/getlawpaypublicKey',
            'GET',
            '',
            '',
            false,
          );
          dispatch({
            type: 'FETCH_LAWPAY_DETAILS',
            payload: lawPayLocationResp.authInfo,
          });
          break;
        }
        default:
          break;
      }
    }
  };
};

export const fetchSchedulingPolicy = () => {
  return async (dispatch) => {
    const response = await useAPI('/newbookingpage/policy', 'GET');

    dispatch({
      type: 'FETCH_SCHEDULING_POLICY',
      payload: response.policy,
    });
  };
};

export const getReviews = (cursor) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/creviews/${TOKEN}/?cursor=${cursor}`,
      'GET',
    );
    if (response) {
      dispatch({
        type: 'FETCH_REVIEWS',
        payload: response.response ? response.response.reviews : '',
      });

      dispatch({
        type: 'REVIEW_CURSOR',
        payload: !CommonFunctions.isNullOrEmpty(response.response.cursor)
          ? response.response.cursor
          : '',
      });
    }
  };
};

export const setSelectedService = (service) => {
  return {
    type: 'SELECTED_SERVICE',
    payload: service,
  };
};

export const setSelectedStaff = (key) => {
  return {
    type: 'SELECTED_STAFF',
    payload: key,
  };
};

export const fetchServices = (cursor, isSkipStaff) => {
  return async (dispatch) => {
    let serviceFetchURL =
      PAGE_DETAILS.isServicePage &&
      PAGE_DETAILS.serviceKey &&
      PAGE_DETAILS.serviceKey !== '@@serviceKey@@'
        ? `/newbookingpage/service/${PAGE_DETAILS.serviceKey}`
        : `/newbookingpage/service?range=100&cursor=${cursor}`;
    let response = '';
    if (PAGE_DETAILS.isStaffPage) {
      serviceFetchURL = `/newbookingpage/provider/service?brand=${BRAND_ID}&merchantId=${TOKEN}&scheduleType=SERVICE&provider=${STAFFKEY}&isDeleted=false&isPrivate=false`;
    }
    response = await useAPI(serviceFetchURL, 'GET');

    let serviceList = '';
    if (response) {
      serviceList = response.services ? response.services : '';
    }

    if (serviceList) {
      const servicesList = CommonFunctions.removePrivateServices(serviceList);

      // if (PAGE_DETAILS.isStaffPage) {
      //   servicesList = servicesList.filter((service) => {
      //     return service.provider.indexOf(STAFFKEY) !== -1;
      //   });
      // }

      dispatch({
        type: 'FETCH_SERVICES',
        payload: !CommonFunctions.isNullOrEmpty(servicesList)
          ? servicesList
          : [],
      });

      dispatch({
        type: 'FETCH_EVENT_SERVICES',
        payload: !CommonFunctions.isNullOrEmpty(servicesList)
          ? servicesList
          : [],
      });

      dispatch({
        type: 'SERVICE_CURSOR',
        payload: response.cursor ? response.cursor : '',
      });
      if (!response.cursor) {
        dispatch({
          type: 'REQUEST_PROCESSED',
          payload: true,
        });
      }
      if (PAGE_DETAILS.isServicePage && servicesList.length > 0) {
        dispatch({
          type: 'STEP',
          payload: isSkipStaff ? 'searchForAvailability' : 'staff',
        });

        dispatch({
          type: 'SELECTED_SERVICE',
          payload: !CommonFunctions.isNullOrEmpty(servicesList)
            ? servicesList
            : [],
        });
      }
    }
  };
};

export const getServiceCategory = (
  categoryOrder,
  cursor,
  existingServiceCategory,
) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/newbookingpage/category?range=50&cursor=${cursor}`,
      'GET',
    );

    if (response.category && response.category.length > 0) {
      const updatedServiceCategory = CommonFunctions.updateServiceCategories(
        existingServiceCategory,
        response.category,
      );

      const sortedServiceCategory = CommonFunctions.filterDuplicatesAndSort(
        updatedServiceCategory,
        categoryOrder.service,
        'id',
      );

      dispatch({
        type: 'FETCH_SERVICES_CATEGORY',
        payload: sortedServiceCategory,
      });
    }
    dispatch({
      type: 'SERVICE_CATEGORY_CURSOR',
      payload: response && response.cursor ? response.cursor : '',
    });
  };
};

export const getOrder = () => {
  return async (dispatch) => {
    const response = await useAPI(`/newbookingpage/category/order`, 'GET');

    dispatch({
      type: 'FETCH_ORDER',
      payload: response.order,
    });
  };
};

export const getProvider = (cursor, staffKey) => {
  return async (dispatch) => {
    const key = PAGE_DETAILS.isStaffPage ? STAFFKEY : staffKey || '';
    const providerFetchURL = key
      ? `/staff/${key}`
      : `/staff?cursor=${cursor}&size=200`;

    let response;
    const chunkSize = 30;
    const promises = [];
    const headers = HEADERS;
    const resp = [];
    if (
      PAGE_DETAILS.isServicePage ||
      PAGE_DETAILS.isClassPage ||
      PAGE_DETAILS.isCategoryPage
    ) {
      const requestURL = `${BASEURL}/staff/key`;

      for (let i = 0; i < key.length; i += chunkSize) {
        const chunk = key.slice(i, i + chunkSize);
        promises.push(axios.post(requestURL, chunk, headers));
      }
      const datas = await Promise.all(promises);
      datas.forEach((d) => {
        d.data.data.staff.forEach((staff) => {
          resp.push(staff);
        });
      });
      // response = await useAPI('/staff/key', 'POST', staffKey);
    } else {
      response = await useAPI(providerFetchURL, 'GET');
    }
    let staffList =
      response && response.staffList ? [...response.staffList] : [];
    if (APP_VERSION === 'v2' && !PAGE_DETAILS.isStaffPage) {
      if (response && response.staffList) {
        staffList = response.staffList.filter((staff) => staff.key !== TOKEN);
      }
    }
    let payload = staffList;
    if (
      PAGE_DETAILS.isServicePage ||
      PAGE_DETAILS.isClassPage ||
      PAGE_DETAILS.isCategoryPage
    ) {
      if (resp) {
        payload = resp;
      } else {
        payload = response.staff;
      }
    } else if (PAGE_DETAILS.isStaffPage || staffKey) {
      if (resp && resp.staff) {
        payload = [resp.staff];
      } else {
        payload = [response.staff];
      }
    }

    dispatch({
      type: 'FETCH_STAFF',
      payload,
    });

    dispatch({
      type: 'FETCH_EVENT_STAFF',
      payload,
    });

    if (
      !PAGE_DETAILS.isServicePage &&
      !PAGE_DETAILS.isClassPage &&
      !PAGE_DETAILS.isCategoryPage &&
      (PAGE_DETAILS.isStaffPage || staffKey)
    ) {
      dispatch({
        type: 'SELECTED_STAFF',
        payload: response.staff,
      });
      dispatch({
        type: 'SELECTED_CLASS_PROVIDER',
        payload: response.staff,
      });
    }
    dispatch({
      type: 'STAFF_CURSOR',
      payload: response?.cursor || '',
    });
  };
};

export const setCustomer = (customer) => {
  return {
    type: 'CUSTOMER',
    payload: customer,
  };
};

export const saveCustomer = (customer, previousCustomer) => {
  if (CommonFunctions.isNullOrEmpty(customer.key)) {
    return async (dispatch) => {
      const response = await useAPI(`/bookingpage/customer`, 'POST', customer);

      if (response?.data?.error || response?.data?.msg) {
        dispatch({ type: 'SAVE_CUSTOMER_ERROR', payload: response.data.msg });
      }

      dispatch({
        type: 'CUSTOMER',
        payload:
          typeof response === 'object' && 'customer' in response
            ? response.customer
            : '',
      });
      dispatch({
        type: 'PREVIOUS_CUSTOMER',
        payload:
          typeof response === 'object' && 'customer' in response
            ? response.customer
            : '',
      });
    };
  }
  const newCustomer = CustomerUtils.compareCustomer(customer, previousCustomer);
  if (!newCustomer.isSame) {
    return async (dispatch) => {
      if (customer) {
        if (APP_VERSION === 'v2') {
          newCustomer.newCustomer.source =
            PreConfirmationUtils.sourceOfBooking();
        }
        const response = await useAPI(
          `/bookingpage/customer/${customer.key}`,
          'PUT',
          newCustomer.newCustomer,
        );

        if (response?.data?.error || response?.data?.msg) {
          dispatch({ type: 'SAVE_CUSTOMER_ERROR', payload: response.data.msg });
        }

        dispatch({
          type: 'CUSTOMER',
          payload:
            typeof response === 'object' && 'customer' in response
              ? response.customer
              : '',
        });
        dispatch({
          type: 'PREVIOUS_CUSTOMER',
          payload:
            typeof response === 'object' && 'customer' in response
              ? response.customer
              : '',
        });
      }
    };
  }
  return {
    type: 'CUSTOMER',
    payload: customer,
  };
};

export const setCategoryKey = (key) => {
  return {
    type: 'CATEGORY_KEY',
    payload: key,
  };
};

export const setSelectedDateAndTime = (date) => {
  return {
    type: 'SELECTED_DATE',
    payload: date,
  };
};

export const setAppointmentComments = (comments) => {
  return {
    type: 'APPT_COMMENTS',
    payload: comments,
  };
};

export const showPageBlock = (block) => {
  return {
    type: 'DISPLAY_PAGE_BLOCK',
    payload: block,
  };
};

export const fetchAllTimezones = () => {
  return async (dispatch) => {
    const response = await useAPI(`/timezones/all`, 'GET', '', '', true);

    dispatch({
      type: 'FETCH_ALL_TIMEZONES',
      payload: response,
    });
  };
};

export const setLocalTimeZone = (timezone) => {
  return {
    type: 'SET_LOCAL_TIMEZONE',
    payload: timezone,
  };
};

export const showInvalidRequest = (errorMsg) => {
  return {
    type: 'INVALID_REQUEST',
    payload: errorMsg,
  };
};

export const showUnauthorizedMessage = (errorMsg) => {
  return {
    type: 'UNAUTHORIZED_MSG',
    payload: errorMsg,
  };
};
export const showInvalidPaypal = (errorMsg) => {
  return {
    type: 'INVALID_PAYPAL',
    payload: errorMsg,
  };
};

export const customerLogin = (customer) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/bookingpage/customer/login`,
      'POST',
      customer,
    );
    if (response && response.customer) {
      dispatch({
        type: 'CUSTOMER',
        payload: response.customer,
      });
      dispatch({
        type: 'PREVIOUS_CUSTOMER',
        payload: response.customer,
      });
    } else if (response) {
      const resp = 'data' in response ? response.data : response;
      if ('error' in resp && resp.error === 'unauthorized_request') {
        dispatch(
          showUnauthorizedMessage({
            title: window.lang.unauthorized_req,
            msg: window.lang.try_again,
            reload: true,
          }),
        );
      } else {
        dispatch({
          type: 'Error',
          payload: Object.prototype.hasOwnProperty.call(response, 'data')
            ? response.data
            : response,
        });
      }
    }
  };
};

export const createCustomer = (customer) => {
  const updatedCustomer = { ...customer };
  if (APP_VERSION === 'v2') {
    updatedCustomer.source = PreConfirmationUtils.sourceOfBooking();
  }
  return async (dispatch) => {
    const response = await useAPI(
      `/bookingpage/customer/register/${updatedCustomer.loginType}`,
      'POST',
      updatedCustomer,
    );
    if (response && response.customer) {
      dispatch({
        type: 'CUSTOMER',
        payload: response.customer ? response.customer : '',
      });
      dispatch({
        type: 'PREVIOUS_CUSTOMER',
        payload: response.customer ? response.customer : '',
      });
    } else if (response) {
      const resp = 'data' in response ? response.data : response;
      if ('error' in resp && resp.error === 'unauthorized_request') {
        dispatch(
          showUnauthorizedMessage({
            title:
              resp.error === 'unauthorized_request'
                ? window.lang.unauthorized_req
                : 'Something went wrong',
            msg: window.lang.try_again,
            reload: true,
          }),
        );
      } else if (
        'error' in resp &&
        (customer.loginType === 'google' || customer.loginType === 'facebook')
      ) {
        dispatch(
          showUnauthorizedMessage({
            title: 'Something went wrong',
            msg: window.lang.try_again,
            reload: true,
          }),
        );
      } else {
        dispatch({
          type: 'Error',
          payload: Object.prototype.hasOwnProperty.call(response, 'data')
            ? response.data
            : response,
        });
      }
    }
  };
};

export const fetchCustomer = (key) => {
  return async (dispatch) => {
    const response = await useAPI(`/bookingpage/customer/${key}`, 'GET');

    if (response && 'customer' in response && response.customer) {
      dispatch({
        type: 'CUSTOMER',
        payload:
          response.customer.Status.toLowerCase() === 'active'
            ? response.customer
            : '',
      });
      dispatch({
        type: 'PREVIOUS_CUSTOMER',
        payload:
          response.customer.Status.toLowerCase() === 'active'
            ? response.customer
            : '',
      });
    }
  };
};

export const loginButtonStepProgress = (login) => {
  return {
    type: 'LOGIN_BUTTON_STEP',
    payload: login,
  };
};

export const setErrorState = (error) => {
  return {
    type: 'Error',
    payload: error,
  };
};

export const setSortedStaffList = (staff) => {
  return {
    type: 'UPDATEDSTAFFLISTBYORDER',
    payload: staff,
  };
};

export const showBookingPolicy = (show) => {
  return {
    type: 'BOOKING_POLICY',
    payload: show,
  };
};

export const setDisplayResponsiveMenu = (show) => {
  return {
    type: 'RESPONSIVE_MENU',
    payload: show,
  };
};

export const fetchStaffWorkingHours = (key, resourceInOrder) => {
  return async (dispatch) => {
    const resp = [];
    if (!PAGE_DETAILS.isStaffPage) {
      const promises = [];
      const chunkSize = 15;
      let array = [];
      if (!CommonFunctions.isNullOrEmpty(resourceInOrder)) {
        array = resourceInOrder;
      } else {
        if (!CommonFunctions.isNullOrEmpty(key) && typeof key === 'string') {
          array.push(key);
        } else {
          array = key;
        }
      }
      const headers = HEADERS;

      try {
        const requestURL = `${BASEURL}/staff/workingHours/key`;
        for (let i = 0; i < array.length; i += chunkSize) {
          const chunk = array.slice(i, i + chunkSize);
          promises.push(axios.post(requestURL, chunk, headers));
        }
        const datas = await Promise.all(promises);
        datas.forEach(function (d) {
          d.data.data.staffsWorkingHours.forEach(function (staff) {
            resp.push(staff);
          });
        });

        if (resp) {
          let staffWorkingHours;
          if (
            PAGE_DETAILS.isServicePage ||
            PAGE_DETAILS.isClassPage ||
            PAGE_DETAILS.isCategoryPage
          ) {
            staffWorkingHours = resp;
          } else {
            staffWorkingHours = resp ? [...resp] : [];
          }
          dispatch({
            type: 'STAFF_WORKING_HOURS',
            payload: staffWorkingHours,
          });
          dispatch({
            type: 'STAFF_WORKING_HOURS_FETCH_PROCESSED',
            payload: true,
          });
        }
      } catch (er) {
        return er.response;
      }
    } else {
      const staffKey = !key ? STAFFKEY : key;
      const staffWorkingHoursURL = staffKey
        ? `/staff/hours/${staffKey}`
        : '/staff/workingHours/company';
      let response;
      if (
        PAGE_DETAILS.isServicePage ||
        PAGE_DETAILS.isClassPage ||
        PAGE_DETAILS.isCategoryPage
      ) {
        response = await useAPI('/staff/workingHours/key', 'POST', key);
      } else {
        response = await useAPI(staffWorkingHoursURL, 'GET');
      }
      if (response) {
        let staffWorkingHours;
        if (
          PAGE_DETAILS.isServicePage ||
          PAGE_DETAILS.isClassPage ||
          PAGE_DETAILS.isCategoryPage
        ) {
          staffWorkingHours = response.staffsWorkingHours;
        } else if (staffKey && response.staffsWorkingHours) {
          staffWorkingHours = [response.staffsWorkingHours];
        } else {
          staffWorkingHours = response.staffWorkingHours
            ? [...response.staffWorkingHours]
            : [];
        }
        dispatch({
          type: 'STAFF_WORKING_HOURS',
          payload: staffWorkingHours,
        });
        dispatch({
          type: 'STAFF_WORKING_HOURS_FETCH_PROCESSED',
          payload: true,
        });
      }
    }
    return resp;
  };
};

export const setChoosenStaffUnavailability = (unavailableDates) => {
  return {
    type: 'UNAVAILABLE_DATES',
    payload: unavailableDates,
  };
};

export const resetStaffUnavailability = () => {
  return {
    type: 'RESET_UNAVAILABLE_DATES',
  };
};

export const setMobilePopUpData = (data) => {
  return {
    type: 'MOBILE_POPUP',
    payload: data,
  };
};

export const fetchTimeZoneObjByID = (timeZonId) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/timezones?timeZoneID=${timeZonId}`,
      'GET',
      '',
      '',
      true,
    );
    dispatch({
      type: 'FETCH_TIMEZONE',
      payload: response,
    });
  };
};

export const setAppointment = (appt) => {
  return {
    type: 'APPOINTMENT',
    payload: appt.events[0],
  };
};
export const setCustomerProfileStep = (step) => {
  return {
    type: 'CUSTOMER_PROFILE_STEP',
    payload: step,
  };
};

export const resetFutureEventCursor = () => {
  return {
    type: 'FUTURE_EVENT_CURSOR',
    payload: '',
  };
};

export const resetPastEventCursor = () => {
  return {
    type: 'PAST_EVENT_CURSOR',
    payload: '',
  };
};

export const resetUpcomingEvents = () => {
  return {
    type: 'CUSTOMER_UPCOMING_APPOINTMENTS',
    payload: '',
  };
};

export const resetPastEvents = () => {
  return {
    type: 'CUSTOMER_PAST_APPOINTMENTS',
    payload: '',
  };
};

export const updateFutureEventRequest = (payload) => {
  return {
    type: 'FUTURE_EVENTS_REQUEST_PROCESSED',
    payload,
  };
};

export const updatePastEventRequest = (payload) => {
  return {
    type: 'PAST_EVENTS_REQUEST_PROCESSED',
    payload,
  };
};

export const setEventFetchInProgress = (payload) => {
  return {
    type: 'CUSTOMER_EVENT_REQUEST_IN_PROGRESS',
    payload,
  };
};

export const triggerCustomerEventFetchComplete = () => {
  return (dispatch) => {
    dispatch(updateFutureEventRequest(true));
    dispatch(updatePastEventRequest(true));
    dispatch({ type: 'CUSTOMER_EVENT_REQUEST_IN_PROGRESS', payload: false });
    if (PAGE_DETAILS.isReschedulePage)
      dispatch({
        type: 'CUSTOMER_PROFILE_STEP',
        payload: 'appointmentDetails',
      });
  };
};

export const isClassInventoryRequestProcessed = (payload) => {
  return {
    type: 'CLASS_INVENTORY_REQUEST_PROCESSED',
    payload,
  };
};

export const getClassInventory = (classKey, cursor, isCategoryPage) => {
  let response;
  let baseURL;
  if (!IS_SE_SLOT) {
    baseURL =
      window.APP_MODE === 'LIVE'
        ? 'https://schedule.setmore.com'
        : 'https://dev.setmore.info';
  }
  return async (dispatch) => {
    if (classKey) {
      const payload = {
        classes: classKey,
      };
      if (!IS_SE_SLOT) {
        response = await useAPI(
          `/bookingpage/slots/inventories`,
          'POST',
          payload,
        );
      } else {
        response = await useAPI(
          `/slots/setmore/inventories/${TOKEN}/classes/${classKey}`,
          'GET',
          null,
          baseURL,
          true,
        );
      }
    } else if (!IS_SE_SLOT) {
      response = await useAPI(
        `/bookingpage/slots/inventories/all?cursor=${cursor}`,
        'GET',
      );
    } else {
      console.log(baseURL);
      response = await useAPI(
        `/slots/setmore/inventories/merchants/${TOKEN}/0?limit=10&cursor=${cursor}`,
        'GET',
        null,
        baseURL,
        true,
      );
    }
    if (response) {
      dispatch({
        type: 'FETCH_CLASS_INVENTORY',
        payload: response.classes ? response.classes : [],
      });

      if (isCategoryPage) {
        dispatch({
          type: 'MANUAL_CLASS_INVENTORY_CURSOR',
          payload: cursor,
        });
        if (!('cursor' in response)) {
          dispatch(isClassInventoryRequestProcessed(true));
        }
      } else if ('classes' in response && response.classes.length > 0) {
        if (response.cursor) {
          dispatch({
            type: 'CLASS_INVENTORY_CURSOR',
            payload: response.cursor,
          });
        } else if (!('cursor' in response)) {
          dispatch({
            type: 'CLASS_INVENTORY_CURSOR',
            payload: '',
          });
        }
      } else {
        dispatch({
          type: 'CLASS_INVENTORY_CURSOR',
          payload: '',
        });
      }
      if (!('cursor' in response) || !response.cursor) {
        dispatch(isClassInventoryRequestProcessed(true));
      }
    } else {
      dispatch({
        type: 'FETCH_CLASS_INVENTORY',
        payload: [],
      });
    }
  };
};

export const updateClassInventory = (payload) => {
  return {
    type: 'UPDATE_CLASS_INVENTORY',
    payload,
  };
};

export const fetchClassByKeys = (keys, type) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/newbookingpage/classes/keys?includePrivate=true`,
      'POST',
      keys,
    );

    if (response && response.classes) {
      if (!type) {
        dispatch({
          type: 'FETCH_EVENT_SERVICES',
          payload: response.classes,
        });

        dispatch(triggerCustomerEventFetchComplete());
      } else {
        dispatch({
          type: 'FETCH_CLASSES',
          payload: response.classes,
        });
      }
    }
  };
};

export const getClassCategoryByKeys = (categoryKeys) => {
  return async (dispatch) => {
    const response = await useAPI(
      '/newbookingpage/classes/categories/keys',
      'POST',
      categoryKeys,
    );

    if (response && response.categories) {
      dispatch({
        type: 'FETCH_CLASS_CATEGORY',
        payload: response.categories,
      });
    }
  };
};

export const getClassByKeys = (classKeys) => {
  return async (dispatch) => {
    const response = await useAPI(
      '/newbookingpage/classes/keys',
      'POST',
      classKeys,
    );

    if (response && response.classes) {
      dispatch({
        type: 'FETCH_CLASSES',
        payload: response.classes,
      });
    }
  };
};

export const updateClassCategory = (payload) => {
  return {
    type: 'UPDATE_CLASS_CATEGORY',
    payload,
  };
};

export const updateClass = (payload) => {
  return {
    type: 'UPDATE_CLASSES',
    payload,
  };
};

export const fetchServicesByKeys = (keys, classKeys) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/newbookingpage/services/keys`,
      'POST',
      keys,
    );

    if (response && response.services) {
      dispatch({
        type: 'FETCH_EVENT_SERVICES',
        payload: response.services,
      });

      if (PAGE_DETAILS.isReschedulePage)
        dispatch({
          type: 'FETCH_SERVICES',
          payload: response.services,
        });

      if (classKeys.length > 0) dispatch(fetchClassByKeys(classKeys));
      else dispatch(triggerCustomerEventFetchComplete());
    }
  };
};

export const updateServiceList = (payload) => {
  return {
    type: 'UPDATE_SERVICES',
    payload,
  };
};

export const fetchStaffByKeys = (staffKeys, serviceKeys, classKeys) => {
  return async (dispatch) => {
    if (staffKeys.length > 0) {
      const response = await useAPI(`/staff/key`, 'POST', staffKeys);

      if (response && response.staff) {
        dispatch({
          type: 'FETCH_EVENT_STAFF',
          payload: response.staff,
        });

        if (PAGE_DETAILS.isReschedulePage)
          dispatch({
            type: 'FETCH_STAFF',
            payload: response.staff,
          });

        if (serviceKeys.length > 0)
          dispatch(fetchServicesByKeys(serviceKeys, classKeys));
        else if (classKeys.length > 0) dispatch(fetchClassByKeys(classKeys));
        else dispatch(triggerCustomerEventFetchComplete());
      }
    } else if (serviceKeys.length > 0) {
      dispatch(fetchServicesByKeys(serviceKeys, classKeys));
    } else if (classKeys.length > 0) {
      dispatch(fetchClassByKeys(classKeys));
    } else {
      dispatch(triggerCustomerEventFetchComplete());
    }
  };
};

export const fetchEventsByURL = (
  url,
  cursor,
  staffList,
  serviceList,
  baseURL,
  isSeComponent,
) => {
  return async (dispatch) => {
    const requestURL = cursor ? `${url}?cursor=${cursor}` : url;
    const response = await useAPI(
      requestURL,
      'GET',
      null,
      baseURL,
      isSeComponent,
    );

    if (response.events) {
      dispatch({
        type: 'SELECTED_APPOINTMENT',
        payload: response.events,
      });
      const customerKey =
        response.events.type === 'SESSION' && APPT_KEY
          ? APPT_KEY.split('_')[APPT_KEY.split('_').length - 1]
          : response.events.consumer[0];

      dispatch(fetchCustomer(customerKey));
      dispatch(fetchStaffWorkingHours(response.events.provider[0]));
    }

    if (response.error) {
      dispatch({
        type: 'SELECTED_APPOINTMENT',
        payload: 'Link Expired',
      });
      dispatch(triggerCustomerEventFetchComplete());
      return;
    }

    if (response.future) {
      dispatch({
        type: 'CUSTOMER_UPCOMING_APPOINTMENTS',
        payload: response.future.events,
      });
    }

    if (!cursor || cursor.indexOf('future') !== -1) {
      dispatch({
        type: 'FUTURE_EVENT_CURSOR',
        payload:
          response.future && response.future.cursor
            ? response.future.cursor
            : '',
      });
    }

    if (response.past) {
      dispatch({
        type: 'CUSTOMER_PAST_APPOINTMENTS',
        payload: response.past.events,
      });
    }

    if (!cursor || cursor.indexOf('past') !== -1) {
      dispatch({
        type: 'PAST_EVENT_CURSOR',
        payload:
          response.past && response.past.cursor ? response.past.cursor : '',
      });
    }

    if (response) {
      const futureEvents =
        response.future && response.future.events ? response.future.events : [];
      const pastEvents =
        response.past && response.past.events ? response.past.events : [];
      const events = response.events ? [response.events] : [];
      const appointments = [...futureEvents, ...pastEvents, ...events];
      const apptStaffKeys =
        CommonFunctions.getUniqueValuesByKeyFromArrOfObjects(
          appointments,
          'provider',
        );
      const apptServiceKeys =
        CommonFunctions.getUniqueValuesByKeyFromArrOfObjects(
          appointments.filter((appt) => appt.type !== 'SESSION'),
          'service',
        );
      const apptClassKeys =
        CommonFunctions.getUniqueValuesByKeyFromArrOfObjects(
          appointments.filter((appt) => appt.type === 'SESSION'),
          'service',
        );
      const staffKeys = CommonFunctions.getValuesByKeyFromArrOfObject(
        staffList,
        'key',
      );
      const serviceKeys = CommonFunctions.getValuesByKeyFromArrOfObject(
        serviceList,
        'id',
      );
      const unAvailableStaffKeys = CommonFunctions.getUniqueKeysFrom2Array(
        apptStaffKeys,
        staffKeys,
      );
      const unAvailableServiceKeys = CommonFunctions.getUniqueKeysFrom2Array(
        apptServiceKeys,
        serviceKeys,
      );

      if (
        unAvailableStaffKeys.length === 0 &&
        unAvailableServiceKeys.length === 0 &&
        apptClassKeys.length === 0
      ) {
        dispatch(triggerCustomerEventFetchComplete());
      } else {
        dispatch(
          fetchStaffByKeys(
            unAvailableStaffKeys,
            unAvailableServiceKeys,
            apptClassKeys,
          ),
        );
      }
    }
  };
};

export const setLeaseId = (leaseId) => {
  return {
    type: 'LEASE_ID',
    payload: leaseId,
  };
};
export const classStepProgress = (step) => {
  if ((IS_PWA_ENABLED || window.isPWARedirect) && step === 'confirmation') {
    if (window.isMobile.matches && SHOW_PWA_POPUP_CONFIRMATION) {
      store.dispatch(setConfirmationPWA(true));
    }
  }

  return {
    type: 'CLASS_STEP',
    payload: step,
  };
};

export const setSelectedClass = (selectedClass) => {
  return {
    type: 'SELECTED_CLASS',
    payload: selectedClass,
  };
};

export const setSelectedClassProvider = (selectedClassProvider) => {
  return {
    type: 'SELECTED_CLASS_PROVIDER',
    payload: selectedClassProvider,
  };
};

export const setSelectedDateAndTimeForClass = (selectedDate) => {
  return {
    type: 'SELECTED_DATE_FOR_CLASS',
    payload: selectedDate,
  };
};

export const setSelectedDateAndTimeForClassReschedule = (selectedDate) => {
  return {
    type: 'SELECTED_DATE_FOR_CLASS_RESCHEDULE',
    payload: selectedDate,
  };
};

export const setSelectedSession = (selectedSession) => {
  return {
    type: 'SELECTED_SESSION',
    payload: selectedSession,
  };
};

export const setPreviousSession = (selectedSession) => {
  return {
    type: 'PREVIOUS_SESSION',
    payload: selectedSession,
  };
};

export const showMobileCompanyLogo = (display) => {
  return {
    type: 'MOBILE_COMPANYLOGO',
    payload: display,
  };
};

export const setActiveTab = (tab) => {
  return {
    type: 'ACTIVE_TAB',
    payload: tab,
  };
};

export const setIsPaymentDone = (ispayment) => {
  return {
    type: 'PAYMENT_DONE',
    payload: ispayment,
  };
};

export const setIsStandaloneApp = (isStandaloneApp) => {
  return {
    type: 'IS_STANDALONE_APP',
    payload: isStandaloneApp,
  };
};

export const setResetTimer = (interval) => {
  return {
    type: 'TIMER_RESET',
    payload: interval,
  };
};

export const setSelectedAppointment = (appt) => {
  return {
    type: 'SELECTED_APPOINTMENT',
    payload: appt,
  };
};

export const setApptReschedule = (value) => {
  return {
    type: 'IS_APPT_RESCHEDULE',
    payload: value,
  };
};

export const setSessionReschedule = (value) => {
  return {
    type: 'IS_SESSION_RESCHEDULE',
    payload: value,
  };
};

export const setCancelInProgress = (payload) => {
  return {
    type: 'IS_CANCEL_IN_PROGRESS',
    payload,
  };
};

export const cancelAppointment = (
  eventId,
  eventType,
  upcomingEvents,
  customerKey,
) => {
  return async (dispatch) => {
    const requestURL =
      eventType === 'SESSION'
        ? `/newbookingpage/removeAttendee/${eventId}_${customerKey}`
        : `/bookingpage/events/appointment/${eventId}`;

    const response = await useAPI(requestURL, 'DELETE');

    if (
      response?.isDeleted === false ||
      response?.response?.status === 500 ||
      response?.response?.status === 400
    ) {
      dispatch(
        showInvalidRequest({
          title: "Hmm, there's been a glitch",
          msg:
            eventType === 'SESSION'
              ? "Your seat reservation couldn't be cancelled. Please try again later."
              : "Your appointment couldn't be cancelled. Please try again later.",
          buttonTitle: 'Got it',
          reload: true,
        }),
      );
      return;
    }

    const obj = {
      eventId,
      isDeleted: response.isDeleted,
    };
    dispatch({
      type: 'EVENT_CANCELLED',
      payload: obj,
    });
    dispatch(setCancelInProgress(false));

    const appts =
      upcomingEvents &&
      upcomingEvents.length > 0 &&
      upcomingEvents.filter((upcomingEvent) => upcomingEvent.id !== eventId);
    dispatch({
      type: 'CUSTOMER_UPCOMING_APPOINTMENTS_UPDATE',
      payload: appts,
    });
  };
};

export const setBookingId = (id) => {
  return {
    type: 'BOOKING_ID',
    payload: id,
  };
};

export const rescheduleAppointment = (event, upcomingEvents) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/bookingpage/events/appointment/${event.id}`,
      'PUT',
      event,
    );
    if (
      response &&
      'data' in response &&
      response.data &&
      'error' in response.data
    ) {
      dispatch(
        showInvalidRequest({
          title: window.lang.invalid_req,
          msg:
            response.data.msg === 'Staff no longer available for the service'
              ? window.lang.staff_unavailable_service
              : response.data.msg,
          reload: true,
        }),
      );
    }

    dispatch({
      type: 'EVENT_RESCHEDULE',
      payload: response ? response.event : '',
    });

    if (response.events) {
      dispatch({
        type: 'STEP',
        payload: 'confirmation',
      });

      const appts =
        upcomingEvents &&
        upcomingEvents.length > 0 &&
        upcomingEvents.map((obj) =>
          obj.id === response.events[0].id ? response.events[0] : obj,
        );
      dispatch({
        type: 'CUSTOMER_UPCOMING_APPOINTMENTS_UPDATE',
        payload: appts,
      });
      if (response.events[0]) {
        const bookingId =
          APP_VERSION === 'v2'
            ? response.events[0].bookingId
            : response.events[0].metaData.bookingId;

        dispatch(setBookingId(bookingId));
      }
    }
  };
};

// export const fetchInstagramUserDetails = () => {
//   return async (dispatch) => {
//     const response = await useAPI(
//       '/newbookingpage/photos/instagram/user',
//       'GET'
//     );
//     const hashTags =
//       CommonFunctions.getHashTagsFromInstagramUserDetails(response);
//     dispatch({
//       type: 'FETCH_INSTAGRAM_USER_DETAILS',
//       payload: hashTags,
//     });
//   };
// };

export const isInstaPhotosFetchInProgress = (isProcess) => {
  return {
    type: 'PHOTOS_REQEUST_PROCESSED',
    payload: isProcess,
  };
};

export const fetchInstagramUserPhotos = (cursor, clientHashTag) => {
  const queryParam = cursor
    ? `?mediaType=image&cursor=${cursor}`
    : '?mediaType=image';
  return async (dispatch) => {
    const response = await useAPI(
      `/newbookingpage/photos/instagram${queryParam}`,
      'GET',
    );

    const instagramPhotos = response?.data?.data || '';

    dispatch(isInstaPhotosFetchInProgress(false));

    dispatch({
      type: 'FETCH_INSTAGRAM_USER_PHOTOS',
      payload: instagramPhotos,
    });
    const paginationCursor = response?.data?.pagination?.cursor || '';
    dispatch({
      type: 'INSTAGRAM_PAGINATION',
      payload: paginationCursor,
    });
    if (
      paginationCursor &&
      (!instagramPhotos ||
        instagramPhotos.length === 0 ||
        instagramPhotos.length <= 8)
    ) {
      dispatch(setPhotosRequestProcessed(false));
      dispatch(fetchInstagramUserPhotos(paginationCursor, clientHashTag));
    }
  };
};

export const updatePhotosFromLocalStorage = (photos) => {
  return {
    type: 'FETCH_INSTAGRAM_USER_PHOTOS',
    payload: photos,
  };
};

export const fetchReviewRating = (rating) => {
  if (!rating) {
    return async (dispatch) => {
      const response = await useAPI(`/newbookingpage/rating`, 'GET');
      if (response && response.rating)
        dispatch({
          type: 'FETCH_REVIEW_RATING',
          payload: response,
        });
    };
  }
  return {
    type: 'FETCH_REVIEW_RATING',
    payload: rating,
  };
};

export const fetchReviewSettings = () => {
  return async (dispatch) => {
    const response = await useAPI(`/reviewsettings`, 'GET');
    if (response && response.reviewSettings)
      dispatch({
        type: 'FETCH_REVIEW_SETTINGS',
        payload: response.reviewSettings,
      });
  };
};

export const displayWriteReview = (payload) => {
  return { type: 'DISPLAY_WRITE_REVIEW', payload };
};

export const handleWriteReview = () => {
  return (dispatch) => {
    dispatch(displayWriteReview(true));
    dispatch(showPageBlock(true));
  };
};

// export const fetchEventTrackingIntegration = () => {
// return async (dispatch) => {
//   const response = await useAPI(`/integration/tracking`, 'GET');
//   if (response) {
//     dispatch({
//       type: 'GOOGLE_ANALYTICS',
//       payload: response.googleAnalyticsId,
//     });
//     dispatch({
//       type: 'FACEBOOK_PIXEL',
//       payload: response.facebookPixelId,
//     });
//     dispatch({
//       type: 'FACEBOOK_PAGE_ID',
//       payload: response.facebookPageId,
//     });
//     dispatch({
//       type: 'GTM_ID',
//       payload: response.googleTagManagerId,
//     });
//     if (window.APP_MODE === 'LIVE') {
//       if (
//         response &&
//         response.googleAnalyticsId &&
//         response.googleAnalyticsId.startsWith('UA-')
//       ) {
//         EventTracking.triggerGoogleAnalyticsEvent(response.googleAnalyticsId);
//       }
//       if (
//         response &&
//         response.googleAnalyticsId &&
//         response.googleAnalyticsId.startsWith('G-')
//       ) {
//         EventTracking.injectGA4Script(response.googleAnalyticsId);
//       }
//       EventTracking.addGoogleTagManagerScript(response.googleTagManagerId);
//       EventTracking.triggerFbPixelEvent(response.facebookPixelId);
//     }
//     dispatch({
//       type: 'IS_TRACKING_FETCHED',
//       payload: true,
//     });
//   }
// };
// };

export const setClassTimings = (photos) => {
  return {
    type: 'CLASS_TIMINGS',
    payload: photos,
  };
};

export const fetchBusinessHours = () => {
  return async (dispatch) => {
    const response = await useAPI('/company/workingHours', 'GET');
    dispatch({
      type: 'FETCH_BUSINESS_HOURS',
      payload: response.companyWorkingHours,
    });
  };
};

export const setClassBookingId = (id) => {
  return {
    type: 'CLASS_BOOKING_ID',
    payload: id,
  };
};

export const fetchReminderSettings = () => {
  return async (dispatch) => {
    const response = await useAPI('/reminder/settings/customer', 'GET');

    dispatch({
      type: 'REMINDER_SETTINGS',
      payload: response.reminder && JSON.parse(response.reminder),
    });
  };
};

// export const fetchBookingPageIntegrations = () => {
//   return async (dispatch) => {
//     const response = await useAPI(`/bookingpage/integrations`, 'GET');
//     if (response) {
//       dispatch({
//         type: 'VIDEO_CONFIGURATION',
//         payload: response.videoConfig,
//       });
//     }
//   };
// };

// export const fetchClassSMSAllowed = () => {
//   return async (dispatch) => {
//     const response = await axios.get(
//       `/fetchRestrictedProvision?companyKey=reminders`
//     );
//     if (response) {
//       dispatch({
//         type: 'REMINDERS_PROVISION',
//         payload: JSON.parse(response.data),
//       });
//     }
//   };
// };

export const setBookingPath = (path) => {
  return {
    type: 'BOOKING_PATH',
    payload: path,
  };
};

export const checkSlotsAvailability = (reqPayload) => {
  return async (dispatch) => {
    let response;
    if (!IS_SE_SLOT) {
      response = await useAPI(`/bookingpage/checkslot`, 'POST', reqPayload);
    } else {
      if (APP_VERSION === 'v2') {
        const slot = reqPayload.startTime;
        dispatch({
          type: 'SLOTS_AVAILABILITY',
          payload: { [slot]: true },
        });
        return;
      }
      response = await useAPI(
        `/slots/check_slot/${TOKEN}/${reqPayload.resourceKey}/${reqPayload.duration}`,
        'POST',
        { slots: reqPayload.startTime },
        '',
        true,
      );
    }

    if (response) {
      dispatch({
        type: 'SLOTS_AVAILABILITY',
        payload: response.slots,
      });
    }
  };
};

export const resetSlotAvailability = () => {
  return {
    type: 'SLOTS_AVAILABILITY',
    payload: '',
  };
};

export const setCustomerDropOffStep = (payload) => {
  return {
    type: 'TRACK_CUSTOMER_DROP_OFF',
    payload,
  };
};

export const fetchBySessionKey = (sessionKey) => {
  return async (dispatch) => {
    const response = await useAPI(`/bookingpage/events/${sessionKey}`, 'GET');
    if (response) {
      if ('events' in response) {
        dispatch({
          type: 'CLASS_SESSION',
          payload: response.events,
        });
      }
    }
  };
};

export const serviceFetchInProcess = () => {
  return {
    type: 'FETCH_SERVICES',
    payload: 'fetching',
  };
};

export const setBookingPage = (payload) => {
  return {
    type: 'BOOKING_PAGE',
    payload,
  };
};

export const fetchServicesByKeysForCat = (serviceKeys) => {
  return async (dispatch) => {
    const response = await useAPI(
      `/newbookingpage/services/keys`,
      'POST',
      serviceKeys,
    );

    if (response && response.services) {
      const servicesList = CommonFunctions.removePrivateServices(
        response.services,
      );
      dispatch({
        type: 'FETCH_SERVICES',
        payload: servicesList && servicesList.length > 0 ? servicesList : [],
      });
      // more service keys we should handle it
      dispatch({
        type: 'REQUEST_PROCESSED',
        payload: true,
      });
    }
  };
};

export const fetchCategory = (payload) => {
  const data = [payload];
  return async (dispatch) => {
    const response = await useAPI('/bookingpage/category', 'POST', data);
    if (
      typeof response === 'object' &&
      'category' in response &&
      response.category.length > 0
    ) {
      const serviceCategory = [];
      const classCategory = [];
      response.category.map((cat) => {
        if (cat.categoryType === 'SERVICE') {
          serviceCategory.push(cat);
        } else if (cat.categoryType === 'CLASS') {
          classCategory.push(cat);
        }
        return null;
      });

      if (serviceCategory.length > 0) {
        dispatch(setBookingPage('serviceCategory'));
        dispatch({
          type: 'FETCH_SERVICES_CATEGORY',
          payload: serviceCategory,
        });

        if (
          serviceCategory.length > 0 &&
          !serviceCategory[0].isDeleted &&
          'keys' in serviceCategory[0] &&
          serviceCategory[0].keys
        ) {
          dispatch(fetchServicesByKeysForCat(serviceCategory[0].keys));
        }
      }
      if (classCategory.length > 0) {
        dispatch(setBookingPage('classCategory'));

        dispatch({
          type: 'FETCH_CLASS_CATEGORY',
          payload: classCategory,
        });
      }
    }
    if (response.category.length === 0) {
      dispatch({
        type: 'FETCH_SERVICES_CATEGORY',
        payload: '',
      });
      dispatch({
        type: 'FETCH_CLASS_CATEGORY',
        payload: '',
      });
    }
  };
};

export const setSlotsForServices = (slots) => {
  return {
    type: 'SLOTS',
    payload: slots,
  };
};

export const updateSlotsForServices = (slots) => {
  return {
    type: 'UPDATE_SLOTS',
    payload: slots,
  };
};

export const showUnableToReschedule = (errorMsg) => {
  return {
    type: 'UNABLE_TO_RESCHEDULE',
    payload: errorMsg,
  };
};

export const resetErrorMessage = () => {
  return {
    type: 'RESET_ERROR_MESSAGE',
    payload: '',
  };
};

export const setStaffStep = (step) => {
  return {
    type: 'STAFF_STEP',
    payload: step,
  };
};

export const updateStaffClassInventory = (payload) => {
  return {
    type: 'UPDATE_STAFF_CLASS_INVENTORY',
    payload,
  };
};

export const setApptBookingFromStaffPage = (payload) => {
  return {
    type: 'IS_APPT_BOOKING_FROM_STAFF_PAGE',
    payload,
  };
};
export const setStaffNoPref = (isStaffNoPref) => {
  return {
    type: 'STAFF_NO_PREF',
    payload: isStaffNoPref,
  };
};

export const setPaymentLoader = (payload) => {
  return {
    type: 'PAYMENT_LOADER',
    payload,
  };
};

export const setSelectedServiceCategory = (payload) => {
  return {
    type: 'SELECTED_SERVICE_CATEGORY',
    payload,
  };
};

export const resetSelectedServiceCategory = () => {
  return {
    type: 'RESET_SELECTED_SERVICE_CATEGORY',
  };
};

export const setSelectedClassCategory = (payload) => {
  return {
    type: 'SELECTED_CLASS_CATEGORY',
    payload,
  };
};

export const resetSelectedClassCategory = () => {
  return {
    type: 'RESET_SELECTED_CLASS_CATEGORY',
  };
};

export const setPaymentStatus = (payload) => {
  return {
    type: 'PAYMENT_STATUS',
    payload,
  };
};

export const setPaymentLintItem = (payload) => {
  return {
    type: 'PAYMENT_LINE_ITEM',
    payload,
  };
};

export const setPreviousCustomer = (customer) => {
  return {
    type: 'PREVIOUS_CUSTOMER',
    payload: customer,
  };
};
export const setUserRef = (userref) => {
  return {
    type: 'USER_REF',
    payload: userref,
  };
};

export const setCheckBoxState = (checkboxState) => {
  return {
    type: 'FB_CHECKBOX_STATE',
    payload: checkboxState,
  };
};

export const fetchPromoResponse = (val) => {
  return async (dispatch) => {
    try {
      const data = await useAPI(`/payments/getcouponinfo/${val}`, 'GET');
      if (data && data.response) {
        dispatch({
          type: 'PROMO_RESPONSE',
          payload: data.response,
        });
      }
      if (data.response.error || !data.response.status) {
        dispatch({
          type: 'PROMO_RESPONSE',
          payload: {
            isValid: false,
            amountOff: '0',
          },
        });
      }
    } catch (er) {
      dispatch({
        type: 'PROMO_RESPONSE',
        payload: {
          isValid: false,
          amountOff: '0',
        },
      });
      return er.response;
    }
    return [];
  };
};
export const setPromoCode = (code) => {
  return {
    type: 'PROMO_CODE',
    payload: code,
  };
};
export const setPromoResponse = () => {
  return {
    type: 'PROMO_RESPONSE',
    payload: {
      isValid: false,
      amountOff: '0',
    },
  };
};
export const promoFetchInProcess = () => {
  return {
    type: 'PROMO_RESPONSE',
    payload: 'fetching',
  };
};

export const getAssignedProviders = (
  services,
  classInventoryList,
  staffList,
) => {
  let classProviderList = [];
  let serviceProviderList = [];
  if (services.length > 0) {
    const serviceProviders = [
      ...new Set(services.flatMap((service) => service.provider)),
    ];

    serviceProviderList = staffList.filter((staff) => {
      return serviceProviders.includes(staff.key);
    });
  }

  if (
    classInventoryList &&
    classInventoryList.length > 0 &&
    classInventoryList[0].staffAvailability &&
    classInventoryList[0].staffAvailability.length > 0
  ) {
    const providerList = [
      ...new Set(
        classInventoryList.flatMap((service) =>
          Object.keys(JSON.parse(service.staffAvailability)),
        ),
      ),
    ];
    classProviderList = staffList.filter((staff) => {
      return providerList.includes(staff.key);
    });
  }

  const assignedProviderList = Array.from(
    new Set([...serviceProviderList, ...classProviderList]),
  );

  return {
    type: 'GET_ASSIGNED_PROVIDERS',
    payload: assignedProviderList,
  };
};

export const fetchAllBookingPageIntegrations = () => {
  return async (dispatch) => {
    const resp = await useAPI('/newbookingpage/integrations', 'GET');
    const { response } = resp;
    if (response) {
      const { gtm_track_id: gtmTrackId = '' } =
        response.google_tag_manager || {};
      const { ga_track_id: gaTrackId = '' } = response.google_analytics || {};
      const { pixel_id: fbPixel = '' } = response.facebook || {};

      dispatch({
        type: 'GOOGLE_ANALYTICS',
        payload: gaTrackId,
      });

      dispatch({
        type: 'GTM_ID',
        payload: gtmTrackId,
      });

      dispatch({
        type: 'FACEBOOK_PIXEL',
        payload: fbPixel,
      });

      dispatch({
        type: 'FACEBOOK_PAGE_ID',
        payload: response.facebook?.pages?.[0] ?? '',
      });

      if (window.APP_MODE === 'LIVE') {
        if (gaTrackId) {
          if (gaTrackId.startsWith('UA-')) {
            EventTracking.triggerGoogleAnalyticsEvent(gaTrackId);
          } else if (gaTrackId.startsWith('G-')) {
            EventTracking.injectGA4Script(gaTrackId);
          }
        }
        if (gtmTrackId) {
          EventTracking.addGoogleTagManagerScript(gtmTrackId);
        }
        if (fbPixel) {
          EventTracking.triggerFbPixelEvent(fbPixel);
        }
      }

      dispatch({
        type: 'IS_TRACKING_FETCHED',
        payload: true,
      });

      if (response.instagram_streaming?.hashtags?.length > 0) {
        const hashTags = response.instagram_streaming.hashtags.map((hashTag) =>
          hashTag.replace(/#/g, ''),
        );
        dispatch({
          type: 'FETCH_INSTAGRAM_USER_DETAILS',
          payload: hashTags,
        });
      }
      const videoConfig = CommonFunctions.createVideoConfig(response);

      dispatch({
        type: 'VIDEO_CONFIGURATION',
        payload: videoConfig,
      });
    }
  };
};

export const isStaffFetchInProcessing = (payload) => {
  return {
    type: 'STAFF_FETCH_PROCESSING',
    payload,
  };
};
