/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Image from './Image';

const Footer = (props) => {
  let link =
    'https://www.setmore.com/home?utm_source=footer&utm_medium=New%20booking%20page';
  let imgLink =
    'https://assets.setmore.com/setmore/images/2.0/BookingPage/setmore-logo-small.svg';

  if (window.companyJson.brandId === 'c5fd52b0-71e6-489f-b044-52390210a437') {
    link =
      'https://inthechair.com/home?utm_source=footer&utm_medium=New%20booking%20page';
    imgLink =
      'https://assets.inthechair.com/v1/static/images/branding/inch-logo-small.svg';
  }

  return (
    props.viewSettings &&
    props.viewSettings.customization.isBranding &&
    ((window.isMobile.matches &&
      props.step !== 'yourInfo' &&
      props.classStep !== 'yourInfo') ||
      !window.isMobile.matches) && (
      <div className="footer" id="footer">
        <a target="_blank" href={link}>
          <span>Scheduling by</span>
          <Image
            src={imgLink}
            alt={'Footer Img'}
            className=""
            defaultImage=""
            width="72"
            height="11"
          />
        </a>
      </div>
    )
  );
};
Footer.propTypes = {
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  step: PropTypes.string,
  classStep: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    viewSettings: state.viewSettings,
    step: state.step,
    classStep: state.classStep,
  };
};
export default connect(mapStateToProps)(Footer);
