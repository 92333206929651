import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setCustomerProfileStep } from '../../../redux/actions';

const ReviewRating = (props) => {
  const starFill = `${props.reviewRating.averageRating * 20}%`;
  const link = `${props.bookingPath}/reviews`;
  const showReviews = () => {
    props.setCustomerProfileStep('');
  };
  return (
    props.reviewRating &&
    props.reviewRating.totalRating > 0 && (
      <div className="reviews-con">
        <em>{props.reviewRating.averageRating}</em>
        <span className="review-stars">
          <span className="rated" style={{ width: starFill }}></span>
        </span>
        <NavLink to={link}>
          <em
            onClick={props.userProfile === 'userProfile' ? showReviews : null}
            data-testid="ratingCount"
          >
            ({props.reviewRating.count})
          </em>
        </NavLink>
      </div>
    )
  );
};

ReviewRating.propTypes = {
  reviewRating: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bookingPath: PropTypes.string,
  setCustomerProfileStep: PropTypes.func,
  userProfile: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    reviewRating: state.reviewRating,
    bookingPath: state.bookingPath,
  };
};

export default connect(mapStateToProps, {
  setCustomerProfileStep,
})(ReviewRating);
