import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CommonFunctions from '../../utils/commonFunctions';
import {
  stepProgress,
  classStepProgress,
  setSelectedService,
  setSelectedStaff,
  setSelectedDateAndTime,
  setCustomerProfileStep,
  setSelectedDateAndTimeForClass,
  setSelectedClass,
  setSelectedClassProvider,
  setSelectedSession,
  loginButtonStepProgress,
  showPageBlock,
  setBookingId,
  setClassBookingId,
  setCustomer,
  resetUpcomingEvents,
  resetPastEvents,
  resetFutureEventCursor,
  resetPastEventCursor,
  updateFutureEventRequest,
  updatePastEventRequest,
  setDisplayResponsiveMenu,
  setApptReschedule,
  setSessionReschedule,
  resetSlotAvailability,
  setAppointmentComments,
  setStaffStep,
  setPromoCode,
  setPromoResponse,
} from '../../redux/actions';
import { PAGE_DETAILS } from '../../utils/constants';

const Signout = (props) => {
  const handleClickOnSignout = (e) => {
    CommonFunctions.eventPropagation(e);
    CommonFunctions.invalidateSession();
    props.setCustomer('');
    props.setAppointmentComments('');
    props.stepProgress('service');
    if (!PAGE_DETAILS.isStaffPage) {
      props.setSelectedStaff('');
      if (!PAGE_DETAILS.isSessionPage) {
        props.setSelectedClassProvider('');
      }
    }
    if (PAGE_DETAILS.isServicePage) {
      const stepState =
        (props.viewSettings &&
          props.viewSettings.bookingOption &&
          props.viewSettings.bookingOption.isSkipStaff) ||
        PAGE_DETAILS.isStaffPage
          ? 'searchForAvailability'
          : 'staff';
      props.stepProgress(stepState);
    } else if (PAGE_DETAILS.isClassPage) {
      const stepState =
        (props.viewSettings &&
          props.viewSettings.bookingOption &&
          props.viewSettings.bookingOption.isSkipStaff) ||
        PAGE_DETAILS.isStaffPage
          ? 'dateAndTime'
          : 'staff';
      props.classStepProgress(stepState);
    } else {
      props.setSelectedService('');
      props.stepProgress('service');
    }
    props.setApptReschedule(false);
    props.setSessionReschedule(false);
    props.setSelectedDateAndTime('');
    props.loginButtonStepProgress('');
    props.setCustomerProfileStep('');
    props.resetUpcomingEvents();
    props.resetPastEvents();
    props.resetFutureEventCursor();
    props.resetPastEventCursor();
    props.updateFutureEventRequest(false);
    props.updatePastEventRequest(false);
    if (!PAGE_DETAILS.isSessionPage) {
      props.setSelectedDateAndTimeForClass('');
      props.setSelectedClass('');
      props.setSelectedSession('');
      props.classStepProgress('class');
    } else {
      const classStep =
        props.viewSettings &&
        props.viewSettings.bookingOption.customerLogin &&
        props.viewSettings.bookingOption.customerLogin.isActive &&
        !window.isFBOrInstagram &&
        CommonFunctions.isStandaloneLoginAllowed()
          ? 'customerLogin'
          : 'yourInfo';
      props.classStepProgress(classStep);
    }
    props.showPageBlock(false);
    props.setBookingId('');
    props.setClassBookingId('');
    props.setDisplayResponsiveMenu(false);
    props.resetSlotAvailability();
    props.setPromoResponse();
    props.setPromoCode('');
    props.setStaffStep('');
  };

  const ProfileDropdownSignout = () => {
    return (
      <li onClick={handleClickOnSignout} data-testid="profileDropdown-signout">
        <span>{window.lang.sign_out}</span>
      </li>
    );
  };

  const MyProfileSignout = () => {
    return (
      <li onClick={handleClickOnSignout} data-testid="myprofile-signout">
        <a>
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M13.808 4c.887.043 1.582.724 1.61 1.53v3.295c0 .328-.28.593-.626.593-.317 0-.578-.223-.62-.512l-.005-.08V5.623c.01-.206-.138-.384-.32-.429l-.07-.01H4.672c-.217.01-.39.166-.417.382l-.003.084v12.725c-.01.206.137.384.32.429l.069.01h9.105c.217-.01.39-.166.417-.382l.004-.084v-3.174c0-.328.28-.593.625-.593.317 0 .578.223.62.512l.005.08v3.148c.04.842-.61 1.562-1.505 1.667l-.135.011H4.609c-.887-.043-1.58-.725-1.609-1.53l.002-12.792c-.04-.841.61-1.562 1.503-1.667L4.641 4h9.167zm3.333 4.938c.244-.231.64-.231.884 0l2.784 2.638c.026.024.05.05.074.081.033.043.058.09.077.138l.008.02c.006.018.012.037.016.055.01.043.016.087.016.133 0 .083-.018.163-.051.235l-.017.034c-.015.028-.033.055-.053.08l-.062.07-2.792 2.645-.077.063c-.244.166-.587.146-.807-.063l-.066-.073c-.176-.232-.154-.556.066-.765l1.725-1.634H8.651l-.085-.005c-.305-.04-.54-.287-.54-.587 0-.327.28-.593.625-.593h10.216l-1.726-1.634-.066-.073c-.176-.232-.154-.556.066-.765z"
              />
            </svg>
          </figure>
          <span>{window.lang.sign_out}</span>
        </a>
      </li>
    );
  };

  const ResponsiveMenuSignout = () => {
    return (
      <a
        className="ml-auto logout-action"
        onClick={handleClickOnSignout}
        data-testid="resp-menu-signout"
      >
        {window.lang.sign_out}
      </a>
    );
  };

  const ResponsiveMyProfileSignout = () => {
    return (
      <li onClick={handleClickOnSignout} data-testid="resp-myprofile-signout">
        <a>
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              fill="none"
              viewBox="0 0 17 17"
            >
              <path
                fill="#142640"
                fillRule="evenodd"
                d="M10.921 3.917c-.02-.57-.512-1.053-1.14-1.083H3.287l-.096.007c-.633.075-1.094.585-1.065 1.18v9.062c.02.57.51 1.053 1.139 1.083h6.493l.097-.007c.633-.075 1.093-.585 1.065-1.18v-2.23l-.004-.057c-.029-.205-.214-.363-.438-.363-.245 0-.443.188-.443.42v2.248l-.003.06c-.02.153-.142.263-.296.27H3.287l-.049-.007c-.13-.032-.234-.158-.227-.304V4.003l.003-.06c.02-.153.142-.263.295-.27h6.45l.049.007c.13.032.234.158.227.304V6.25l.004.057c.03.205.214.363.439.363.244 0 .443-.188.443-.42V3.917zm1.847 2.414c-.173-.164-.453-.164-.626 0-.156.148-.171.378-.047.542l.047.052 1.221 1.157H6.128c-.245 0-.443.188-.443.42 0 .212.166.388.383.416l.06.004h7.235l-1.221 1.157c-.156.148-.171.378-.047.542l.047.052c.155.147.398.162.571.044l.055-.044 1.977-1.874c.043-.04.075-.087.096-.136.022-.05.034-.104.034-.161l-2.733-2.17 2.603 1.873-1.977-1.874zm1.977 1.874l.047.052c-.014-.018-.03-.036-.047-.052z"
                clipRule="evenodd"
              />
            </svg>
          </figure>
          <span>{window.lang.sign_out}</span>
        </a>
      </li>
    );
  };

  return (
    <>
      {props.component === 'profileDropdown' && <ProfileDropdownSignout />}
      {props.component === 'myProfile' && <MyProfileSignout />}
      {props.component === 'resposiveMenu' && <ResponsiveMenuSignout />}
      {props.component === 'resposiveMyProfile' && (
        <ResponsiveMyProfileSignout />
      )}
    </>
  );
};

Signout.propTypes = {
  component: PropTypes.string,
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  stepProgress: PropTypes.func,
  classStepProgress: PropTypes.func,
  setSelectedService: PropTypes.func,
  setSelectedStaff: PropTypes.func,
  setSelectedDateAndTime: PropTypes.func,
  setCustomerProfileStep: PropTypes.func,
  setSelectedDateAndTimeForClass: PropTypes.func,
  setSelectedClass: PropTypes.func,
  setSelectedClassProvider: PropTypes.func,
  setSelectedSession: PropTypes.func,
  loginButtonStepProgress: PropTypes.func,
  showPageBlock: PropTypes.func,
  setBookingId: PropTypes.func,
  setClassBookingId: PropTypes.func,
  setCustomer: PropTypes.func,
  setAppointmentComments: PropTypes.func,
  resetUpcomingEvents: PropTypes.func,
  resetPastEvents: PropTypes.func,
  resetFutureEventCursor: PropTypes.func,
  resetPastEventCursor: PropTypes.func,
  updateFutureEventRequest: PropTypes.func,
  updatePastEventRequest: PropTypes.func,
  setDisplayResponsiveMenu: PropTypes.func,
  setApptReschedule: PropTypes.func,
  setSessionReschedule: PropTypes.func,
  resetSlotAvailability: PropTypes.func,
  bookingPage: PropTypes.string,
  setStaffStep: PropTypes.func,
  setPromoCode: PropTypes.func,
  setPromoResponse: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    viewSettings: state.viewSettings,
    bookingPage: state.bookingPage,
  };
};

export default connect(mapStateToProps, {
  stepProgress,
  classStepProgress,
  setSelectedService,
  setSelectedStaff,
  setSelectedDateAndTime,
  setCustomerProfileStep,
  setSelectedDateAndTimeForClass,
  setSelectedClass,
  setSelectedClassProvider,
  setSelectedSession,
  loginButtonStepProgress,
  showPageBlock,
  setBookingId,
  setClassBookingId,
  setCustomer,
  resetUpcomingEvents,
  resetPastEvents,
  resetFutureEventCursor,
  resetPastEventCursor,
  updateFutureEventRequest,
  updatePastEventRequest,
  setDisplayResponsiveMenu,
  setApptReschedule,
  setSessionReschedule,
  resetSlotAvailability,
  setAppointmentComments,
  setStaffStep,
  setPromoCode,
  setPromoResponse,
})(Signout);
