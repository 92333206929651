import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './redux/store';
import * as Sentry from '@sentry/browser';

window.isMobile = window.matchMedia('only screen and (max-width: 1080px)');

Sentry.init({
  dsn: 'https://be593221375d4810b1f4c931f6414b0f@o151188.ingest.sentry.io/5541143',
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: window.APP_MODE === 'LIVE' ? 'production' : 'development',
  ignoreErrors: [
    'UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
    'UnhandledRejection: Non-Error promise rejection captured with value:',
    'jQuery is not defined',
    "Can't find variable: jQuery",
    "Uncaught DOMException: Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.",
    "Cannot read property 'getReadModeConfig' of undefined",
    "Cannot read property 'getReadModeRender' of undefined",
    "Cannot read property 'getReadModeExtract' of undefined",
    'Illegal invocation',
    "ReferenceError: Can't find variable: $",
    'Chartboost is not defined',
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
    'ChunkLoadError',
    'Loading CSS chunk',
    'Unhandled Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
    'PaymentMethodUnsupportedError',
    'PaymentMethodNotAttachedError',
    "Can't find variable: PaymentAutofillConfig",
    'Request failed with status code 404',
    '$ is not defined',
    'privateSpecialRepair is not defined',
    'The element #google-pay-button was not found',
    'The element #card-container was not found',
    "Can't find variable: _AutofillCallbackHandler",
    'reduce of empty array with no initial value',
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  denyUrls: [
    'https://www.google-analytics.com/*',
    'https://web-writer.eu.smartlook.cloud/rec/v3/write',
    'https://web-writer.eu.smartlook.cloud/rec/v3/*',
    'https://translate.googleapis.com/*',
    'https://stats.g.doubleclick.net/*',
  ],
});
Sentry.setUser({
  id: window.companyJson.key,
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
