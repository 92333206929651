import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Image from './Image';
import CommonFunctions from '../../utils/commonFunctions';
import ReviewRating from '../mainContainer/Reviews/ReviewRating';
import Loader from './Loader';
import { PAGE_DETAILS } from '../../utils/constants';

const ResponsiveMenu = React.lazy(() => import('../menu/ResponsiveMenu'));

const ProfileDropdown = React.lazy(() =>
  import('../mainContainer/ProfileDropdown')
);

const ResponsiveHeader = React.lazy(() =>
  import('../mainContainer/ResponsiveHeader')
);
const Header = (props) => {
  return (
    <>
      {props.viewSettings.viewOptions.isMenu && props.displayResponsiveMenu && (
        <Suspense
          fallback={
            <section className="mob-nav">
              <Loader />
            </section>
          }
        >
          <ResponsiveMenu />
        </Suspense>
      )}

      <header
        className={`${
          props.banner?.size &&
          !CommonFunctions.isNullOrEmpty(props.banner.size)
            ? ''
            : 'no-banner-img'
        } ${props.customer.key ? 'logged-in' : ''}`}
      >
        {props.customerProfileStep && !window.isMobile.matches && (
          <div className="userprofile-header">
            {props.companyDetails.logo && (
              <figure>
                <Image
                  src={props.companyDetails.logo}
                  alt=""
                  defaultImage=""
                  sizeAttr="=s250"
                />
              </figure>
            )}
            <div className="userprofile-title">
              <h3 data-testid="companyName">
                {CommonFunctions.htmlDecode(props.companyDetails.name)}
              </h3>
              {props.viewSettings.menu.indexOf('review') !== -1 && (
                <ReviewRating userProfile="userProfile" />
              )}
            </div>
          </div>
        )}

        {!props.customerProfileStep && (
          <Suspense fallback={null}>
            {' '}
            <ResponsiveHeader />{' '}
          </Suspense>
        )}
        {!PAGE_DETAILS.isReschedulePage &&
          !window.isMobile.matches &&
          props.viewSettings.bookingOption.customerLogin.isActive && (
            <Suspense fallback={null}>
              <div className="profile-header-wrap">
                <ProfileDropdown />
              </div>
            </Suspense>
          )}
      </header>
    </>
  );
};
Header.propTypes = {
  customer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customerProfileStep: PropTypes.string,
  displayResponsiveMenu: PropTypes.bool,
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  banner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = (state) => {
  return {
    viewSettings: state.viewSettings,
    companyDetails: state.companyDetails,
    displayResponsiveMenu: state.displayResponsiveMenu,
    customerProfileStep: state.customerProfileStep,
    customer: state.customer,
    banner: state.banner,
  };
};
export default connect(mapStateToProps)(Header);
