import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CompanyProfile from './CompanyProfile';
import SubTabs from './SubTabs';
import CommonFunctions from '../../utils/commonFunctions';
import { PAGE_DETAILS } from '../../utils/constants';

function Menu(props) {
  const activeTabs = () => {
    const tabs = [];
    if (
      PAGE_DETAILS.isClassPage !== true &&
      props.viewSettings.menu.indexOf('bookAppt') !== -1 &&
      !PAGE_DETAILS.isSessionPage &&
      props.bookingPage !== 'classCategory' &&
      !props.isSessionReschedule
    ) {
      tabs.push('bookAppt');
    }
    if (
      props.viewSettings.menu.indexOf('bookClass') !== -1 &&
      PAGE_DETAILS.isServicePage !== true &&
      props.bookingPage !== 'serviceCategory'
    ) {
      tabs.push('bookClass');
    }
    if (
      props.viewSettings.menu.indexOf('staffMembers') !== -1 &&
      !props.viewSettings.bookingOption.isSkipStaff &&
      !PAGE_DETAILS.isSessionPage &&
      PAGE_DETAILS.isStaffPage !== true &&
      PAGE_DETAILS.isServicePage !== true &&
      PAGE_DETAILS.isClassPage !== true
    ) {
      tabs.push('staffMembers');
    }
    if (
      props.viewSettings.menu.indexOf('services') !== -1 &&
      PAGE_DETAILS.isServicePage !== true &&
      PAGE_DETAILS.isClassPage !== true &&
      !PAGE_DETAILS.isSessionPage &&
      props.bookingPage !== 'serviceCategory' &&
      props.bookingPage !== 'classCategory' &&
      !props.isSessionReschedule
    ) {
      tabs.push('services');
    }
    if (
      props.viewSettings.menu.indexOf('classes') !== -1 &&
      PAGE_DETAILS.isServicePage !== true &&
      PAGE_DETAILS.isClassPage !== true &&
      !PAGE_DETAILS.isSessionPage &&
      props.bookingPage !== 'serviceCategory' &&
      props.bookingPage !== 'classCategory'
    ) {
      tabs.push('classes');
    }
    if (
      !CommonFunctions.isNullOrEmpty(props.companyDetails.aboutYou) &&
      props.viewSettings.menu.indexOf('aboutUs') !== -1
    ) {
      tabs.push('aboutUs');
    }

    if (props.clientHashTags) {
      tabs.push('photos');
    }

    if (props.viewSettings.menu.indexOf('review') !== -1) {
      tabs.push('reviews');
    }

    return tabs;
  };
  return (
    <aside className="sidebar">
      <CompanyProfile
        src={props.companyDetails.logo}
        alt={props.companyDetails.name}
        title={props.companyDetails.name}
      />

      <SubTabs activeTabs={activeTabs()} />
    </aside>
  );
}
Menu.propTypes = {
  activeTabs: PropTypes.array,
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  clientHashTags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  serviceList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isSessionReschedule: PropTypes.bool,
  bookingPage: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails,
    viewSettings: state.viewSettings,
    clientHashTags: state.clientHashTags,
    serviceList: state.serviceList,
    isSessionReschedule: state.isSessionReschedule,
    bookingPage: state.bookingPage,
  };
};

export default connect(mapStateToProps)(Menu);
