/* eslint-disable prefer-destructuring */
import { DateTime } from 'luxon';
import axios from 'axios';
import CommonFunctions from '../../../utils/commonFunctions';
import {
  TOKEN,
  STAFFKEY,
  DATE_MONTH_YEAR_FORMAT,
  PAGE_DETAILS,
  SE_BASE_URL,
  IS_SE_SLOT,
} from '../../../utils/constants';

const convertDateToSelectedTZ = (date, selectedTimezone) => {
  const selected = new Date(date);
  if (!Number.isNaN(selected.getTime())) {
    const dateTime = DateTime.fromJSDate(selected).setZone(selectedTimezone);
    const endDate = new Date(1578178800000);
    endDate.setFullYear(dateTime.get('year'));
    endDate.setMonth(dateTime.get('month') - 1);
    endDate.setDate(dateTime.day);
    endDate.setHours(dateTime.get('hour'));
    endDate.setMinutes(dateTime.get('minute'));
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    return endDate;
  }
  return selected;
};

const getIso = (date) => {
  if (!CommonFunctions.isNullOrEmpty(date)) {
    const time = date.split('.')[0];
    const zone = date.split('.')[1].slice(3);
    const timeGMT = zone === '+00:00' ? 'Z' : zone;
    return `${time}${timeGMT}`;
  }
  return '';
};

const getAdvanceDurationMins = (leadTime) => {
  return leadTime
    ? leadTime.day * 24 * 60 + leadTime.hour * 60 + leadTime.minute
    : 0;
};

const getSlotsPayload = (services, staffKey, currentDate) => {
  let duration = 0;

  services.forEach((service) => {
    duration += service.duration;
  });

  return {
    companyKey: services[0].merchantId,
    staffKey,
    serviceDuration: duration,
    date: DateTime.fromJSDate(currentDate).toFormat('MM/dd/yyyy'),
    serviceKey: services[0].id,
  };
};

const getLastDateOfSchedulingWindow = (schedulingWindow) => {
  let converetedDate = new Date();
  if (window.companyJson && window.companyJson.companyDate) {
    converetedDate = new Date(window.companyJson.companyDate);
  }
  const endDate = new Date(1578178800000);
  if (
    schedulingWindow &&
    (schedulingWindow.day > 0 ||
      schedulingWindow.month > 0 ||
      schedulingWindow.year > 0)
  ) {
    const monthDays = schedulingWindow.month * 30;
    let yearAdded = converetedDate.getFullYear();
    if (schedulingWindow.year > 0) {
      yearAdded = converetedDate.getFullYear() + schedulingWindow.year;
    }
    endDate.setFullYear(yearAdded);
    endDate.setMonth(converetedDate.getMonth());
    endDate.setDate(
      converetedDate.getDate() + schedulingWindow.day + monthDays
    );
    endDate.setHours(converetedDate.getHours());
    endDate.setMinutes(converetedDate.getMinutes());
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);
    return endDate;
  }
  return null;
};

const isPastSchedulingWindow = (date, schedulingWindow) => {
  if (
    schedulingWindow &&
    (schedulingWindow.day > 0 ||
      schedulingWindow.month > 0 ||
      schedulingWindow.year > 0)
  ) {
    const endDate = getLastDateOfSchedulingWindow(schedulingWindow);

    return date > endDate;
  }
  return false;
};

const convertToLanguage = (date, isUnavailableDay) => {
  const day =
    window.lang[DateTime.fromJSDate(date).toFormat('EEEE').toLowerCase()];
  const month =
    window.lang[DateTime.fromJSDate(date).toFormat('MMMM').toLowerCase()];
  const year = DateTime.fromJSDate(date).toFormat('d, yyyy');
  if (!isUnavailableDay) return `${day}, ${month} ${year}`;
  return `${month} ${year}`;
};

const generateSlotMapWhenSlotIsEmpty = (key, schedulingWindow, slotsMap) => {
  const slotMapGenerated = slotsMap;
  if (!isPastSchedulingWindow(new Date(key), schedulingWindow)) {
    slotMapGenerated.unavailableDates.push(key);
    slotMapGenerated.noSlots = true;
  } else {
    slotMapGenerated.noSlots = true;
    slotMapGenerated.unavailableDates.push(key);
  }
  return slotMapGenerated;
};

const generateSlotMapWithSlotsModel = (slots, skipStaff, date, staffNoPref) => {
  const slotsArray = [];
  slots[date].map((x) => {
    if (skipStaff || staffNoPref) {
      return slotsArray.push({
        id: x[0],
        value: x[3].toLowerCase(),
        staffKey: x[2],
      });
    }
    return slotsArray.push({
      id: x[0],
      value: x[3].toLowerCase(),
    });
  });

  return slotsArray;
};

const generateSlotMapWhenAvailable = (
  slots,
  key,
  schedulingWindow,
  slotMap,
  skipStaff,
  setSelectedDate,
  staffNoPref
) => {
  const slotMapGenerated = slotMap;

  if (!isPastSchedulingWindow(new Date(key), schedulingWindow)) {
    if (!slotMapGenerated.availableDate) {
      slotMapGenerated.availableDate = key;
      slotMapGenerated.dateTitle = convertToLanguage(new Date(key));
      slotMapGenerated.availableDatesForEachMonth.push(key);
    }
    slotMapGenerated.noSlots = false;
    slotMapGenerated.slots[key] = generateSlotMapWithSlotsModel(
      slots,
      skipStaff,
      key,
      staffNoPref
    );
  } else {
    slotMapGenerated.noSlots = true;
    const lastDateOfSchedulingWindow = DateTime.fromJSDate(
      getLastDateOfSchedulingWindow(schedulingWindow)
    ).toFormat('MM/dd/yyyy');
    if (!slotMapGenerated.availableDate) {
      slotMapGenerated.availableDate = lastDateOfSchedulingWindow;
      slotMapGenerated.dateTitle = convertToLanguage(
        slotMapGenerated.availableDate
      );
      slotMapGenerated.availableDatesForEachMonth.push(
        slotMapGenerated.availableDate
      );
      setSelectedDate(slotMapGenerated.availableDate);
    }
    slotMapGenerated.slots[key] = generateSlotMapWithSlotsModel(
      slots,
      skipStaff,
      lastDateOfSchedulingWindow,
      staffNoPref
    );
  }
  return slotMapGenerated;
};

const nonWorkingDay = (date, props) => {
  let selectedStaff = props.selectedStaff.key;
  if (
    !selectedStaff &&
    props.staffList.length === 1 &&
    props.viewSettings.bookingOption.isSkipStaff
  ) {
    selectedStaff = props.staffList[0].key;
  }
  const staffWorkingHours = CommonFunctions.getObject(
    props.staffWorkingHours,
    selectedStaff,
    'ResourceKey'
  );
  if (staffWorkingHours && staffWorkingHours.WorkingDays.length === 0) {
    return true;
  }
  if (
    staffWorkingHours &&
    (!props.viewSettings.viewOptions.isLocalTime ||
      props.localTimeZone === props.companyDetails.timeZone)
  ) {
    const workingDays = staffWorkingHours.WorkingDays.split('');
    return workingDays.indexOf(`${new Date(date).getDay()}`) === -1;
  }
  return false;
};

const getNextWorkingDate = (date, props) => {
  const nextWorkingDate = DateTime.fromJSDate(date)
    .plus({ days: 1 })
    .toJSDate();
  let selectedStaff = props.selectedStaff.key;
  if (
    !selectedStaff &&
    props.staffList.length === 1 &&
    props.viewSettings.bookingOption.isSkipStaff
  ) {
    selectedStaff = props.staffList[0].key;
  }
  const staffWorkingHours = CommonFunctions.getObject(
    props.staffWorkingHours,
    selectedStaff,
    'ResourceKey'
  );
  if (
    nonWorkingDay(nextWorkingDate, props) &&
    staffWorkingHours &&
    staffWorkingHours.WorkingDays.length !== 0
  ) {
    return getNextWorkingDate(nextWorkingDate, props);
  }
  return nextWorkingDate;
};

const getTotalSchedulingWindowDays = (schedulingWindow) => {
  if (schedulingWindow) {
    return schedulingWindow.day + schedulingWindow.month * 30;
  }
  return 0;
};

const getFirstDayOfNextMonth = (selectedDate) => {
  const selectedDateObj = new Date(selectedDate);
  const selectedMonth = selectedDateObj.getMonth();
  const selectedYear = selectedDateObj.getFullYear();
  let nextMonth = selectedMonth + 1;
  let nextMonthYear = selectedYear;
  if (nextMonth === 12) {
    nextMonthYear = selectedYear + 1;
    nextMonth = 0;
  }
  const firstDayOfNextMonth = new Date(nextMonthYear, nextMonth, 1);
  return DateTime.fromJSDate(firstDayOfNextMonth).toFormat('MM/dd/yyyy');
};

const getAdvanceLeadTimeDate = (leadTime) => {
  let currentDate = new Date();
  if (window.companyJson && window.companyJson.currentDate) {
    currentDate = new Date(window.companyJson.currentDate);
  }
  const leadTimeDay = leadTime ? leadTime.day : 0;
  return currentDate.setDate(currentDate.getDate() + leadTimeDay);
};

const computeAdvancedLeadTimeDate = (leadTime, selectedTime) => {
  const bookByTime = new Date(
    selectedTime - getAdvanceDurationMins(leadTime) * 60 * 1000
  );
  return bookByTime > new Date().getTime();
};

const setSelectedDateForAppt = (
  props,
  slotsMap,
  skipStaff,
  date,
  count,
  keys,
  staffNoPref
) => {
  const slotMap = { ...slotsMap };
  const totalDays = getTotalSchedulingWindowDays(
    props.schedulingPolicy.advanceBooking
  );
  if (
    CommonFunctions.isNullOrEmpty(slotMap.availableDate) &&
    !props.viewSettings.viewOptions.isNextAvailableDate
  ) {
    if (totalDays !== 0 && slotMap.unavailableDates.length >= totalDays) {
      if (!staffNoPref && !skipStaff && nonWorkingDay(new Date(date), props)) {
        slotMap.availableDate = getNextWorkingDate(new Date(date), props);
      } else {
        slotMap.availableDate = new Date(date);
      }
    }
    if (isPastSchedulingWindow(date, props.schedulingPolicy.advanceBooking)) {
      const schedulingWindow = {
        ...props.schedulingPolicy.advanceBooking,
      };
      schedulingWindow.month += 1;
      slotMap.availableDate = getLastDateOfSchedulingWindow(schedulingWindow);
    }
  }
  if (
    CommonFunctions.isNullOrEmpty(slotMap.availableDate) &&
    props.viewSettings.viewOptions.isNextAvailableDate &&
    !slotMap.fetchAgain
  ) {
    if (count === keys.length) {
      slotMap.availableDate = new Date(keys[1]);
    }
    if (
      !staffNoPref &&
      !skipStaff &&
      nonWorkingDay(slotMap.availableDate, props)
    ) {
      slotMap.availableDate = getNextWorkingDate(slotMap.availableDate, props);
    }
  }
  if (
    CommonFunctions.isNullOrEmpty(slotMap.availableDate) &&
    !props.viewSettings.viewOptions.isNextAvailableDate
  ) {
    slotMap.availableDate = new Date(date);
  }
  if (slotMap.availableDate) {
    slotMap.dateTitle = convertToLanguage(new Date(slotMap.availableDate));
  }
  return slotMap;
};

const setDateWhenReschedule = (
  selectedDateFromReschedule,
  props,
  slotsMap,
  skipStaff,
  staffNoPref
) => {
  const slotMap = { ...slotsMap };
  if (selectedDateFromReschedule) {
    const dateOfReschedule = new Date(selectedDateFromReschedule);
    const checkForSlot =
      DateTime.fromJSDate(dateOfReschedule).toFormat('MM/dd/yyyy');
    if (
      !isPastSchedulingWindow(
        dateOfReschedule,
        props.schedulingPolicy.advanceBooking
      ) &&
      dateOfReschedule.getTime() >
        getAdvanceLeadTimeDate(props.schedulingPolicy.leadTime) &&
      slotMap.slots[checkForSlot] &&
      slotMap.slots[checkForSlot].length > 0
    ) {
      slotMap.availableDate = dateOfReschedule;
      if (
        !staffNoPref &&
        !skipStaff &&
        nonWorkingDay(slotMap.availableDate, props)
      ) {
        slotMap.availableDate = getNextWorkingDate(
          slotMap.availableDate,
          props
        );
      }
      slotMap.dateTitle = convertToLanguage(slotMap.availableDate);
    }
  }
  return slotMap;
};

const getSlotsResponse = (
  date,
  response,
  skipStaff,
  slotsMap,
  setSelectedDate,
  props,
  selectedDateFromReschedule,
  staffNoPref
) => {
  const { slots } = response.data.data;
  if (typeof slots === 'object' && Object.keys(slots).length === 0) {
    return { noSlots: true };
  }

  let slotMap = {
    slots: {},
    dateTitle: '',
    noSlots: slots[date].length < 1,
    unavailableDates: [],
    availableDate: '',
    fetchedMonths: [],
    nonAvailableMonths: [],
    availableDatesForEachMonth: [],
  };
  if (slotsMap && slotsMap.slots) {
    slotMap = slotsMap;
  }
  const fetchedMonth = DateTime.fromJSDate(new Date(date)).toFormat('M/yyyy');
  slotMap.fetchedMonths.push(fetchedMonth);
  slotMap.fetchAgain = false;
  const keys = Object.keys(slots).sort();
  let count = 0;

  keys.forEach((key) => {
    if (slots[key].length === 0) {
      slotMap = generateSlotMapWhenSlotIsEmpty(
        key,
        props.schedulingPolicy.advanceBooking,
        slotMap
      );
      count += 1;
    } else {
      slotMap = generateSlotMapWhenAvailable(
        slots,
        key,
        props.schedulingPolicy.advanceBooking,
        slotMap,
        skipStaff,
        setSelectedDate,
        staffNoPref
      );
    }
  });
  const mySet1 = new Set();
  slotMap.unavailableDates.forEach(mySet1.add, mySet1);
  slotMap.unavailableDates = [...mySet1];

  if (count === keys.length) {
    slotMap.nonAvailableMonths.push(fetchedMonth);
    const nextFetchDate = getFirstDayOfNextMonth(date);
    if (
      props.viewSettings.viewOptions.isNextAvailableDate &&
      !isPastSchedulingWindow(
        new Date(nextFetchDate),
        props.schedulingPolicy.advanceBooking
      )
    ) {
      slotMap.fetchAgain = true;
    }
  }
  slotMap = setSelectedDateForAppt(
    props,
    slotMap,
    skipStaff,
    date,
    count,
    keys,
    staffNoPref
  );
  if (selectedDateFromReschedule) {
    slotMap = setDateWhenReschedule(
      selectedDateFromReschedule,
      props,
      slotMap,
      skipStaff,
      staffNoPref
    );
  }

  return slotMap;
};

const addSlotLoader = (type) => {
  if (document.getElementsByClassName('time-sheet')[0]) {
    const doc = document.getElementsByClassName('time-sheet')[0].classList;

    if (type === 'add') {
      doc.add('slot-loading');
    } else {
      doc.remove('slot-loading');
    }
  }
};

const getEndDayOfMonth = (selectedDate) => {
  const selectedDateObj = new Date(selectedDate);
  const selectedMonth = selectedDateObj.getMonth();
  const selectedYear = selectedDateObj.getFullYear();
  return new Date(selectedYear, selectedMonth + 1, 0);
};

const getNoOfDaysToFetch = (selectedDate) => {
  return (
    getEndDayOfMonth(selectedDate).getDate() -
    new Date(selectedDate).getDate() +
    1
  );
};

const generateURL = (
  skipStaff,
  slotPayload,
  apptLeadDate,
  days,
  timeZone,
  format,
  staffNoPref
) => {
  let baseURL = 'https://slots.setmore.com/slots/v1';
  if (
    window.APP_MODE === 'LOCALHOST' ||
    window.location.hostname === 'localhost' ||
    window.APP_MODE === 'STAGING'
  ) {
    baseURL = 'https://slots.setmore.info/slots/v1';
  }
  if (window.APP_MODE === 'STAGING-SETMORE') {
    baseURL = 'https://testing.setmore.com/slots/v1';
  }

  if (IS_SE_SLOT) {
    baseURL = `${SE_BASE_URL}/slots`;
  }

  let requestURL = '';
  if (skipStaff || staffNoPref) {
    let serviceKeys = slotPayload.serviceKey;
    if (PAGE_DETAILS.isServicePage) {
      serviceKeys = PAGE_DETAILS.serviceKey;
    }
    let companyKeys = slotPayload.companyKey;
    if (PAGE_DETAILS.isServicePage) {
      companyKeys = TOKEN;
    }
    requestURL = `${baseURL}/staffs/${companyKeys}/${serviceKeys}/${slotPayload.serviceDuration}/${apptLeadDate}/${days}/0?timezone=${timeZone}&date=${slotPayload.date}&timeFormat=${format}`;
  } else {
    requestURL = `${baseURL}/${slotPayload.companyKey}/${
      slotPayload.staffKey || STAFFKEY
    }/${
      slotPayload.serviceDuration
    }/${apptLeadDate}/${days}/0?timezone=${timeZone}&date=${
      slotPayload.date
    }&timeFormat=${format}`;
  }
  return requestURL;
};

const getRequestURLForSlotFetch = (props, skipStaff, slotPayload, timeZone) => {
  const apptLeadDate = getAdvanceDurationMins(props.schedulingPolicy.leadTime);
  const format =
    props.viewSettings.viewOptions.timeFormat === 24 ? 'HH:mm' : 'h:mm%20a';
  const days = getNoOfDaysToFetch(slotPayload.date);
  const requestURL = generateURL(
    skipStaff,
    slotPayload,
    apptLeadDate,
    days,
    timeZone,
    format,
    props.staffNoPref
  );
  let requestURL2;
  if (days === 31) {
    const lastDateOfMonth = new Date(
      new Date(slotPayload.date).getFullYear(),
      new Date(slotPayload.date).getMonth() + 1,
      0
    );
    const slotsPayload = { ...slotPayload };
    slotsPayload.date =
      DateTime.fromJSDate(lastDateOfMonth).toFormat('MM/dd/yyyy');
    requestURL2 = generateURL(
      skipStaff,
      slotsPayload,
      apptLeadDate,
      1,
      timeZone,
      format,
      props.staffNoPref
    );
  }
  return { requestURL, requestURL2 };
};

const removeLoaderForDatePicker = (step) => {
  if (
    document.getElementsByClassName('slot-choosing')[0] &&
    (!step || step === 'dateAndTime')
  ) {
    for (const element of document.getElementsByClassName('slot-choosing')[0]
      .children) {
      element.style.display = 'block';
    }
    document
      .getElementsByClassName('slot-choosing')[0]
      .classList.remove('loader', 'loader--secondary');
  }
};

const fetchSlots = async (
  slotPayload,
  timeZone,
  props,
  skipStaff,
  slots,
  setResponseLength,
  setSelectedDate,
  selectedDateFromReschedule,
  staffNoPref
) => {
  const requestURL = getRequestURLForSlotFetch(
    props,
    skipStaff,
    slotPayload,
    timeZone,
    staffNoPref
  );
  try {
    const response = await axios.get(requestURL.requestURL, {
      cancelToken: window.source.token,
    });
    let response2;
    if (requestURL.requestURL2) {
      response2 = await axios.get(requestURL.requestURL2);
    }
    if (response2 && response2.status === 200 && response2.data.data.slots) {
      // eslint-disable-next-line prefer-destructuring
      response.data.data.slots[Object.keys(response2.data.data.slots)[0]] =
        Object.values(response2.data.data.slots)[0];
    }
    if (response.status === 200) {
      const responseLength = Object.keys(response.data.data.slots).length;
      if (setResponseLength && responseLength > 1) {
        setResponseLength(responseLength);
      }
      const slotMap = getSlotsResponse(
        slotPayload.date,
        response,
        skipStaff,
        slots,
        setSelectedDate,
        props,
        selectedDateFromReschedule,
        staffNoPref
      );
      if (slotMap.fetchAgain) {
        const payload = { ...slotPayload };
        payload.date = getFirstDayOfNextMonth(slotPayload.date);
        const firstDayOfThisMonth = new Date(
          getFirstDayOfNextMonth(new Date())
        );
        const lastDate = firstDayOfThisMonth.setMonth(
          firstDayOfThisMonth.getMonth() + 6
        );
        if (new Date(payload.date).getTime() >= lastDate) {
          if (!slotMap.availableDate) {
            const lastDateForSelectedDate = new Date(payload.date) - 1;
            slotMap.availableDate = new Date(lastDateForSelectedDate);
          }
          if (!slotMap.dateTitle) {
            slotMap.dateTitle = convertToLanguage(slotMap.availableDate);
          }
        }
        const schedulingWindow = {
          ...props.schedulingPolicy.advanceBooking,
        };
        if (schedulingWindow.month !== 0 || schedulingWindow.day !== 0) {
          schedulingWindow.month += 1;
        }
        if (
          !isPastSchedulingWindow(new Date(payload.date), schedulingWindow) &&
          new Date(payload.date).getTime() < lastDate
        ) {
          fetchSlots(
            payload,
            timeZone,
            props,
            skipStaff,
            slotMap,
            '',
            setSelectedDate,
            '',
            staffNoPref
          );
        } else {
          addSlotLoader('remove');
          props.setSlotsForServices(slotMap);
        }
      } else {
        addSlotLoader('remove');
        props.setSlotsForServices(slotMap);
      }
    }
  } catch (error) {
    if (error) {
      console.warn(error);
    }
  }
};

const getFirstAvailableDay = (month, year, availableDatesForEachMonth) => {
  const selectedMonth = month + 1 > 9 ? `${month + 1}/` : `0${month + 1}/`;
  const selectedYear = `/${year}`;
  if (availableDatesForEachMonth) {
    for (const availabledate of availableDatesForEachMonth) {
      const testing = new RegExp(`${selectedMonth}([0-9][0-9])${selectedYear}`);
      if (testing.test(availabledate)) {
        return availabledate;
      }
    }
  }
  return null;
};

const getSlots = (
  props,
  currentDate,
  timeZone,
  skipStaff,
  slots,
  setResponseLength,
  setSelectedDate,
  selectedDateFromReschedule,
  staffNoPref
) => {
  const slotPayload = getSlotsPayload(
    props.selectedService,
    props.selectedStaff.key,
    currentDate
  );
  try {
    addSlotLoader('add');
    fetchSlots(
      slotPayload,
      timeZone,
      props,
      skipStaff,
      slots,
      setResponseLength,
      setSelectedDate,
      selectedDateFromReschedule,
      staffNoPref
    );
  } catch (error) {
    if (error) {
      console.log(error);
    }
  }
};

const unavailableDates = (date, unavailabilityDates) => {
  if (unavailabilityDates) {
    return unavailabilityDates.indexOf(convertToLanguage(date, true)) !== -1;
  }
  return false;
};

const getMonthLabel = (appt, localTimeZone, companyTimeZone) => {
  DateTime.fromMillis(appt.startTime)
    .setZone(localTimeZone || companyTimeZone)
    .toFormat('MMMM, yyyy');
};

const getClassSessionSlotByStaffKey = (
  inventory,
  seletedClassKey,
  slotsFromInventory,
  monthYear,
  staffKey,
  selectedDate,
  timeZone,
  format,
  currentClsTime
) => {
  Object.keys(inventory[seletedClassKey][monthYear][staffKey]).forEach(
    (time) => {
      if (
        DateTime.fromMillis(parseInt(time, 10))
          .setZone(timeZone)
          .toFormat(DATE_MONTH_YEAR_FORMAT) ===
          DateTime.fromJSDate(selectedDate).toFormat(DATE_MONTH_YEAR_FORMAT) ||
        (convertDateToSelectedTZ(parseInt(time, 10), timeZone).getTime() >=
          selectedDate.getTime() &&
          convertDateToSelectedTZ(parseInt(time, 10), timeZone).getTime() <=
            selectedDate.getTime() + 86399000)
      ) {
        const timeValue = DateTime.fromMillis(parseInt(time, 10))
          .setZone(timeZone)
          .toFormat(format);
        if (
          !slotsFromInventory.find((obj) => obj.id === time) &&
          time !== currentClsTime
        ) {
          const slot = {};
          slot.id = time;
          // eslint-disable-next-line prefer-destructuring
          slot.sessionKey =
            inventory[seletedClassKey][monthYear][staffKey][time][0];
          slot.staffKey = staffKey;
          slot.value = timeValue.toLowerCase();
          slotsFromInventory.push(slot);
        }
      }
    }
  );
};

const sortArrayOfObjectsByTime = (array) => {
  return array.sort((a, b) => {
    const timeA = parseInt(a.id, 10);
    const timeB = parseInt(b.id, 10);
    if (timeA < timeB) return -1;
    if (timeA > timeB) return 1;
    return 0;
  });
};

const classSessionSlotMapGen = (
  currentClsTime,
  isSkipStaff,
  seletedStaffKey,
  chosenDate,
  inventory,
  timeZone,
  timeFormat,
  setSlots,
  setChosenDate,
  staffNoPref
) => {
  const slotMap = {
    slots: [],
    dateTitle: '',
    noSlots: true,
    unavailableDates: [],
    availableDate: new Date(),
  };
  if (window.companyJson && window.companyJson.companyDate) {
    slotMap.availableDate = new Date(window.companyJson.companyDate);
  }
  const selectedDate = !chosenDate
    ? new Date(
        DateTime.fromMillis(parseInt(inventory.firstSlot, 10))
          .setZone(timeZone)
          .toFormat(DATE_MONTH_YEAR_FORMAT)
      )
    : chosenDate;
  const monthYear = DateTime.fromJSDate(selectedDate).toFormat('MMyyyy');
  const slotsFromInventory = [];
  const seletedClassKey = Object.keys(inventory)[0];
  const format = timeFormat === 24 ? 'HH:mm' : 'h:mm a';
  if (
    inventory &&
    inventory[seletedClassKey] &&
    inventory[seletedClassKey][monthYear]
  ) {
    Object.keys(inventory[seletedClassKey][monthYear]).forEach((staffKey) => {
      if (
        (!staffNoPref && !isSkipStaff && staffKey === seletedStaffKey) ||
        isSkipStaff ||
        staffNoPref
      )
        getClassSessionSlotByStaffKey(
          inventory,
          seletedClassKey,
          slotsFromInventory,
          monthYear,
          staffKey,
          selectedDate,
          timeZone,
          format,
          currentClsTime
        );
    });

    if (slotsFromInventory.length > 0) slotMap.noSlots = false;
    slotMap.dateTitle = convertToLanguage(selectedDate);
    slotMap.slots =
      slotsFromInventory.length > 0
        ? sortArrayOfObjectsByTime(slotsFromInventory)
        : slotsFromInventory;
    slotMap.availableDate = selectedDate;

    setSlots(slotMap);
    if (setChosenDate) setChosenDate(selectedDate);
  }

  setSlots(slotMap);
  if (setChosenDate) setChosenDate(selectedDate);
};

const createArrayIfNotUpdate = (array, value) => {
  let resultArray = array;
  if (!(resultArray instanceof Array)) resultArray = [];
  resultArray.push(value);
  return resultArray;
};

const createObjectIfNotReturn = (obj) => {
  if (!(obj instanceof Object)) return {};
  return obj;
};

const classSessionSlotsGen = (
  inventory,
  selectedClsKey,
  timeZone,
  resourceOrderList,
  currentClsTime,
  selectedClassProvider,
  isSkipStaff,
  staffNoPref
) => {
  const slots = {};
  inventory.forEach((classSlots) => {
    if (classSlots.key === selectedClsKey) {
      const availabilityOfSelectedClass = JSON.parse(
        classSlots.staffAvailability
      );
      let staffKeys = resourceOrderList;
      if (PAGE_DETAILS.isStaffPage) {
        staffKeys = [STAFFKEY];
      }
      slots[classSlots.key] = {};
      if (selectedClassProvider && !isSkipStaff && !staffNoPref) {
        staffKeys = [selectedClassProvider];
      }
      if (staffKeys) {
        staffKeys.forEach((staffKey) => {
          if (!availabilityOfSelectedClass[staffKey]) return;
          Object.keys(availabilityOfSelectedClass[staffKey]).forEach((time) => {
            if (currentClsTime && parseInt(time, 10) === currentClsTime) return;
            const monthYear = DateTime.fromMillis(parseInt(time, 10))
              .setZone(timeZone)
              .toFormat('MMyyyy');
            const availableDate = DateTime.fromMillis(parseInt(time, 10))
              .setZone(timeZone)
              .toFormat(DATE_MONTH_YEAR_FORMAT);

            slots[classSlots.key][monthYear] = createObjectIfNotReturn(
              slots[classSlots.key][monthYear]
            );
            slots[classSlots.key][monthYear][staffKey] =
              createObjectIfNotReturn(
                slots[classSlots.key][monthYear][staffKey]
              );

            slots[classSlots.key][monthYear][staffKey][time] =
              createArrayIfNotUpdate(
                slots[classSlots.key][monthYear][staffKey][time],
                ...availabilityOfSelectedClass[staffKey][time]
              );
            slots[classSlots.key][monthYear][staffKey].availabilityDates =
              createArrayIfNotUpdate(
                slots[classSlots.key][monthYear][staffKey].availabilityDates,
                availableDate
              );

            if (
              slots[classSlots.key][monthYear][staffKey].firstAvailableTime &&
              time <
                slots[classSlots.key][monthYear][staffKey].firstAvailableTime[0]
            )
              slots[classSlots.key][monthYear][staffKey].firstAvailableTime =
                time;

            slots.firstSlot = createArrayIfNotUpdate(slots.firstSlot, time);
          });
        });
      }
      slots.firstSlot = slots.firstSlot && slots.firstSlot.sort()[0];
    }
  });
  return slots;
};

const getClsInventoryPostApplyingLeadTimeAndSchedulingWindow = (
  leadTime,
  advanceBooking,
  inventories,
  staffKey
) => {
  let classInventories;

  let currentDayMillis = new Date().getTime();
  if (window.companyJson && window.companyJson.companyDate) {
    currentDayMillis = new Date(window.companyJson.companyDate).getTime();
  }
  const datePlusLeadTimeInMillis =
    currentDayMillis + getAdvanceDurationMins(leadTime) * 60 * 1000;
  const lastDateOfSchedulingWindow =
    getLastDateOfSchedulingWindow(advanceBooking);
  const schedulingWindow = lastDateOfSchedulingWindow
    ? lastDateOfSchedulingWindow.getTime()
    : Number.MAX_VALUE;

  classInventories = inventories.map((clsInventory) => {
    const inventory = clsInventory;
    inventory.startTimes = inventory.startTimes.filter((time) => {
      return time > datePlusLeadTimeInMillis && time < schedulingWindow;
    });

    inventory.startTimeLong =
      inventory.startTimes.length === 0 ? '' : inventory.startTimes.sort()[0];

    const staffAvailability = inventory.staffAvailability
      ? JSON.parse(inventory.staffAvailability)
      : {};
    Object.keys(staffAvailability).forEach((resKey) => {
      const targetObj = staffAvailability[resKey];
      const timesToDel = Object.keys(targetObj).filter((time) => {
        return time < datePlusLeadTimeInMillis || time > schedulingWindow;
      });

      timesToDel.forEach((time) => {
        delete targetObj[time];
      });

      if (PAGE_DETAILS.isStaffPage) {
        if (resKey !== STAFFKEY) {
          delete staffAvailability[resKey];
        }
      }
      if (staffKey && resKey !== staffKey) {
        delete staffAvailability[resKey];
      }

      if (CommonFunctions.isObjectNullOrEmpty(targetObj))
        delete staffAvailability[resKey];
      inventory.staffAvailability = JSON.stringify(staffAvailability);
    });
    return inventory;
  });

  classInventories.startTimeLong = CommonFunctions.isObjectNullOrEmpty(
    inventories.startTimeLong
  )
    ? []
    : inventories.startTimeLong.sort()[0];

  classInventories = inventories.filter((cls) => {
    return (
      !CommonFunctions.isObjectNullOrEmpty(cls.startTimes) &&
      Object.keys(JSON.parse(cls.staffAvailability)).length > 0
    );
  });

  return classInventories;
};

const showLoaderForDatePicker = () => {
  if (document.getElementsByClassName('slot-choosing')[0]) {
    for (const element of document.getElementsByClassName('slot-choosing')[0]
      .children) {
      element.style.display = 'none';
    }
    document
      .getElementsByClassName('slot-choosing')[0]
      .classList.add('loader', 'loader--secondary');
  }
};

const fetchSlotsForMonthChange = (selectedMonth, selectedYear, props) => {
  const timeZone = CommonFunctions.isNullOrEmpty(props.localTimeZone)
    ? props.companyDetails.timeZone
    : props.localTimeZone;

  const isNotFetched =
    props.slotsForServices?.fetchedMonths?.indexOf(
      `${selectedMonth + 1}/${selectedYear}`
    ) === -1;
  const slotsForServices = { ...props.slotsForServices };

  slotsForServices.availableDate = '';
  slotsForServices.dateTitle = '';

  const dateString = `1/${selectedMonth}/${selectedYear}`;
  const dateParts = dateString.split('/');
  const nextFetchDate = new Date(+dateParts[2], dateParts[1], +dateParts[0]);

  if (
    props.activeTab === 'Book Appointment' &&
    isNotFetched &&
    ((props.previousMonth !== undefined &&
      (props.previousMonth < selectedMonth ||
        props.previousYear <= selectedYear)) ||
      props.previousMonth === undefined)
  ) {
    showLoaderForDatePicker();

    getSlots(
      props,
      new Date(nextFetchDate),
      timeZone,
      props.viewSettings.bookingOption.isSkipStaff &&
        !PAGE_DETAILS.isStaffPage &&
        !props.isApptReschedule,
      slotsForServices,
      props.setResponseLength,
      props.setChosenDate,
      '',
      props.staffNoPref
    );
    props.setIsUpdated(true);
  } else {
    const date = new Date(+selectedYear, selectedMonth, +1);
    const firstAvailableDate = getFirstAvailableDay(
      selectedMonth,
      selectedYear,
      slotsForServices.availableDatesForEachMonth
    );
    if (!CommonFunctions.isNullOrEmpty(firstAvailableDate)) {
      slotsForServices.availableDate = new Date(firstAvailableDate);
    } else if (nonWorkingDay(new Date(date), props)) {
      slotsForServices.availableDate = getNextWorkingDate(
        new Date(date),
        props
      );
    } else {
      slotsForServices.availableDate = new Date(date);
    }
    slotsForServices.dateTitle = convertToLanguage(
      slotsForServices.availableDate
    );
    const fetchedMonth = DateTime.fromJSDate(
      new Date(slotsForServices.availableDate)
    ).toFormat('M/yyyy');
    if (slotsForServices.fetchedMonths.indexOf(fetchedMonth) === -1) {
      slotsForServices.fetchedMonths.push(fetchedMonth);
      slotsForServices.nonAvailableMonths.push(fetchedMonth);
      slotsForServices.unavailableDates.push(slotsForServices.availableDate);
    }
    props.setSlotsForServices(slotsForServices);
  }
};

const disablePreviousMonth = (monthSelected, selectedYear, currentDate) => {
  if (document.getElementsByClassName('flatpickr-prev-month')[0]) {
    if (
      monthSelected === currentDate.getMonth() &&
      selectedYear === currentDate.getFullYear()
    ) {
      document.getElementsByClassName(
        'flatpickr-prev-month'
      )[0].style.pointerEvents = 'none';
      document.getElementsByClassName('flatpickr-prev-month')[0].style.opacity =
        '0.4';
    } else {
      document.getElementsByClassName(
        'flatpickr-prev-month'
      )[0].style.pointerEvents = 'auto';
      document.getElementsByClassName('flatpickr-prev-month')[0].style.opacity =
        '1';
    }
  }
};

const disableNextMonth = (
  monthSelected,
  selectedYear,
  currentDate,
  isPastScheduling
) => {
  if (document.getElementsByClassName('flatpickr-next-month')[0]) {
    if (
      isPastScheduling &&
      monthSelected === currentDate.getMonth() &&
      selectedYear === currentDate.getFullYear()
    ) {
      document.getElementsByClassName(
        'flatpickr-next-month'
      )[0].style.pointerEvents = 'none';
      document.getElementsByClassName('flatpickr-next-month')[0].style.opacity =
        '0.4';
    } else {
      document.getElementsByClassName(
        'flatpickr-next-month'
      )[0].style.pointerEvents = 'auto';
      document.getElementsByClassName('flatpickr-next-month')[0].style.opacity =
        '1';
    }
  }
};

const disableNonWorkingDays = (dayElem, props) => {
  const isNonWorkingDay = nonWorkingDay(dayElem.dateObj, props);
  if (isNonWorkingDay) {
    dayElem.classList.add('not-avail');
    dayElem.classList.add('flatpickr-disabled');
  }
};

const autoAdjustMonthWidth = (instance, date) => {
  let monthsDropDown;
  let currentMonth;
  if (instance) {
    monthsDropDown = instance.monthElements[0];
    currentMonth = instance.currentMonth;
  } else {
    monthsDropDown = document.getElementsByClassName(
      'flatpickr-monthDropdown-months'
    )[0];
    currentMonth = date.getMonth();
  }
  if (monthsDropDown) {
    const dummyElem = document.createElement('span');
    dummyElem.style.visibility = 'hidden';
    dummyElem.style.position = 'absolute';
    dummyElem.textContent = monthsDropDown.options[currentMonth].text;
    document.body.appendChild(dummyElem);
    monthsDropDown.style.width = `${dummyElem.offsetWidth}px`;
    dummyElem.remove();
  }
};

const DateAndTimeUtils = {
  convertDateToSelectedTZ,
  getIso,
  getSlots,
  getAdvanceDurationMins,
  isPastSchedulingWindow,
  unavailableDates,
  nonWorkingDay,
  getMonthLabel,
  convertToLanguage,
  classSessionSlotsGen,
  classSessionSlotMapGen,
  getClsInventoryPostApplyingLeadTimeAndSchedulingWindow,
  getAdvanceLeadTimeDate,
  getNextWorkingDate,
  fetchSlotsForMonthChange,
  getFirstDayOfNextMonth,
  disablePreviousMonth,
  disableNextMonth,
  showLoaderForDatePicker,
  removeLoaderForDatePicker,
  getLastDateOfSchedulingWindow,
  disableNonWorkingDays,
  getSlotsResponse,
  autoAdjustMonthWidth,
  getEndDayOfMonth,
  computeAdvancedLeadTimeDate,
};

export default DateAndTimeUtils;
