import { combineReducers } from 'redux';

const step = (state, action) => {
  return action && action.type === 'STEP' && action.payload
    ? action.payload
    : state || 'service';
};

const viewSettings = (state, action) => {
  return action.type === 'FETCH_VIEW_SETTINGS' && action.payload
    ? action.payload
    : state || '';
};
const leaseObj = (state, action) => {
  return action.type === 'LEASE_ID' ? action.payload : state || '';
};
const companyDetails = (state, action) => {
  return action.type === 'FETCH_COMPANY_DETAILS' && action.payload
    ? action.payload
    : state || '';
};

const banner = (state, action) => {
  return action.type === 'FETCH_BANNER' && action.payload
    ? action.payload
    : state || '';
};

const paymentDetails = (state, action) => {
  return action.type === 'FETCH_PAYMENT_DETAILS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const paymentAuthInfo = (state, action) => {
  return action.type === 'FETCH_PAYMENT_AUTH' ? action.payload : state || '';
};

const stripePublicKey = (state, action) => {
  return action.type === 'GET_STRIPE_PUBLICKEY' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const squareLocation = (state, action) => {
  return action.type === 'FETCH_SQUARE_DETAILS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const lawpayinfo = (state, action) => {
  return action.type === 'FETCH_LAWPAY_DETAILS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const schedulingPolicy = (state, action) => {
  return action.type === 'FETCH_SCHEDULING_POLICY' && action.payload
    ? action.payload
    : state || '';
};

const selectedService = (state, action) => {
  return action.type === 'SELECTED_SERVICE' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const selectedStaff = (state, action) => {
  return action.type === 'SELECTED_STAFF' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const updateState = (state, action) => {
  const { payload } = action;
  let updatedState;
  if (state.length > 0 && payload !== undefined && payload.length > 0) {
    updatedState = state.concat(payload);
    return updatedState;
  }
  if (state.length > 0 && (payload === undefined || payload.length === 0)) {
    return state;
  }
  return payload !== undefined ? payload : state || '';
};

const serviceList = (state, action) => {
  if (action.type === 'FETCH_SERVICES') {
    const existingState = state || '';
    const { payload } = action;
    let updatedState;
    if (
      existingState.length > 0 &&
      payload.length > 0 &&
      existingState !== 'fetching'
    ) {
      updatedState = existingState.concat(payload);
      return updatedState;
    }
    if (payload.length === 0 && existingState === 'fetching') {
      return payload;
    }
    if (existingState.length > 0 && payload.length === 0) {
      return existingState;
    }
    return payload || state || '';
  }
  if (action.type === 'UPDATE_SERVICES') {
    return action.payload;
  }
  return state || '';
};

const eventServiceList = (state, action) => {
  if (action.type === 'FETCH_EVENT_SERVICES') {
    return updateState(state || '', action);
  }
  return state || '';
};

const serviceCategory = (state, action) => {
  return action.type === 'FETCH_SERVICES_CATEGORY' &&
    action.payload &&
    action.payload.length > 0
    ? action.payload
    : state || '';
};

const categoryOrder = (state, action) => {
  return action.type === 'FETCH_ORDER' && action.payload
    ? action.payload
    : state || '';
};

const staffList = (state, action) => {
  switch (action.type) {
    case 'FETCH_STAFF': {
      return updateState(state || [], action);
    }
    case 'UPDATEDSTAFFLISTBYORDER': {
      return action.payload;
    }
    default:
      return state || '';
  }
};

const eventStaffList = (state, action) => {
  if (action.type === 'FETCH_EVENT_STAFF') {
    return updateState(state || [], action);
  }
  return state || '';
};

const staffCursor = (state, action) => {
  return action.type === 'STAFF_CURSOR' ? action.payload : state || '';
};

const customer = (state, action) => {
  return action.type === 'CUSTOMER' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const previousCustomer = (state, action) => {
  return action.type === 'PREVIOUS_CUSTOMER' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const categoryKey = (state, action) => {
  return action.type === 'CATEGORY_KEY' ? action.payload : state || '';
};

const selectedDateAndTime = (state, action) => {
  return action.type === 'SELECTED_DATE' ? action.payload : state || '';
};

const appointmentComments = (state, action) => {
  return action.type === 'APPT_COMMENTS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const canDisplayPageBlock = (state, action) => {
  return action.type === 'DISPLAY_PAGE_BLOCK' && action.payload !== undefined
    ? action.payload
    : state || false;
};

const appointment = (state, action) => {
  return action.type === 'APPOINTMENT' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const timeZones = (state, action) => {
  if (action.type === 'FETCH_TIMEZONE') {
    const existingState = state || [];
    const { payload } = action;
    let updatedState;
    if (existingState.length > 0 && payload) {
      updatedState = existingState.concat(payload);
      return updatedState;
    }
    if (payload) return [payload];
  }
  if (action.type === 'FETCH_ALL_TIMEZONES') {
    return action.payload && action.payload !== undefined ? action.payload : '';
  }
  return state || '';
};

const localTimeZone = (state, action) => {
  return action.type === 'SET_LOCAL_TIMEZONE' && action.payload
    ? action.payload
    : state || '';
};

const serviceCursor = (state, action) => {
  return action.type === 'SERVICE_CURSOR' ? action.payload : state || '';
};

const error = (state, action) => {
  return action.type === 'Error' ? action.payload : state || '';
};

const loginButtonStep = (state, action) => {
  return action.type === 'LOGIN_BUTTON_STEP' ? action.payload : state || '';
};

const bookingPolicy = (state, action) => {
  return action.type === 'BOOKING_POLICY' ? action.payload : state || false;
};

const displayResponsiveMenu = (state, action) => {
  return action.type === 'RESPONSIVE_MENU' ? action.payload : state || false;
};

const staffWorkingHours = (state, action) => {
  return action.type === 'STAFF_WORKING_HOURS' && action.payload
    ? action.payload
    : state || '';
};

const unavailabilityDates = (state, action) => {
  if (action.type === 'UNAVAILABLE_DATES') {
    return updateState(state || [], action);
  }
  if (action.type === 'RESET_UNAVAILABLE_DATES') {
    return '';
  }
  return state || '';
};

const mobilePopUpData = (state, action) => {
  return action.type === 'MOBILE_POPUP' ? action.payload : state || '';
};

const isRequestProcessed = (state, action) => {
  return action.type === 'REQUEST_PROCESSED' ? action.payload : state || false;
};

const classInventoryList = (state, action) => {
  if (action.type === 'FETCH_CLASS_INVENTORY') {
    return updateState(state || '', action);
  }
  if (action.type === 'UPDATE_CLASS_INVENTORY') {
    return action.payload;
  }
  return state || '';
};

const classInventoryCursor = (state, action) => {
  if (action.type === 'CLASS_INVENTORY_CURSOR') {
    return action.payload;
  }
  if (action.type === 'MANUAL_CLASS_INVENTORY_CURSOR') {
    return action.payload + state;
  }
  return state || '';
};

const classCategoryList = (state, action) => {
  if (action.type === 'FETCH_CLASS_CATEGORY') {
    return updateState(state || '', action);
  }
  if (action.type === 'UPDATE_CLASS_CATEGORY') {
    return action.payload;
  }
  return state || '';
};

const classesList = (state, action) => {
  if (action.type === 'FETCH_CLASSES') {
    return updateState(state || '', action);
  }
  if (action.type === 'UPDATE_CLASSES') {
    return action.payload;
  }
  return state || '';
};

const customerProfileStep = (state, action) => {
  return action.type === 'CUSTOMER_PROFILE_STEP' ? action.payload : state || '';
};

const upcomingEvents = (state, action) => {
  switch (action.type) {
    case 'CUSTOMER_UPCOMING_APPOINTMENTS': {
      return updateState(state || [], action);
    }
    case 'CUSTOMER_UPCOMING_APPOINTMENTS_UPDATE':
      return action.payload;

    default:
      return state || '';
  }
};

const pastEvents = (state, action) => {
  if (action.type === 'CUSTOMER_PAST_APPOINTMENTS') {
    return updateState(state || [], action);
  }
  return state || '';
};

const pastEventsCursor = (state, action) => {
  if (action.type === 'PAST_EVENT_CURSOR') {
    return action.payload ? action.payload : '';
  }
  return state || '';
};

const futureEventsCursor = (state, action) => {
  if (action.type === 'FUTURE_EVENT_CURSOR') {
    return action.payload ? action.payload : '';
  }
  return state || '';
};

const futureEventRequestProcessed = (state, action) => {
  return action.type === 'FUTURE_EVENTS_REQUEST_PROCESSED'
    ? action.payload
    : state || false;
};

const pastEventRequestProcessed = (state, action) => {
  return action.type === 'PAST_EVENTS_REQUEST_PROCESSED'
    ? action.payload
    : state || false;
};

const isEventFetchInProgress = (state, action) => {
  return action.type === 'CUSTOMER_EVENT_REQUEST_IN_PROGRESS'
    ? action.payload
    : state || false;
};

const classStep = (state, action) => {
  return action && action.type === 'CLASS_STEP' && action.payload
    ? action.payload
    : state || 'class';
};

const selectedClass = (state, action) => {
  return action.type === 'SELECTED_CLASS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const selectedClassProvider = (state, action) => {
  return action.type === 'SELECTED_CLASS_PROVIDER' &&
    action.payload !== undefined
    ? action.payload
    : state || '';
};

const mobileCompanyLogo = (state, action) => {
  return action.type === 'MOBILE_COMPANYLOGO' ? action.payload : state || false;
};

const paymentDone = (state, action) => {
  return action.type === 'PAYMENT_DONE' ? action.payload : state || '';
};

const resetTimer = (state, action) => {
  return action.type === 'TIMER_RESET' ? action.payload : state || '';
};

const activeTab = (state, action) => {
  return action.type === 'ACTIVE_TAB' ? action.payload : state || '';
};

const reviews = (state, action) => {
  if (action.type === 'FETCH_REVIEWS') {
    return updateState(state || [], action);
  }
  return state || '';
};

const selectedAppointment = (state, action) => {
  return action.type === 'SELECTED_APPOINTMENT' ? action.payload : state || '';
};

const isApptReschedule = (state, action) => {
  return action.type === 'IS_APPT_RESCHEDULE' ? action.payload : state || false;
};

const isSessionReschedule = (state, action) => {
  return action.type === 'IS_SESSION_RESCHEDULE'
    ? action.payload
    : state || false;
};

const isCancelInProgress = (state, action) => {
  return action.type === 'IS_CANCEL_IN_PROGRESS'
    ? action.payload
    : state || false;
};

const clientHashTags = (state, action) => {
  return action.type === 'FETCH_INSTAGRAM_USER_DETAILS'
    ? action.payload
    : state || '';
};

const selectedDateAndTimeForClass = (state, action) => {
  return action.type === 'SELECTED_DATE_FOR_CLASS'
    ? action.payload
    : state || '';
};

const selectedDateAndTimeForClassReschedule = (state, action) => {
  return action.type === 'SELECTED_DATE_FOR_CLASS_RESCHEDULE'
    ? action.payload
    : state || '';
};

const selectedSessionKey = (state, action) => {
  return action.type === 'SELECTED_SESSION' ? action.payload : state || '';
};

const previousSessionKey = (state, action) => {
  return action.type === 'PREVIOUS_SESSION' ? action.payload : state || '';
};

const instagramUserPhotos = (state, action) => {
  if (action.type === 'FETCH_INSTAGRAM_USER_PHOTOS') {
    return updateState(state || [], action);
  }
  return state || '';
};

const instagramCursor = (state, action) => {
  return action.type === 'INSTAGRAM_PAGINATION' ? action.payload : state || '';
};

const reviewRating = (state, action) => {
  return action.type === 'FETCH_REVIEW_RATING' && action.payload
    ? action.payload
    : state || '';
};

const isPhotosRequestProcessing = (state, action) => {
  return action.type === 'PHOTOS_REQEUST_PROCESSED'
    ? action.payload
    : state || false;
};

const reviewSettings = (state, action) => {
  return action.type === 'FETCH_REVIEW_SETTINGS' ? action.payload : state || '';
};

const canDisplayWriteReview = (state, action) => {
  return action.type === 'DISPLAY_WRITE_REVIEW'
    ? action.payload
    : state || false;
};

const reviewCursor = (state, action) => {
  return action.type === 'REVIEW_CURSOR' ? action.payload : state || '';
};

const googleAnalyticsId = (state, action) => {
  return action.type === 'GOOGLE_ANALYTICS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const isTrackingFetched = (state, action) => {
  return action.type === 'IS_TRACKING_FETCHED'
    ? action.payload
    : state || false;
};

const googleTagManagerId = (state, action) => {
  return action.type === 'GTM_ID' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const classTimings = (state, action) => {
  return action.type === 'CLASS_TIMINGS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const facebookPixelId = (state, action) => {
  return action.type === 'FACEBOOK_PIXEL' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const facebookPageId = (state, action) => {
  return action.type === 'FACEBOOK_PAGE_ID' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const businessHours = (state, action) => {
  return action.type === 'FETCH_BUSINESS_HOURS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const bookingId = (state, action) => {
  return action.type === 'BOOKING_ID' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const classBookingId = (state, action) => {
  return action.type === 'CLASS_BOOKING_ID' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const reminderSettings = (state, action) => {
  return action.type === 'REMINDER_SETTINGS' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const videoConfiguration = (state, action) => {
  return action.type === 'VIDEO_CONFIGURATION' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const bookingPath = (state, action) => {
  return action.type === 'BOOKING_PATH' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const slotsAvailability = (state, action) => {
  return action.type === 'SLOTS_AVAILABILITY' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const trackCustomerDropOffStep = (state, action) => {
  if (action.type === 'TRACK_CUSTOMER_DROP_OFF' && action.payload) {
    return state && state.length > 0
      ? [...state, action.payload]
      : [action.payload];
  }
  return state || [];
};

const classSession = (state, action) => {
  return action.type === 'CLASS_SESSION' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const bookingPage = (state, action) => {
  return action.type === 'BOOKING_PAGE' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const serviceCategoryCursor = (state, action) => {
  return action.type === 'SERVICE_CATEGORY_CURSOR' &&
    action.payload !== undefined
    ? action.payload
    : state || '';
};

const slotsForServices = (state, action) => {
  if (action.type === 'SLOTS') {
    const existingState = state || {};
    const { payload } = action;
    if (
      existingState.slots &&
      existingState.slots.length > 0 &&
      payload.slots.length > 0
    ) {
      return payload;
    }
    if (
      existingState.slots &&
      existingState.slots.length > 0 &&
      payload.slots.length === 0
    ) {
      return existingState;
    }
    return payload || state || '';
  }
  if (action.type === 'UPDATE_SLOTS') {
    return action.payload;
  }
  return state || {};
};

const errorMsg = (state, action) => {
  if (
    action.type === 'UNAUTHORIZED_MSG' ||
    action.type === 'INVALID_REQUEST' ||
    action.type === 'UNABLE_TO_RESCHEDULE' ||
    action.type === 'INVALID_PAYPAL'
  ) {
    return action.payload;
  }
  return action.type === 'RESET_ERROR_MESSAGE' ? '' : state || '';
};

const staffStep = (state, action) => {
  return action && action.type === 'STAFF_STEP' ? action.payload : state || '';
};

const staffClassInventoryList = (state, action) => {
  if (action.type === 'UPDATE_STAFF_CLASS_INVENTORY') {
    return action.payload;
  }
  return state || '';
};

const classInventoryRequestProcessed = (state, action) => {
  if (action.type === 'CLASS_INVENTORY_REQUEST_PROCESSED') {
    return action.payload;
  }
  return state || false;
};

const isApptBookingFromStaffPage = (state, action) => {
  if (action.type === 'IS_APPT_BOOKING_FROM_STAFF_PAGE') {
    return action.payload;
  }
  return state || false;
};

const staffWorkingHoursRequestProcessed = (state, action) => {
  if (action.type === 'STAFF_WORKING_HOURS_FETCH_PROCESSED') {
    return action.payload;
  }
  return state || false;
};
const staffNoPref = (state, action) => {
  if (action.type === 'STAFF_NO_PREF') {
    return action.payload;
  }
  return state || false;
};

const isPaymentLoader = (state, action) => {
  if (action.type === 'PAYMENT_LOADER') {
    return action.payload;
  }
  return state || false;
};

const selectedServiceCategory = (state, action) => {
  if (action.type === 'SELECTED_SERVICE_CATEGORY' && action.payload) {
    return action.payload;
  }
  if (action.type === 'RESET_SELECTED_SERVICE_CATEGORY') {
    return [];
  }
  return state || [];
};
const selectedClassCategory = (state, action) => {
  if (
    action.type === 'SELECTED_CLASS_CATEGORY' &&
    action.payload !== undefined
  ) {
    return action.payload;
  }

  if (action.type === 'RESET_SELECTED_CLASS_CATEGORY') {
    return '';
  }
  return state || '';
};

const paymentStatus = (state, action) => {
  if (action.type === 'PAYMENT_STATUS') {
    return action.payload;
  }
  return state || '';
};

const paymentLineItem = (state, action) => {
  if (action.type === 'PAYMENT_LINE_ITEM') {
    return action.payload;
  }
  return state || '';
};
const userRef = (state, action) => {
  return action.type === 'USER_REF' && action.payload !== undefined
    ? action.payload
    : state || '';
};
const fbCheckboxState = (state, action) => {
  if (action.type === 'FB_CHECKBOX_STATE') {
    return action.payload;
  }
  return state || false;
};
const promoResponse = (state, action) => {
  return action.type === 'PROMO_RESPONSE' && action.payload !== undefined
    ? action.payload
    : state || '';
};
const promoCode = (state, action) => {
  return action.type === 'PROMO_CODE' && action.payload !== undefined
    ? action.payload
    : state || '';
};

const isPWAAlreadyInstalled = (state, action) => {
  return action.type === 'IS_PWA_ALREADY_INSTALLED'
    ? action.payload
    : state || false;
};

const isStandaloneApp = (state, action) => {
  return action.type === 'IS_STANDALONE_APP' ? action.payload : state || false;
};

const A2HSPopup = (state, action) => {
  return action.type === 'A2HS_POPUP' ? action.payload : state || false;
};

const isConfirmationPopup = (state, action) => {
  return action.type === 'CONFIRMATION_PWA' ? action.payload : state || false;
};

const paypalScriptError = (state, action) => {
  if (action.type === 'PAYPAL_SCRIPT_FAILED') {
    return action.payload;
  }
  return state || false;
};

const assignedProviders = (state, action) => {
  return action.type === 'GET_ASSIGNED_PROVIDERS' &&
    action.payload !== undefined
    ? action.payload
    : state || '';
};

const isStaffFetchInProcess = (state, action) => {
  return action.type === 'STAFF_FETCH_PROCESSING'
    ? action.payload
    : state || false;
};

const saveCustomerError = (state, action) => {
  return action.type === 'SAVE_CUSTOMER_ERROR' ? action.payload : state || '';
};

export default combineReducers({
  step,
  viewSettings,
  companyDetails,
  paymentDetails,
  paymentAuthInfo,
  squareLocation,
  lawpayinfo,
  schedulingPolicy,
  selectedService,
  selectedStaff,
  serviceList,
  serviceCategory,
  categoryOrder,
  staffList,
  staffCursor,
  customer,
  previousCustomer,
  categoryKey,
  selectedDateAndTime,
  appointmentComments,
  canDisplayPageBlock,
  timeZones,
  localTimeZone,
  serviceCursor,
  error,
  loginButtonStep,
  bookingPolicy,
  displayResponsiveMenu,
  staffWorkingHours,
  unavailabilityDates,
  mobilePopUpData,
  isRequestProcessed,
  appointment,
  classInventoryList,
  classInventoryCursor,
  classCategoryList,
  classesList,
  customerProfileStep,
  upcomingEvents,
  pastEvents,
  pastEventsCursor,
  futureEventsCursor,
  futureEventRequestProcessed,
  pastEventRequestProcessed,
  isEventFetchInProgress,
  eventServiceList,
  eventStaffList,
  classStep,
  selectedClass,
  selectedClassProvider,
  selectedDateAndTimeForClass,
  selectedDateAndTimeForClassReschedule,
  selectedSessionKey,
  previousSessionKey,
  mobileCompanyLogo,
  activeTab,
  reviews,
  selectedAppointment,
  isApptReschedule,
  isSessionReschedule,
  isCancelInProgress,
  clientHashTags,
  instagramUserPhotos,
  instagramCursor,
  reviewRating,
  isPhotosRequestProcessing,
  reviewSettings,
  canDisplayWriteReview,
  reviewCursor,
  paymentDone,
  googleAnalyticsId,
  classTimings,
  businessHours,
  facebookPixelId,
  bookingId,
  classBookingId,
  reminderSettings,
  videoConfiguration,
  resetTimer,
  bookingPath,
  stripePublicKey,
  slotsAvailability,
  trackCustomerDropOffStep,
  classSession,
  bookingPage,
  serviceCategoryCursor,
  slotsForServices,
  errorMsg,
  leaseObj,
  staffStep,
  staffClassInventoryList,
  classInventoryRequestProcessed,
  isApptBookingFromStaffPage,
  banner,
  facebookPageId,
  staffWorkingHoursRequestProcessed,
  isPaymentLoader,
  staffNoPref,
  googleTagManagerId,
  selectedServiceCategory,
  selectedClassCategory,
  paymentStatus,
  paymentLineItem,
  userRef,
  fbCheckboxState,
  promoResponse,
  promoCode,
  isStandaloneApp,
  A2HSPopup,
  isPWAAlreadyInstalled,
  isTrackingFetched,
  isConfirmationPopup,
  paypalScriptError,
  assignedProviders,
  isStaffFetchInProcess,
  saveCustomerError,
});
