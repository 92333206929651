import React from 'react';
import PropTypes from 'prop-types';

const Loader = (props) => {
  return (
    <div
      data-testid="loader"
      className={`loader loader--${props.type} ${props.selector}`}
    ></div>
  );
};

Loader.defaultProps = {
  type: 'secondary',
};
Loader.propTypes = {
  selector: PropTypes.string,
  type: PropTypes.string,
};

export default Loader;
