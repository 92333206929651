import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import reducers from './reducer';

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
