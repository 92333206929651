import React from 'react';
import PropTypes from 'prop-types';

const Image = (props) => {
  if (
    props?.src === 'NA' ||
    props?.src ===
      'https://assets.setmore.com/setmore/images/hero-placeholder.webp'
  ) {
    return null;
  }

  let imageSrc = props.src ? props.src.replace('http:', 'https:') : props.src;
  const handleOnError = (e) => {
    e.target.onerror = null;
    e.target.src = props.defaultImage || '';
  };

  let imageSize = '';
  const defaultImageSize = window.isMobile.matches ? '=s75' : '=s100';
  if (
    imageSrc &&
    imageSrc.indexOf('googleusercontent') !== -1 &&
    imageSrc.indexOf('=s96-c') === -1
  ) {
    imageSize = props.sizeAttr ? props.sizeAttr : defaultImageSize;
  }

  if (
    props.type !== 'bannerImg' &&
    imageSrc &&
    imageSrc.indexOf('https://avatar.anywhere.app/files/img/') !== -1
  ) {
    const imageURL = new URL(imageSrc);
    if (window.isMobile.matches) {
      imageURL.searchParams.set('w', '120');
      imageURL.searchParams.set('h', '120');
    } else {
      imageURL.searchParams.set('w', '220');
      imageURL.searchParams.set('h', '220');
    }
    imageSrc = imageURL.toString();
  }

  return imageSrc ? (
    <img
      src={`${imageSrc}${imageSize}`}
      alt={props.alt}
      className={props.selector}
      width={props.width}
      height={props.height}
      loading={props.loading}
      onError={handleOnError}
    />
  ) : null;
};

Image.propTypes = {
  loading: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  selector: PropTypes.string,
  defaultImage: PropTypes.string,
  imageSize: PropTypes.string,
  sizeAttr: PropTypes.string,
};

export default Image;
