import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CommonFunctions from '../../utils/commonFunctions';
import ReviewRating from './Reviews/ReviewRating';
import ProfileDropdown from './ProfileDropdown';
import Image from '../genericComponents/Image';
import { H2Title } from '../genericComponents/Title';
import { PAGE_DETAILS } from '../../utils/constants';

const StickyHeader = (props) => {
  const hideProfileDropDownonScroll = () => {
    const profileDropdown =
      document.getElementsByClassName('user-account-dtls');
    if (profileDropdown.length > 0) profileDropdown[0].classList.remove('open');
  };
  const [header, showHeader] = useState(false);

  function setStickyHeader() {
    showHeader(window.pageYOffset > 50);
  }
  useEffect(() => {
    if (!header) hideProfileDropDownonScroll();
  });
  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', setStickyHeader);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', setStickyHeader);
    };
  }, [header]);

  const address = CommonFunctions.constructCompanyAddress(props.companyDetails);

  return (
    <div
      className={`desktop-header hide-mob ${
        header && !window.isMobile.matches ? 'show-header' : ''
      }`}
      data-testid="desktopHeader"
    >
      <div className="container fx fx--ai-c">
        <section className="company-info fx fx--ai-c">
          <picture>
            {props.companyDetails.logo && (
              <Image
                src={props.companyDetails.logo}
                alt={props.companyDetails.name}
              />
            )}
          </picture>
          <article>
            <div className="fx fx--ai-c">
              <H2Title title={props.companyDetails.name} />
              {props.viewSettings.menu.indexOf('review') !== -1 && (
                <ReviewRating
                  userProfile={
                    props.customerProfileStep === 'upcomingAppts' ||
                    props.customerProfileStep === 'myProfile'
                      ? 'userProfile'
                      : ''
                  }
                />
              )}
            </div>
            <address>{CommonFunctions.htmlDecode(address)}</address>
          </article>
        </section>
        <section className="user-login">
          {!PAGE_DETAILS.isReschedulePage &&
            !window.isMobile.matches &&
            props.viewSettings.bookingOption.customerLogin.isActive && (
              <ProfileDropdown desktopHeader="desktopHeader" header={header} />
            )}
        </section>
      </div>
    </div>
  );
};

StickyHeader.propTypes = {
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  customerProfileStep: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails,
    viewSettings: state.viewSettings,
    customerProfileStep: state.customerProfileStep,
  };
};
export default connect(mapStateToProps, {})(StickyHeader);
