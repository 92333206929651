import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setDisplayResponsiveMenu,
  loginButtonStepProgress,
  setErrorState,
  setPromoResponse,
  setPromoCode,
} from '../../redux/actions';
import { SUBDOMAIN, PAGE_DETAILS } from '../../utils/constants';
import CommonFunctions from '../../utils/commonFunctions';

const SubTabs = (props) => {
  let cpath = '';
  if (PAGE_DETAILS.isServicePage || PAGE_DETAILS.isClassPage) {
    if (
      !CommonFunctions.isNullOrEmpty(SUBDOMAIN) &&
      SUBDOMAIN !== '@@customDomain@@'
    ) {
      cpath = `/${window.location.pathname.split('/')[1]}`;
    } else if (window.location.pathname.split('/').length === 2) {
      cpath = `/${window.location.pathname.split('/')[1]}/${
        window.location.pathname.split('/')[2]
      }`;
    }
  }

  if (PAGE_DETAILS.isStaffPage) {
    if (
      window.location.pathname.indexOf('resourcebookingpage') !== -1 &&
      window.location.pathname.split('/')[2]
    ) {
      cpath = `/${window.location.pathname.split('/')[1]}/${
        window.location.pathname.split('/')[2]
      }`;
    } else {
      cpath = `/${window.location.pathname.split('/')[1]}`;
    }
  }

  const path =
    CommonFunctions.isNullOrEmpty(SUBDOMAIN) || SUBDOMAIN === '@@customDomain@@'
      ? props.bookingPath
      : cpath;

  const customisedLabels =
    props.viewSettings.customization.customisedLabels || '';

  const activeMenuTabs = CommonFunctions.constructSideBarActiveTabs(
    props.activeTabs,
    customisedLabels
  );

  const handleClick = (e) => {
    CommonFunctions.eventPropagation(e);
    props.setDisplayResponsiveMenu(false);
    props.loginButtonStepProgress('');
    props.setPromoResponse();
    props.setPromoCode('');
    props.setErrorState('');
    CommonFunctions.updatePageTitle(props.companyDetails, e.currentTarget.id);
  };

  const Tabs = (prop) => {
    const { title, linkTo, src } = prop;
    const { pathname } = useLocation();

    const pathParts = pathname.split('/');

    const rootPathName = pathParts[3];
    const staffPath = pathParts[2];
    const landing = pathParts[pathParts.length - 1];

    const isActive =
      pathname.includes(linkTo) ||
      ((rootPathName === '' || rootPathName === undefined) &&
        (landing === '' ||
          (PAGE_DETAILS.isStaffPage && staffPath === undefined)) &&
        ((linkTo === '/bookappointment' &&
          props.activeTabs[0] === 'bookAppt') ||
          (linkTo === '/bookclass' && props.activeTabs[0] === 'bookClass'))) ||
      (rootPathName === 'manage' &&
        ((landing === props.selectedAppointment?.cancelEventId &&
          linkTo === '/bookappointment' &&
          props.activeTabs[0] === 'bookAppt') ||
          (landing ===
            `${props.selectedAppointment?.id}_${props.customer.key}` &&
            linkTo === '/bookclass' &&
            props.activeTabs[0] === 'bookClass'))) ||
      (rootPathName === 'class' &&
        linkTo === '/bookclass' &&
        landing.length > 15) ||
      (rootPathName === 'services' &&
        linkTo === '/bookappointment' &&
        landing.length > 15) ||
      (rootPathName === 'category' &&
        ((linkTo === '/bookappointment' &&
          landing === props.serviceCategory &&
          props.serviceCategory.length > 0) ||
          (linkTo === '/bookclass' &&
            landing === props.classCategory &&
            props.classCategory.length > 0))) ||
      (rootPathName === 'session' &&
        (linkTo === landing ||
          landing === '' ||
          (landing === props.classSession &&
            props.classSession.id &&
            PAGE_DETAILS.isSessionPage &&
            linkTo === '/bookclass')));
    const className = isActive ? 'active' : undefined;

    return (
      <li
        className={className}
        data-testid={linkTo.replace('/', '')}
        id={linkTo.replace('/', '')}
        onClick={handleClick}
      >
        <NavLink to={`${path}${linkTo}`} className="active" end>
          <figure>{src}</figure>
          <span>{title}</span>
        </NavLink>
      </li>
    );
  };
  Tabs.propTypes = {
    linkTo: PropTypes.string,
    src: PropTypes.object,
    title: PropTypes.string,
    replace: PropTypes.func,
  };

  return (
    <nav className="navigation">
      <ul>
        {activeMenuTabs.map(
          (tab) =>
            tab && (
              <Tabs
                key={tab.title}
                linkTo={tab.url}
                src={tab.icon}
                alt={tab.title}
                title={tab.title}
              />
            )
        )}
      </ul>
    </nav>
  );
};

SubTabs.propTypes = {
  activeMenuTabs: PropTypes.array,
  activeTabs: PropTypes.array,
  src: PropTypes.object,
  linkTo: PropTypes.string,
  displayResponsiveMenu: PropTypes.bool,
  setDisplayResponsiveMenu: PropTypes.func,
  loginButtonStepProgress: PropTypes.func,
  setErrorState: PropTypes.func,
  viewSettings: PropTypes.object,
  classSession: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bookingPath: PropTypes.string,
  companyDetails: PropTypes.object,
  bookingPage: PropTypes.string,
  selectedService: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedAppointment: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  customer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  serviceCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  classCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setPromoResponse: PropTypes.func,
  setPromoCode: PropTypes.func,
};
const mapStateToProps = (state) => {
  return {
    displayResponsiveMenu: state.displayResponsiveMenu,
    viewSettings: state.viewSettings,
    bookingPath: state.bookingPath,
    classSession: state.classSession,
    bookingPage: state.bookingPage,
    companyDetails: state.companyDetails,
    selectedService: state.selectedService[0],
    selectedAppointment: state.selectedAppointment,
    customer: state.customer,
    serviceCategory: state.serviceCategory,
    classCategory: state.classCategoryList,
  };
};
export default connect(mapStateToProps, {
  setDisplayResponsiveMenu,
  loginButtonStepProgress,
  setErrorState,
  setPromoResponse,
  setPromoCode,
})(SubTabs);
