/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import CommonFunctions from './commonFunctions';
import { BROWSER_NAME, IS_PWA_ENABLED, TOKEN } from './constants';

class EventTracking {
  static addGoogleTagManagerScript(trackerId) {
    let currentDate;
    if (window.companyJson && window.companyJson.companyDate) {
      currentDate = new Date(window.companyJson.companyDate);
    }
    if (trackerId) {
      const script = document.createElement('script');

      script.onload = ((w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': currentDate.getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', trackerId);
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');

      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${trackerId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style = 'display:none;visibility:hidden';
      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
    }
  }

  static injectGA4Script(trackerId) {
    if (trackerId) {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${trackerId}`;
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());

        window.gtag('config', trackerId);
      };
      document.head.appendChild(script);
    }
  }

  static triggerGoogleAnalyticsEvent(trackerId) {
    if (trackerId) {
      window.ga('create', trackerId, {
        cookieFlags: 'SameSite=None; Secure',
        name: 'customerUATracker',
      });
      window.ga('customerUATracker.send', 'pageview');
    }
  }

  static triggerFbPixelEvent(trackerId) {
    if (trackerId) {
      window.fbq('init', trackerId);
      window.fbq('trackSingle', trackerId, 'PageView');
    }
  }

  static getDataToPushEvents = (type, value, activeTab) => {
    let label = 'Service staff selected';
    switch (type.toLowerCase()) {
      case 'service':
        return {
          event: 'Service selected',
          service: value,
        };
      case 'staff':
      case 'classselectedstaff':
        if (activeTab === 'Staff') label = 'Staff selected';
        else if (activeTab === 'Book Class') label = 'Class staff selected';
        return {
          event: label,
          staff: value,
        };
      case 'dateandtime':
      case 'sessiondateandtime':
        return {
          event:
            activeTab === 'Book Appointment'
              ? 'Service date and time selected'
              : 'Class session date and time selected',
          dateandtime: value,
        };
      case 'class':
        return {
          event: 'Class session selected',
          class: value,
        };
      case 'skippayment':
      case 'classsessionskippayment':
        return {
          event:
            activeTab === 'Book Appointment'
              ? 'Service payment skipped'
              : 'Class session payment skipped',
          skippayment: value,
        };
      case 'paymentconfirmed':
      case 'classsessionpaymentconfirmed':
        return {
          event:
            activeTab === 'Book Appointment'
              ? 'Service payment confirmed'
              : 'Class session payment confirmed',
          paymentconfirmed: value,
        };
      default:
        return {};
    }
  };

  static sendGAOnCustomerDropOff = (
    type,
    value,
    activeTab,
    trackCustomerDropOffStep,
    setCustomerDropOffStep,
    googleAnalyticsId
  ) => {
    if (window.APP_MODE === 'LIVE' && type && value) {
      window.dataLayer = window.dataLayer || [];
      if (
        trackCustomerDropOffStep &&
        trackCustomerDropOffStep.indexOf(type) !== -1
      ) {
        return;
      }
      const event = this.getDataToPushEvents(type, value, activeTab);
      event.companykey = TOKEN;
      window.dataLayer.push(event);

      if (
        googleAnalyticsId &&
        googleAnalyticsId !== 'null' &&
        event &&
        Object.keys.length > 0
      ) {
        if (googleAnalyticsId.startsWith('UA-')) {
          window.ga(
            'customerUATracker.send',
            'event',
            Object.values(event)[0],
            Object.values(event)[1]
          );
        }
      }

      if (window.gtag) {
        const eventType = Object.values(event)[0];

        const eventTypeMap = {
          'Service selected': 'GA4 - Service selected',
          'Class session selected': 'GA4 - Class selected',
          'Staff selected': 'GA4 - Staff selected',
          'Service staff selected': 'GA4 - Service Staff selected',
          'Class staff selected': 'GA4 - Class Staff selected',
          'Service date and time selected':
            'GA4 - Service date and time selected',
          'Class session date and time selected':
            'GA4 - Class Session date and time selected',
          'Service payment skipped': 'GA4 - Service payment skipped',
          'Class session payment skipped':
            'GA4 - Class Session payment skipped',
          'Service payment confirmed': 'GA4 - Service payment confirmed',
          'Class session payment confirmed':
            'GA4 - Class Session payment confirmed',
        };

        // window.gtag('event', "event name", "event model");
        window.gtag('event', eventTypeMap[eventType], {
          [Object.keys(event)[1]]: Object.values(event)[1],
        });
      }
      setCustomerDropOffStep(type);
    }
  };

  static sendGAEventForSetmoreOnApptBooked = (
    apptDetails,
    apptType,
    isApptBookingFromStaffPage,
    isPWAAlreadyInstalled,
    isStandaloneApp
  ) => {
    if (window.APP_MODE === 'LIVE' && apptDetails) {
      window.dataLayer = window.dataLayer || [];
      const bookedFrom = isApptBookingFromStaffPage
        ? `Staff ${apptType}`
        : apptType;
      const event = {
        event: bookedFrom,
        staffKey: apptDetails.staffKey,
        service: apptDetails.serviceName,
        companykey: TOKEN,
        'Booking Id': apptDetails.bookingId,
      };

      window.dataLayer.push(event);

      if (window.gtag) {
        const eventTypeMap = {
          'Service booked': 'GA4 - Service booked',
          'Class session booked': 'GA4 - Class Session booked',
          'Staff Service booked': 'GA4 - Staff Service booked',
          'Staff Class session booked': 'GA4 - Staff Class Session booked',
        };
        event['Staff name'] = apptDetails.staffName;
        window.gtag('event', eventTypeMap[apptType], event);
      }

      if (IS_PWA_ENABLED) {
        const eventParams = { pwa_source: CommonFunctions.getEventSource() };
        this.userEngagementTracking('appt_confirmed_source', eventParams);

        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        const device = isIOS ? 'ios' : 'andr';
        if (
          (isIOS &&
            (BROWSER_NAME.indexOf('safari') !== -1 ||
              BROWSER_NAME.indexOf('chrome') !== -1)) ||
          (!isIOS &&
            BROWSER_NAME !== 'instagram' &&
            !isPWAAlreadyInstalled &&
            !isStandaloneApp)
        ) {
          this.userEngagementTracking(
            `A2HS-${device}-booked-popup-trigger`,
            eventParams
          );
        }
      }
    }
  };

  static sendGAEventForCustomerOnApptBooked = (
    apptDetails,
    gaTrackerId,
    apptType
  ) => {
    if (window.APP_MODE === 'LIVE' && gaTrackerId && apptDetails) {
      window.ga(
        'customerUATracker.send',
        'event',
        apptType,
        apptDetails.staffName,
        apptDetails.serviceName
      );
    }
  };

  static sendFacebookPixelEvent = (serviceDetails, fbPixelId, apptType) => {
    if (window.APP_MODE === 'LIVE' && fbPixelId) {
      if (apptType === 'Book Appointment' && serviceDetails) {
        serviceDetails.forEach(() => {
          window.fbq('trackSingle', fbPixelId, 'Schedule');
        });
      } else {
        window.fbq('trackSingle', fbPixelId, 'Schedule');
      }
    }
  };

  static triggerEventOnApptBookingGA4(props, bookingId) {
    const apptDetails = CommonFunctions.getAppointmentDetails(props);
    const serviceDetails = CommonFunctions.getServiceOrClassDetails(props);
    const selectedDateAndTime =
      props.activeTab === 'Book Appointment'
        ? props.selectedDateAndTime
        : props.selectedDateAndTimeForClass;
    const dateAndTime = CommonFunctions.getApptDateAndTime(
      selectedDateAndTime,
      props.localTimeZone,
      props.viewSettings,
      props.companyDetails,
      props.timeZones
    );
    const amount =
      props.paymentDetails.isPaymentEnabled && props.paymentLineItem
        ? parseFloat(props.paymentLineItem.total)
        : parseFloat(serviceDetails.price);

    const event = {
      service: apptDetails.serviceName,
      staff: apptDetails.staffName,
      dateandtime: dateAndTime,
      staffkey: apptDetails.staffKey,
      companykey: props.companyDetails.key,
      customer: props.customer.isNewCustomer
        ? 'New customer'
        : 'Return customer',
      booking_id: bookingId,
      date_time: dateAndTime,
      service_category: serviceDetails.categoryName,
      service_duration: serviceDetails.duration,
      coupon: props.promoCode ? props.promoCode : '',
      amount:
        props.paymentStatus !== 'Skipped' && props.paymentStatus ? amount : 0,
      payment:
        (!props.paymentStatus && !props.paymentDetails.isPaymentEnabled) ||
        serviceDetails.price === 0
          ? 'No Charge'
          : 'Paid',
      payment_provider:
        props.paymentDetails.isPaymentEnabled &&
        'gateWayType' in props.paymentDetails
          ? CommonFunctions.capitalizeFirstCharOfEachWord(
              props.paymentDetails.gateWayType
            )
          : '',
      currency: CommonFunctions.getISOCurrencyCodeStripe(
        props.companyDetails.currency
      ),
      payment_status:
        (!props.paymentStatus && !props.paymentDetails.isPaymentEnabled) ||
        serviceDetails.price === 0
          ? ''
          : 'Success',
      payment_message: '',
      event: 'purchase',
      ecommerce: {
        transaction_id: bookingId,
        affiliation: serviceDetails.serviceName,
        value:
          props.paymentStatus !== 'Skipped' && props.paymentStatus ? amount : 0,
        tax: 0,
        shipping: 0,
        currency: CommonFunctions.getISOCurrencyCodeStripe(
          props.companyDetails.currency
        ),
        coupon: props.promoCode ? props.promoCode : '',
        items: CommonFunctions.getTransactionProductItemsGA4(props, bookingId),
      },
    };
    window.dataLayer.push(event);
  }

  static triggerTrackingEvents = (
    apptDetails,
    selectedService,
    activeTab,
    googleAnalyticsId,
    facebookPixelId,
    isApptBookingFromStaffPage,
    isPWAAlreadyInstalled,
    isStandaloneApp
  ) => {
    const apptType =
      activeTab === 'Book Appointment'
        ? 'Service booked'
        : 'Class session booked';
    this.sendGAEventForCustomerOnApptBooked(
      apptDetails,
      googleAnalyticsId,
      apptType
    );
    this.sendGAEventForSetmoreOnApptBooked(
      apptDetails,
      apptType,
      isApptBookingFromStaffPage,
      isPWAAlreadyInstalled,
      isStandaloneApp
    );
    this.sendFacebookPixelEvent(selectedService, facebookPixelId, activeTab);
  };

  static triggerGAEventOnCustomerDropOff = (type, props) => {
    this.sendGAOnCustomerDropOff(
      type,
      CommonFunctions.htmlDecode(
        props.activeTab === 'Book Appointment'
          ? props.selectedService && props.selectedService[0].title.trim()
          : props.selectedClass && props.selectedClass.title.trim()
      ),
      props.activeTab,
      props.trackCustomerDropOffStep,
      props.setCustomerDropOffStep,
      props.googleAnalyticsId
    );
  };

  static triggerEventOnCustomerAppointmentBookingToGTM(props, bookingId) {
    if (props.googleTagManagerId) {
      const serviceDetails = CommonFunctions.getServiceOrClassDetails(props);

      const selectedDateAndTime =
        props.activeTab === 'Book Appointment'
          ? props.selectedDateAndTime
          : props.selectedDateAndTimeForClass;

      const dateAndTime = CommonFunctions.getApptDateAndTime(
        selectedDateAndTime,
        props.localTimeZone,
        props.viewSettings,
        props.companyDetails,
        props.timeZones
      );

      const amount =
        props.paymentDetails.isPaymentEnabled && props.paymentLineItem
          ? parseFloat(props.paymentLineItem.total)
          : parseFloat(serviceDetails.price);

      const event = {
        event: 'Booking',
        customer: props.customer.isNewCustomer
          ? 'New customer'
          : 'Existing customer',
        service: serviceDetails.serviceName,
        booking_id: bookingId,
        date_time: dateAndTime,
        staff_key: serviceDetails.provider,
        customer_key: props.customer.key,
        service_category: serviceDetails.categoryName,
        service_duration: `${serviceDetails.duration} minutes`,
        amount:
          props.paymentStatus !== 'Skipped' && props.paymentStatus ? amount : 0,
        payment:
          (!props.paymentStatus && !props.paymentDetails.isPaymentEnabled) ||
          serviceDetails.price === 0
            ? 'No Charge'
            : props.paymentStatus,
        payment_provider:
          props.paymentDetails.isPaymentEnabled &&
          'gateWayType' in props.paymentDetails
            ? CommonFunctions.capitalizeFirstCharOfEachWord(
                props.paymentDetails.gateWayType
              )
            : '',
        currency: CommonFunctions.getISOCurrencyCodeStripe(
          props.companyDetails.currency
        ),

        status: 'Success',
        message: '',
        transactionId: bookingId,
        transactionAffiliation: serviceDetails.serviceName,
        transactionTotal:
          props.paymentStatus !== 'Skipped' &&
          props.paymentDetails.isPaymentEnabled &&
          props.paymentLineItem
            ? parseFloat(props.paymentLineItem.total)
            : 0,
        transactionTax: 0.0,
        transactionShipping: 0.0,
        transactionCurrency: CommonFunctions.getISOCurrencyCodeStripe(
          props.companyDetails.currency
        ),
        transactionProducts: CommonFunctions.getTransactionProductItems(props),
      };
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(event);
      this.triggerEventOnApptBookingGA4(props, bookingId);
    }
  }

  static userEngagementTracking(eventName, eventParams) {
    if (window.APP_MODE === 'LIVE') {
      if (eventName) {
        let event = {
          event: eventName,
          companykey: TOKEN,
          traffic_source: CommonFunctions.getTrafficSource(),
          industry: window.companyJson.businessType,
        };
        if (eventParams) {
          event = { ...event, ...eventParams };
        }
        window.dataLayer.push(event);
      }
    }
  }
}

export default EventTracking;
