/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import avatarThumb from '../../assets/avatarThumb.svg';
import CommonFunctions from '../../utils/commonFunctions';
import {
  loginButtonStepProgress,
  setCustomer,
  stepProgress,
  setSelectedService,
  setSelectedStaff,
  setSelectedDateAndTime,
  setLocalTimeZone,
  setCustomerProfileStep,
  fetchEventsByURL,
  resetUpcomingEvents,
  resetPastEvents,
  resetFutureEventCursor,
  resetPastEventCursor,
  updateFutureEventRequest,
  updatePastEventRequest,
  setEventFetchInProgress,
  setSelectedDateAndTimeForClass,
  setSelectedClass,
  setSelectedClassProvider,
  setSelectedSession,
  classStepProgress,
  showPageBlock,
  setBookingId,
  setClassBookingId,
  setStaffNoPref,
} from '../../redux/actions';
import Signout from '../genericComponents/Signout';

const ProfileDropdown = (props) => {
  useEffect(() => {
    const dropDown = document.getElementsByClassName('user-account-dtls');
    if (props.header && dropDown.length > 1)
      dropDown[1].classList.remove('open');
  }, [props.header]);

  const handleClick = (e) => {
    CommonFunctions.eventPropagation(e);
    e.currentTarget.classList.toggle('open');
  };

  const handleClickOnAppts = (e) => {
    CommonFunctions.eventPropagation(e);
    if (
      props.customerProfileStep !== 'upcomingAppts' &&
      props.customerProfileStep !== 'pastAppts'
    )
      props.setCustomerProfileStep('upcomingAppts');
    document.querySelectorAll('.user-account-dtls').forEach(function (element) {
      element.classList.remove('open');
    });
    if (
      !props.upcomingEvents &&
      !props.pastEvents &&
      !props.isEventFetchInProgress
    ) {
      props.setEventFetchInProgress(true);

      props.fetchEventsByURL(
        `/bookingpage/events/customer/${props.customer.key}`,
        '',
        props.eventStaffList,
        props.eventServiceList
      );
    }
    props.setStaffNoPref(false);
  };

  const login = () => {
    props.loginButtonStepProgress('customerLogin');
    if (document.getElementsByClassName('main-container').length > 0) {
      document.getElementsByClassName('main-container')[0].scrollIntoView(true);
      window.scrollBy(0, -100);
    }
    CommonFunctions.removeClassName('book-appts', 'services-container');
  };
  const customerImage =
    props.customer.CompanyLogoPath &&
    props.customer.CompanyLogoPath !== '/bookingpage/images/provider_img2.png'
      ? props.customer.CompanyLogoPath
      : avatarThumb;
  const otherTabs =
    props.activeTab &&
    props.activeTab !== 'Book Appointment' &&
    props.activeTab !== 'Book Class';
  const profileDropDownTestId =
    props.desktopHeader === 'desktopHeader'
      ? 'myProfileDropDownHeader'
      : 'myProfileDropDown';

  const signInID =
    props.desktopHeader === 'desktopHeader'
      ? 'sign-in-on-scroll'
      : 'sign-in-no-scroll';

  const signInTestId =
    props.desktopHeader === 'desktopHeader' ? 'signInHeader' : 'signIn';
  return (
    !props.loginButtonStep &&
    props.loginButtonStep !== 'skipLogin' &&
    CommonFunctions.isStandaloneLoginAllowed() &&
    !window.isFBOrInstagram &&
    ((props.activeTab === 'Book Appointment' &&
      props.step !== 'customerLogin' &&
      props.step !== 'googleLogin' &&
      props.step !== 'facebookLogin' &&
      props.step !== 'setmoreLogin' &&
      props.step !== 'createNewProfile' &&
      props.step !== 'forgotPassword') ||
      (props.activeTab === 'Book Class' &&
        props.classStep !== 'customerLogin' &&
        props.classStep !== 'googleLogin' &&
        props.classStep !== 'facebookLogin' &&
        props.classStep !== 'setmoreLogin' &&
        props.classStep !== 'createNewProfile' &&
        props.classStep !== 'forgotPassword') ||
      otherTabs) &&
    (window.cKey && props.customer.key ? (
      <div
        id="myProfile"
        className="user-account-dtls pointer g-dropdown-wrap fx fx--ai-c"
        data-testid={profileDropDownTestId}
        onClick={handleClick}
      >
        <figure>
          <img src={customerImage} alt="User name comes here" />
        </figure>
        <span className="user-name">{`${props.customer.FirstName} ${props.customer.LastName}`}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          viewBox="0 0 10 6"
          fill="none"
          className="arrow-icon"
        >
          <g clipPath="url(#a)">
            <path
              d="M5.202 3.806 8.793.215l.924.923-4.499 4.499L.717 1.136l.907-.908 3.578 3.578z"
              fill="#666"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path
                fill="#fff"
                transform="matrix(0 -1 -1 0 9.5 6)"
                d="M0 0h6v9H0z"
              />
            </clipPath>
          </defs>
        </svg>
        <div className="g-dropmenu has-arrow">
          <ul>
            <li onClick={handleClickOnAppts} data-testid="myProfile">
              <span>{window.lang.my_profile}</span>
            </li>
            <Signout component="profileDropdown" />
          </ul>
        </div>
      </div>
    ) : (
      <div
        id={signInID}
        className="user-account-dtls pointer fx fx--ai-c"
        onClick={login}
        data-testid={signInTestId}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.112 14.327A7.916 7.916 0 0 0 8 16a7.913 7.913 0 0 0 4.887-1.673c.018-.01.046-.028.074-.056A7.959 7.959 0 0 0 16 8c0-4.411-3.589-8-8-8S0 3.589 0 8a7.96 7.96 0 0 0 3.039 6.272c.029.03.059.047.073.055zM8 15a6.939 6.939 0 0 1-3.81-1.133A5.977 5.977 0 0 1 8 12.504c1.4 0 2.742.482 3.811 1.363A6.94 6.94 0 0 1 8 15zM1 8c0-3.86 3.14-7 7-7s7 3.14 7 7a6.982 6.982 0 0 1-2.375 5.249A6.967 6.967 0 0 0 8 11.505a6.966 6.966 0 0 0-4.625 1.744A6.98 6.98 0 0 1 1 8zm3-1.5c0 2.205 1.795 4 4 4s4-1.795 4-4-1.795-4-4-4-4 1.795-4 4zm1 0c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z"
            fill="#181818"
          />
        </svg>
        <span>{window.lang.sign_in}</span>
        {/* <a onClick={login} data-testid="signIn" className="fx fx--ai-c">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.112 14.327A7.916 7.916 0 0 0 8 16a7.913 7.913 0 0 0 4.887-1.673c.018-.01.046-.028.074-.056A7.959 7.959 0 0 0 16 8c0-4.411-3.589-8-8-8S0 3.589 0 8a7.96 7.96 0 0 0 3.039 6.272c.029.03.059.047.073.055zM8 15a6.939 6.939 0 0 1-3.81-1.133A5.977 5.977 0 0 1 8 12.504c1.4 0 2.742.482 3.811 1.363A6.94 6.94 0 0 1 8 15zM1 8c0-3.86 3.14-7 7-7s7 3.14 7 7a6.982 6.982 0 0 1-2.375 5.249A6.967 6.967 0 0 0 8 11.505a6.966 6.966 0 0 0-4.625 1.744A6.98 6.98 0 0 1 1 8zm3-1.5c0 2.205 1.795 4 4 4s4-1.795 4-4-1.795-4-4-4-4 1.795-4 4zm1 0c0-1.654 1.346-3 3-3s3 1.346 3 3-1.346 3-3 3-3-1.346-3-3z"
                  fill="#181818"
                />
              </svg>
              <span>{window.lang.sign_in}</span>
            </a> */}
      </div>
    ))
  );
};

ProfileDropdown.propTypes = {
  customer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loginButtonStepProgress: PropTypes.func,
  setCustomer: PropTypes.func,
  stepProgress: PropTypes.func,
  setSelectedStaff: PropTypes.func,
  setSelectedService: PropTypes.func,
  setSelectedDateAndTime: PropTypes.func,
  setLocalTimeZone: PropTypes.func,
  loginButtonStep: PropTypes.string,
  step: PropTypes.string,
  setCustomerProfileStep: PropTypes.func,
  fetchEventsByURL: PropTypes.func,
  resetEventCursor: PropTypes.func,
  resetUpcomingEvents: PropTypes.func,
  resetPastEvents: PropTypes.func,
  resetFutureEventCursor: PropTypes.func,
  resetPastEventCursor: PropTypes.func,
  updateFutureEventRequest: PropTypes.func,
  updatePastEventRequest: PropTypes.func,
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  eventStaffList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  eventServiceList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isEventFetchInProgress: PropTypes.bool,
  upcomingEvents: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pastEvents: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customerProfileStep: PropTypes.string,
  setEventFetchInProgress: PropTypes.func,
  setSelectedDateAndTimeForClass: PropTypes.func,
  setSelectedClass: PropTypes.func,
  setSelectedClassProvider: PropTypes.func,
  setSelectedSession: PropTypes.func,
  classStepProgress: PropTypes.func,
  showPageBlock: PropTypes.func,
  classStep: PropTypes.string,
  activeTab: PropTypes.string,
  setBookingId: PropTypes.func,
  setClassBookingId: PropTypes.func,
  desktopHeader: PropTypes.string,
  header: PropTypes.bool,
  setStaffNoPref: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    loginButtonStep: state.loginButtonStep,
    step: state.step,
    viewSettings: state.viewSettings,
    eventStaffList: state.eventStaffList,
    eventServiceList: state.eventServiceList,
    upcomingEvents: state.upcomingEvents,
    pastEvents: state.pastEvents,
    isEventFetchInProgress: state.isEventFetchInProgress,
    customerProfileStep: state.customerProfileStep,
    classStep: state.classStep,
    activeTab: state.activeTab,
  };
};

export default connect(mapStateToProps, {
  loginButtonStepProgress,
  setCustomer,
  stepProgress,
  setSelectedService,
  setSelectedStaff,
  setSelectedDateAndTime,
  setLocalTimeZone,
  setCustomerProfileStep,
  fetchEventsByURL,
  resetUpcomingEvents,
  resetPastEvents,
  resetFutureEventCursor,
  resetPastEventCursor,
  updateFutureEventRequest,
  updatePastEventRequest,
  setEventFetchInProgress,
  setSelectedDateAndTimeForClass,
  setSelectedClass,
  setSelectedClassProvider,
  setSelectedSession,
  classStepProgress,
  showPageBlock,
  setBookingId,
  setClassBookingId,
  setStaffNoPref,
})(ProfileDropdown);
