import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CommonFunctions from '../../utils/commonFunctions';

import {
  AwIconSolidEmail,
  AwIconSolidFacebook,
  AwIconSolidInstagram,
  AwIconSolidLink,
  AwIconSolidPhone,
  AwIconSolidPin,
  AwIconSolidTwitter,
  AwIconSolidWeb,
} from '../../svgs/AWIcons';

const ContactInfo = (props) => {
  const OfficeNumber = ({ value }) => {
    return (
      value && (
        <>
          <a href={`tel:${value}`}>
            <figure>
              <AwIconSolidPhone />
            </figure>
            <span>{value}</span>
          </a>
        </>
      )
    );
  };

  OfficeNumber.propTypes = {
    value: PropTypes.string,
  };

  const CompanyAddress = ({ address }) => {
    const companyAddress = CommonFunctions.getCompleteAddressWithCommaSeparated(
      props.companyInformation
    );
    return (
      CommonFunctions.isAddressNotEmpty(
        props.companyInformation.addressDetails
      ) && (
        <>
          <a
            href={CommonFunctions.constructHrefWithAddress(
              companyAddress,
              '-8.5036172,115.2547038'
            )}
            rel="noreferrer"
            target="_blank"
          >
            <figure>
              <AwIconSolidPin />
            </figure>
            <span className="address">
              {CommonFunctions.capitalizeFirstCharOfEachWord(
                CommonFunctions.htmlDecode(address)
              )}
            </span>
          </a>
        </>
      )
    );
  };

  CompanyAddress.propTypes = {
    address: PropTypes.string,
  };

  const Website = ({ title, link }) => {
    return (
      <>
        <a href={link} target="_blank" rel="noreferrer">
          <figure>
            <AwIconSolidWeb />
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  Website.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
  };

  const Facebook = ({ title, link }) => {
    return (
      <>
        <a href={link} target="_blank" rel="noreferrer">
          <figure>
            <AwIconSolidFacebook />
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  Facebook.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
  };

  const Twitter = ({ title, link }) => {
    return (
      <>
        <a href={link} target="_blank" rel="noreferrer">
          <figure>
            <AwIconSolidTwitter />
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  Twitter.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
  };

  const Instagram = ({ title, link }) => {
    return (
      <>
        <a href={link} target="_blank" rel="noreferrer">
          <figure>
            <AwIconSolidInstagram />
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  Instagram.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
  };

  const CustomLabels = ({ title, link }) => {
    return (
      <>
        <a href={link} target="_blank" rel="noreferrer">
          <figure>
            <AwIconSolidLink />
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  CustomLabels.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
  };

  const Email = ({ value }) => {
    return (
      value && (
        <>
          <a href={`mailto:${value}`}>
            <figure>
              <AwIconSolidEmail />
            </figure>
            <span>{window.lang.email}</span>
          </a>
        </>
      )
    );
  };

  Email.propTypes = {
    value: PropTypes.string,
  };

  const Youtube = ({ title, link }) => {
    return (
      <>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="youtube-link"
        >
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="17"
              viewBox="0 0 24 17"
              fill="none"
            >
              <path
                d="M23.4735 2.67642C23.2102 1.6234 22.3766 0.789762 21.3675 0.526508C19.4808 0 12.0219 0 12.0219 0C12.0219 0 4.5192 0 2.63254 0.526508C1.6234 0.789762 0.789762 1.6234 0.526508 2.67642C0 4.5192 0 8.46801 0 8.46801C0 8.46801 0 12.3729 0.526508 14.2596C0.789762 15.3126 1.6234 16.1024 2.63254 16.3656C4.5192 16.8483 12.0219 16.8483 12.0219 16.8483C12.0219 16.8483 19.4808 16.8483 21.3675 16.3656C22.3766 16.1024 23.2102 15.3126 23.4735 14.2596C24 12.3729 24 8.46801 24 8.46801C24 8.46801 24 4.5192 23.4735 2.67642ZM9.5649 12.0219V4.91408L15.7952 8.46801L9.5649 12.0219Z"
                fill="#C0C8CE"
              />
            </svg>
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  const LinkedIn = ({ title, link }) => {
    return (
      <>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="linkedIn-link"
        >
          <figure>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <path
                d="M19.5 0H1.45312C0.65625 0 0 0.703125 0 1.54688V19.5C0 20.3438 0.65625 21 1.45312 21H19.5C20.2969 21 21 20.3438 21 19.5V1.54688C21 0.703125 20.2969 0 19.5 0ZM6.32812 18H3.23438V8.01562H6.32812V18ZM4.78125 6.60938C3.75 6.60938 2.95312 5.8125 2.95312 4.82812C2.95312 3.84375 3.75 3 4.78125 3C5.76562 3 6.5625 3.84375 6.5625 4.82812C6.5625 5.8125 5.76562 6.60938 4.78125 6.60938ZM18 18H14.8594V13.125C14.8594 12 14.8594 10.5 13.2656 10.5C11.625 10.5 11.3906 11.7656 11.3906 13.0781V18H8.29688V8.01562H11.25V9.375H11.2969C11.7188 8.57812 12.75 7.73438 14.25 7.73438C17.3906 7.73438 18 9.84375 18 12.5156V18Z"
                fill="#C0C8CE"
              />
            </svg>
          </figure>
          <span>{title}</span>
        </a>
      </>
    );
  };

  const [showContactInfo, setShowContactInfo] = useState(false);

  useEffect(() => {
    function showContactLabel() {
      setShowContactInfo(
        props.companyDetails &&
          props.companyDetails.map(
            (info) => !CommonFunctions.isNullOrEmpty(info)
          )
      );
    }
    showContactLabel();
  }, [props.companyDetails]);
  return (
    showContactInfo &&
    props.companyDetails && (
      <div className="contact-info" data-testid="contactInfo">
        <ul>
          {props.companyDetails &&
            props.companyDetails.map(
              (info) =>
                info &&
                info.value && (
                  <li key={info.title}>
                    {info.title === 'address' &&
                      info.value &&
                      info.value.trim() && (
                        <CompanyAddress address={info.value} />
                      )}

                    {info.title === 'Website' &&
                      info.value &&
                      info.value.trim() && (
                        <Website
                          title={window.lang.website}
                          link={info.value}
                        />
                      )}

                    {info.title === 'Facebook' &&
                      info.value &&
                      info.value.trim() && (
                        <Facebook title={info.title} link={info.value} />
                      )}

                    {info.title === 'Twitter' &&
                      info.value &&
                      info.value.trim() && (
                        <Twitter title={info.title} link={info.value} />
                      )}

                    {info.title.toLowerCase() === 'instagram' &&
                      info.value &&
                      info.value.trim() && (
                        <Instagram title={info.title} link={info.value} />
                      )}

                    {info.title.toLowerCase() === 'youtube' &&
                      info.value &&
                      info.value.trim() && (
                        <Youtube title={info.title} link={info.value} />
                      )}

                    {info.title.toLowerCase() === 'linkedin' &&
                      info.value &&
                      info.value.trim() && (
                        <LinkedIn title={info.title} link={info.value} />
                      )}

                    {info.title !== 'OfficeNumber' &&
                      info.title !== 'Email' &&
                      info.title !== 'address' &&
                      info.title !== 'Facebook' &&
                      info.title !== 'Twitter' &&
                      info.title.toLowerCase() !== 'instagram' &&
                      info.title.toLowerCase() !== 'youtube' &&
                      info.title.toLowerCase() !== 'linkedin' &&
                      info.title !== 'Website' &&
                      info.value &&
                      info.value.trim() && (
                        <CustomLabels
                          title={CommonFunctions.htmlDecode(info.title)}
                          link={info.value}
                        />
                      )}

                    {info.title === 'OfficeNumber' &&
                      info.value &&
                      info.value.trim() && <OfficeNumber value={info.value} />}
                    {info.title === 'Email' &&
                      info.value &&
                      info.value.trim() && <Email value={info.value} />}
                  </li>
                )
            )}
        </ul>
      </div>
    )
  );
};

ContactInfo.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  companyInformation: PropTypes.object,
  address: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    companyInformation: state.companyDetails,
  };
};

export default connect(mapStateToProps)(ContactInfo);
