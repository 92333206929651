import AllCountries from './data';

const isValidNumber = (number, code) => {
  let selectedCountryCode = code;
  if (code && code !== 'null' && code.length === 3) {
    selectedCountryCode = AllCountries.getCountry(code).iso2;
  }
  if (window.intlTelInputUtils) {
    return window.intlTelInputUtils.isValidNumber(number, selectedCountryCode);
  }
  return true;
};

const getNumber = (evt) => {
  const re = /[^0-9-+() ]/g;
  return evt.target.value.replace(re, '');
};

const getNumberFromString = (str) => {
  const res = str.replace(/\D/g, '');
  return res;
};

const getCountryCodeFromNumber = (number) => {
  if (number && number.indexOf('+') !== -1 && window.intlTelInputUtils) {
    const nonFormattedNumberFromInput = `+${getNumberFromString(number)}`;
    const formattedNumber = window.intlTelInputUtils.formatNumber(
      nonFormattedNumberFromInput,
      '',
      4
    );
    const nonFormattedNumber = getNumberFromString(formattedNumber);
    return AllCountries.getCountryForCountryWithDialCode(
      nonFormattedNumber,
      nonFormattedNumberFromInput.replace(nonFormattedNumber, '')
    );
  }
  return null;
};

const getNonFormattedNumber = (number, selectedCountry) => {
  if (selectedCountry && window.intlTelInputUtils) {
    const nonFormattedNumber = window.intlTelInputUtils.formatNumber(
      number,
      selectedCountry.iso2,
      0
    );
    if (nonFormattedNumber && nonFormattedNumber !== 'null')
      return nonFormattedNumber.replace(`+${selectedCountry.dialCode}`, '');
  }
  return number;
};

const formatNumberInInternationalFormat = (number, iso2) => {
  if (window.intlTelInputUtils) {
    let selectedCountryCode = iso2;
    if (
      selectedCountryCode &&
      selectedCountryCode !== 'null' &&
      selectedCountryCode.length === 3
    ) {
      selectedCountryCode = AllCountries.getCountry(selectedCountryCode).iso2;
    }

    const nonFormattedNumber = getNonFormattedNumber(number);
    const formatting = window.intlTelInputUtils.numberFormat.INTERNATIONAL;
    return window.intlTelInputUtils.formatNumber(
      nonFormattedNumber,
      selectedCountryCode,
      formatting
    );
  }
  return number;
};

const formatNumber = (number, iso2) => {
  if (window.intlTelInputUtils) {
    let selectedCountryCode = iso2;
    if (
      selectedCountryCode &&
      selectedCountryCode !== 'null' &&
      selectedCountryCode.length === 3
    ) {
      selectedCountryCode = AllCountries.getCountry(selectedCountryCode).iso2;
    }

    const nonFormattedNumber = getNonFormattedNumber(number);
    const formatting = window.intlTelInputUtils.numberFormat.NATIONAL;
    let formattedNumber = window.intlTelInputUtils.formatNumber(
      nonFormattedNumber,
      selectedCountryCode,
      formatting
    );
    formattedNumber = formattedNumber || '';
    if (
      selectedCountryCode === 'in' &&
      formattedNumber.length > 10 &&
      formattedNumber[0] === '0'
    ) {
      formattedNumber = formattedNumber.slice(1);
    }
    if (formattedNumber !== number) return formattedNumber;
  }
  return number;
};

const Utils = {
  isValidNumber,
  formatNumber,
  getNumber,
  getNonFormattedNumber,
  getCountryCodeFromNumber,
  formatNumberInInternationalFormat,
};

export default Utils;
