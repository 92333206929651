import React from 'react';
const AwIconSolidCalendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="#c0c8ce"
      fillRule="evenodd"
      d="M8.122 3.5a.69.69 0 0 1 .683.596l.006.093v1.527h6.378V4.19a.69.69 0 0 1 .69-.689.69.69 0 0 1 .682.596l.007.093v1.527h2.265c1.197 0 2.167.97 2.167 2.167v2.272c0-.007-.309-.007-.69-.007H3.69l-.69.007V7.883c0-1.197.97-2.167 2.167-2.167h2.265V4.19a.69.69 0 0 1 .69-.689zM3 11.521v7.812c0 1.197.97 2.167 2.167 2.167h13.666c1.197 0 2.167-.97 2.167-2.167v-7.812l-.69.006H3.69c-.123 0-.477 0-.69-.006z"
    />
  </svg>
);
const AwIconSolidEmail = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      d="M4.688 18.188A1.69 1.69 0 0 1 3 16.5v-9c0-.403.146-.794.411-1.101.01-.012.023-.028.038-.042a1.69 1.69 0 0 1 1.238-.545h14.625a1.69 1.69 0 0 1 1.239.545.54.54 0 0 1 .038.043A1.68 1.68 0 0 1 21 7.5v9a1.69 1.69 0 0 1-1.687 1.688H4.688zm15.188-9.985l-4.212 2.905 2.344 2.167c.228.211.242.567.032.795-.107.115-.257.181-.413.181a.56.56 0 0 1-.382-.149l-2.53-2.338-1.369.944a2.35 2.35 0 0 1-1.345.417c-.485 0-.95-.144-1.345-.417l-1.369-.944-2.53 2.339c-.104.095-.24.149-.382.149a.57.57 0 0 1-.413-.18c-.211-.228-.197-.585.031-.795l2.344-2.167-4.211-2.906V7.5h.962l6.207 4.28a1.23 1.23 0 0 0 .706.218 1.24 1.24 0 0 0 .706-.218l6.207-4.28h.962v.703z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidFacebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M4.313 21h7.8a.56.56 0 0 0 .563-.562v-6.375a.56.56 0 0 0-.562-.562h-1.687v-1.875h1.688a.56.56 0 0 0 .563-.562v-.795c0-.983.39-1.925 1.085-2.62s1.637-1.085 2.62-1.085h1.17v1.875h-1.17a1.83 1.83 0 0 0-1.83 1.83v.795a.56.56 0 0 0 .563.563H17.1l-.233 1.875h-1.755a.56.56 0 0 0-.562.563v6.375a.56.56 0 0 0 .563.563h4.575c.348 0 .682-.138.928-.384s.384-.58.384-.928V4.313c0-.348-.138-.682-.384-.928S20.036 3 19.688 3H4.313c-.348 0-.682.138-.928.384S3 3.964 3 4.313v15.375c0 .348.138.682.384.928s.58.384.928.384z"
      fill="#c0c8ce"
    />
    <defs>
      <clipPath>
        <path fill="#fff" transform="translate(3 3)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

const AwIconSolidInstagram = () => (
  <svg width="24" height="24" fill="none">
    <g clipPath="url(#A)">
      <path
        fillRule="evenodd"
        d="M8.055 21h7.875A5.07 5.07 0 0 0 21 15.938V8.063A5.07 5.07 0 0 0 15.93 3H8.055A5.07 5.07 0 0 0 3 8.063v7.875A5.07 5.07 0 0 0 8.055 21zM12 16.312c.853-.002 1.686-.256 2.394-.73s1.26-1.149 1.585-1.937.409-1.655.242-2.491a4.31 4.31 0 0 0-3.39-3.384 4.31 4.31 0 0 0-2.491.247c-.788.327-1.461.879-1.934 1.588A4.31 4.31 0 0 0 7.68 12 4.32 4.32 0 0 0 12 16.312zm0-7.5c.63.001 1.246.19 1.769.541s.931.849 1.171 1.432a3.19 3.19 0 0 1 .178 1.841c-.124.618-.428 1.185-.874 1.63s-1.014.748-1.632.87a3.19 3.19 0 0 1-3.27-1.357c-.35-.524-.537-1.14-.537-1.77.002-.846.339-1.657.938-2.254s1.41-.933 2.257-.933zm6.067-1.95a.94.94 0 0 1-.937.938.94.94 0 0 1-.937-.937.94.94 0 0 1 .938-.937.94.94 0 0 1 .938.938z"
        fill="#c0c8ce"
      />
    </g>
    <defs>
      <clipPath id="A">
        <path fill="#fff" transform="translate(3 3)" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

const AwIconSolidLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      d="M12 21a9 9 0 1 0 0-18 9 9 0 1 0 0 18zm1.978-8.659l.14.004c.644 0 1.275-.261 1.731-.717l1.055-1.054a2.44 2.44 0 0 0 .721-1.739 2.44 2.44 0 0 0-.721-1.739c-.465-.465-1.083-.721-1.74-.721s-1.276.256-1.74.721L12.369 8.15a2.45 2.45 0 0 0-.709 1.87c.011.186.165.331.351.331h.021c.094-.005.18-.047.242-.117a.35.35 0 0 0 .088-.254c-.029-.495.155-.981.505-1.332l1.055-1.054c.332-.331.773-.514 1.243-.514s.912.182 1.243.514.515.773.515 1.243-.183.911-.515 1.243l-1.055 1.054a1.76 1.76 0 0 1-1.234.51 1.84 1.84 0 0 1-.101-.003L14 11.639c-.187 0-.342.146-.352.331a.35.35 0 0 0 .331.371zm-4.44 2.472a.35.35 0 0 1-.248-.103c-.137-.137-.137-.36 0-.497l4.922-4.92a.35.35 0 0 1 .248-.103c.094 0 .182.037.248.103s.103.155.103.248-.037.182-.103.248l-4.922 4.92c-.067.067-.155.104-.248.104zm-.703 2.812c-.657 0-1.276-.256-1.74-.721-.96-.959-.96-2.519 0-3.479l1.055-1.054c.456-.455 1.086-.715 1.73-.715a2.36 2.36 0 0 1 .141.004c.194.011.342.178.331.371a.35.35 0 0 1-.35.331H9.98a1.85 1.85 0 0 0-.102-.003c-.457 0-.906.186-1.231.51l-1.055 1.054a1.76 1.76 0 0 0 0 2.485c.332.332.773.515 1.243.515a1.75 1.75 0 0 0 1.243-.515l1.055-1.054c.351-.351.535-.837.507-1.333a.35.35 0 0 1 .331-.371c.006 0 .014 0 .022 0h0a.35.35 0 0 1 .349.331 2.45 2.45 0 0 1-.712 1.87l-1.055 1.054a2.45 2.45 0 0 1-1.742.721z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidWeb = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#c0c8ce">
    <path d="M4.973 15.274c.958-.435 2.002-.725 3.105-.929-.203-.928-.319-1.886-.348-2.873H3a7.74 7.74 0 0 0 1.393 4.033l.58-.232zm.377.929l-.348.145c1.277 1.451 3.076 2.408 5.078 2.64l-.087-.116c-.725-.987-1.306-2.205-1.683-3.54-1.074.174-2.06.464-2.96.87zm2.931-9.489c.348-1.248.871-2.408 1.538-3.366L10.08 3c-2.031.232-3.801 1.219-5.107 2.67l.319.145c.929.406 1.944.725 2.989.9zm-.232.958c-1.132-.203-2.176-.522-3.163-.958l-.522-.232A8.07 8.07 0 0 0 3 10.515h4.73c.029-.987.116-1.944.319-2.844zm8.879 8.763l-.203-.145c-.987-.464-2.089-.783-3.25-.987-.377 1.335-.958 2.554-1.683 3.54l-.116.174c2.089-.174 3.917-1.132 5.252-2.583zm-.174-10.707l.232-.116A7.97 7.97 0 0 0 11.705 3l.261.377c.667.958 1.161 2.118 1.538 3.366a12.85 12.85 0 0 0 3.25-1.016zm-8.067 4.759h4.382a15.64 15.64 0 0 0-.319-2.67l-1.828.116c-.638 0-1.277-.029-1.886-.116-.203.841-.319 1.77-.348 2.67zm8.909-4.121l-.406.232c-1.045.493-2.234.871-3.453 1.074.203.9.29 1.857.319 2.815h4.991c-.087-1.509-.609-2.931-1.451-4.12zm-.378 9.082l.348.232c.87-1.19 1.393-2.641 1.48-4.179h-4.991c-.029.987-.145 1.944-.348 2.873a12.98 12.98 0 0 1 3.453 1.074h.058zM10.893 3.551l-.29.377c-.58.812-1.016 1.828-1.335 2.931a15.79 15.79 0 0 0 1.654.087c.551 0 1.074-.029 1.596-.087-.319-1.103-.784-2.118-1.364-2.96l-.261-.348zm0 14.886l.116-.145c.638-.87 1.132-1.944 1.48-3.105a14.19 14.19 0 0 0-1.567-.087 14.85 14.85 0 0 0-1.625.087c.348 1.161.842 2.234 1.48 3.076l.116.174zm.029-4.324c.609 0 1.219.029 1.828.116.203-.871.29-1.799.319-2.728H8.687a16.34 16.34 0 0 0 .319 2.728 14.13 14.13 0 0 1 1.915-.116z" />
  </svg>
);

const AwIconSolidPin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      d="M5.863 9.187c0-3.493 2.832-6.325 6.325-6.325s6.325 2.832 6.325 6.325c0 2.368-.929 3.985-3.007 6.157l-1.03 1.071c-.278.298-.606.963-.925 1.855l-.497 1.6-.114.432-.071.292c-.166.725-1.199.725-1.365 0l-.071-.291-.113-.431-.495-1.597c-.318-.891-.645-1.556-.922-1.853L8.618 15.08c-1.901-2.038-2.756-3.622-2.756-5.892zm3.375 0a2.95 2.95 0 0 1 5.9 0 2.95 2.95 0 0 1-5.9 0z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
    <path
      fillRule="evenodd"
      d="M4.404 3.434l-.506.506c-1.355 1.356-1.626 3.444-.684 5.095l.118.194c2.382 3.527 5.416 6.56 8.944 8.944a4.24 4.24 0 0 0 5.284-.569l.506-.506c.712-.713.712-1.868 0-2.581l-2.131-2.13c-.661-.66-1.711-.713-2.434-.133l-.123.11c-.134.133-.283.195-.438.195s-.304-.062-.413-.171l-3.41-3.411c-.212-.212-.229-.549-.043-.78l.066-.072a1.8 1.8 0 0 0 .512-1.266c0-.484-.193-.949-.535-1.291l-2.13-2.132c-.714-.713-1.869-.713-2.582-.001z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidUser = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <path
      d="M14.384 13.848c-.777 0-1.125.438-2.384.438-1.286 0-1.634-.437-2.411-.437C7.607 13.848 6 15.516 6 17.539v1.121c0 .738.563 1.313 1.286 1.313h9.429A1.3 1.3 0 0 0 18 18.66v-1.121c0-2.024-1.634-3.691-3.616-3.691zM12 12.973c1.875 0 3.429-1.559 3.429-3.5 0-1.914-1.554-3.5-3.429-3.5-1.902 0-3.429 1.586-3.429 3.5 0 1.941 1.527 3.5 3.429 3.5z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidEdit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <path
      fillRule="evenodd"
      d="M9.265 12.251l6.847-6.847a2.28 2.28 0 0 1 3.221 0 2.28 2.28 0 0 1 0 3.221l-6.847 6.847a.75.75 0 0 1-.348.197l-2.883.721a.75.75 0 0 1-.908-.908l.721-2.883a.75.75 0 0 1 .197-.348zm2.72-5.237a.75.75 0 0 0-.749-.749H6.19l-.163.006A2.19 2.19 0 0 0 4 8.456v10.09l.006.163a2.19 2.19 0 0 0 2.184 2.027h10.09l.163-.006a2.19 2.19 0 0 0 2.027-2.184v-5.045l-.007-.102a.75.75 0 0 0-.742-.647.75.75 0 0 0-.749.749v5.045l-.008.102c-.049.334-.337.59-.685.59H6.19l-.102-.008c-.334-.05-.59-.337-.59-.685V8.456l.008-.102c.049-.334.337-.59.685-.59h5.045l.102-.007a.75.75 0 0 0 .647-.742z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidPeople = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      d="M12 3.362a2.95 2.95 0 0 0-.988 5.73 4.64 4.64 0 0 0-3.65 4.532v1.688l.006.095a.7.7 0 0 0 .694.605h1.053l.5 4.995a.7.7 0 0 0 .697.63h3.375l.106-.008a.7.7 0 0 0 .591-.622l.499-4.995h1.055a.7.7 0 0 0 .7-.7v-1.687a4.64 4.64 0 0 0-3.649-4.532A2.95 2.95 0 0 0 12 3.362zM3.987 8a2.39 2.39 0 0 1 2.388-2.387 2.39 2.39 0 0 1 .51 4.72.7.7 0 0 1 .19.48C6.373 11.915 6 13.194 6 14.501v1.81A1.19 1.19 0 0 0 7.189 17.5h1.005v2.218l.006.095a.7.7 0 0 1-.605.694l-.095.006H5.25a.7.7 0 0 1-.674-.511l-.021-.102-.486-3.887h-.507a.7.7 0 0 1-.694-.605l-.006-.095v-1.687c0-1.638 1.121-3.014 2.638-3.403A2.39 2.39 0 0 1 3.987 8zm13.638-2.387a2.39 2.39 0 0 0-.51 4.72c-.118.125-.19.294-.19.48.702 1.102 1.075 2.382 1.075 3.688v1.81a1.19 1.19 0 0 1-1.189 1.189H15.8v2.313a.7.7 0 0 0 .7.7h2.25l.104-.008a.7.7 0 0 0 .591-.605l.485-3.887h.508l.095-.006a.7.7 0 0 0 .605-.694v-1.687l-.005-.193c-.084-1.552-1.176-2.837-2.633-3.21a2.39 2.39 0 0 0-.875-4.61z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidStar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fillRule="evenodd"
      d="M11.867 3.007c-.434.044-.819.305-1.021.698l-2.3 4.559-4.379.434a1.28 1.28 0 0 0-1.089.84l-.04.13c-.108.436.019.901.342 1.221l3.631 3.596-1.344 4.884a1.29 1.29 0 0 0 .432 1.338l.111.081a1.29 1.29 0 0 0 1.282.07l4.506-2.232 4.517 2.237a1.29 1.29 0 0 0 1.389-.153l.102-.092a1.29 1.29 0 0 0 .332-1.24l-1.346-4.89 3.628-3.596c.356-.353.474-.88.303-1.351l-.053-.125c-.197-.403-.593-.678-1.047-.716l-4.369-.433-2.301-4.558C12.929 3.272 12.484 3 11.998 3l-.131.007z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidPicture = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
    <path
      fillRule="evenodd"
      d="M5.167 3.638h13.666c1.197 0 2.167.97 2.167 2.167v13.666a2.17 2.17 0 0 1-2.167 2.167H5.167C3.97 21.638 3 20.668 3 19.471V5.805c0-1.197.97-2.167 2.167-2.167zm13.666 1.379H5.167a.79.79 0 0 0-.788.788v9.444c1.212-1.183 2.869-1.911 4.696-1.911 1.87 0 3.561.763 4.78 1.994a3.98 3.98 0 0 1 2.115-.604 4 4 0 0 1 3.651 2.364V5.805a.79.79 0 0 0-.788-.788zm-3.509 1.391c-1.605 0-2.906 1.301-2.906 2.906s1.301 2.906 2.906 2.906 2.906-1.301 2.906-2.906-1.301-2.906-2.906-2.906z"
      fill="#c0c8ce"
    />
  </svg>
);

const AwIconSolidTwitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      d="M3.067 17.183c-.027.126-.009.257.05.372s.155.206.273.258c1.949.911 4.067 1.404 6.218 1.448a9.85 9.85 0 0 0 5.445-1.545 9.55 9.55 0 0 0 3.155-3.418c.754-1.382 1.154-2.928 1.165-4.502v-.338l1.343-.968a.56.56 0 0 0 .243-.529.56.56 0 0 0-.113-.28c-.063-.081-.146-.144-.242-.181l-1.043-.45.585-1.275c.041-.099.052-.207.033-.313s-.067-.203-.14-.282-.166-.135-.269-.163-.213-.025-.315.007l-1.627.54a3.9 3.9 0 0 0-2.4-.833c-1.044.002-2.044.417-2.782 1.155S11.492 7.626 11.49 8.67v.27c-2.04.24-3.99-.803-6.135-3.262a.56.56 0 0 0-.26-.168c-.101-.03-.208-.032-.309-.005s-.193.083-.265.159a.56.56 0 0 0-.141.275 5.85 5.85 0 0 0 .367 3.81l-1.042-.135a.56.56 0 0 0-.493.167.56.56 0 0 0-.134.232c-.025.087-.029.179-.011.268.084.608.305 1.188.647 1.698s.795.934 1.326 1.242l-.488.18c-.07.028-.134.07-.188.123a.56.56 0 0 0-.167.408c.001.076.017.15.048.22.255.517.631.965 1.096 1.305s1.005.564 1.574.652c-1.049.407-2.167.611-3.293.6a.56.56 0 0 0-.555.473z"
      fill="#c0c8ce"
    />
  </svg>
);

export {
  AwIconSolidEmail,
  AwIconSolidFacebook,
  AwIconSolidInstagram,
  AwIconSolidCalendar,
  AwIconSolidLink,
  AwIconSolidWeb,
  AwIconSolidPin,
  AwIconSolidPhone,
  AwIconSolidUser,
  AwIconSolidEdit,
  AwIconSolidPeople,
  AwIconSolidStar,
  AwIconSolidPicture,
  AwIconSolidTwitter,
};
