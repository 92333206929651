import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../genericComponents/Image';
import { H1Title } from '../genericComponents/Title';
import {
  stepProgress,
  setSelectedStaff,
  setSelectedService,
  setSelectedDateAndTime,
} from '../../redux/actions';
import ReviewRating from '../mainContainer/Reviews/ReviewRating';

function CompanyProfile(props) {
  const handleClickEvent = () => {
    window.location.reload();
  };
  return (
    <div className="company-details">
      {props.src && (
        <div
          className="img-con"
          data-testid={'companyLogo'}
          onClick={handleClickEvent}
        >
          {/* <a href={`${window.location.href}`}> */}
          <Image
            src={props.src}
            alt={props.alt}
            defaultImage=""
            sizeAttr={window.isMobile.matches ? '=s120' : '=s250'}
          />
          {/* </a> */}
        </div>
      )}
      <H1Title title={props.title} />
      {props.viewSettings.menu.indexOf('review') !== -1 && <ReviewRating />}
    </div>
  );
}

CompanyProfile.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  stepProgress: PropTypes.func,
  setSelectedStaff: PropTypes.func,
  setSelectedService: PropTypes.func,
  setSelectedDateAndTime: PropTypes.func,
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const mapStateToProps = (state) => {
  return {
    viewSettings: state.viewSettings,
  };
};

export default connect(mapStateToProps, {
  stepProgress,
  setSelectedStaff,
  setSelectedService,
  setSelectedDateAndTime,
})(CompanyProfile);
