import React from 'react';

export default function Sprite() {
  return (
    <svg width="0" height="0" style={{ position: 'absolute' }}>
      <symbol
        viewBox="-4 -4 16 15"
        id="g-checkbox-checked"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M6.702.223a.76.76 0 0 1 1.075 1.075L3.215 5.86a.76.76 0 0 1-1.075 0L.223 3.943a.76.76 0 1 1 1.075-1.075l1.38 1.38L6.701.222z"
        />
      </symbol>
      <symbol
        viewBox="-4 -4 23 23"
        id="g-search"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.744 13.657a.31.31 0 0 1 .086.229.419.419 0 0 1-.086.257l-.658.63a.356.356 0 0 1-.258.115.272.272 0 0 1-.23-.115l-3.492-3.464a.38.38 0 0 1-.086-.23v-.4a6.151 6.151 0 0 1-1.804 1.074 5.759 5.759 0 0 1-2.09.386 5.784 5.784 0 0 1-2.993-.802A6.017 6.017 0 0 1 .972 9.176 5.784 5.784 0 0 1 .17 6.183a5.78 5.78 0 0 1 .802-2.992A6.017 6.017 0 0 1 3.133 1.03 5.784 5.784 0 0 1 6.126.228c1.088 0 2.085.267 2.992.801a6.017 6.017 0 0 1 2.162 2.162c.534.907.801 1.904.801 2.992 0 .726-.129 1.422-.386 2.09a6.151 6.151 0 0 1-1.074 1.804h.4a.31.31 0 0 1 .23.086l3.493 3.494zm-8.618-2.892c.82 0 1.584-.206 2.29-.616a4.549 4.549 0 0 0 1.675-1.675c.41-.706.616-1.47.616-2.29 0-.822-.205-1.585-.616-2.291a4.549 4.549 0 0 0-1.675-1.675 4.481 4.481 0 0 0-2.29-.616c-.821 0-1.585.205-2.291.616A4.549 4.549 0 0 0 2.16 3.893a4.481 4.481 0 0 0-.616 2.29c0 .821.205 1.585.616 2.291.41.706.969 1.265 1.675 1.675.706.41 1.47.616 2.29.616z" />
      </symbol>
    </svg>
  );
}
