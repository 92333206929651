import axios from 'axios';
import { DateTime } from 'luxon';
import CommonFunctions from '../../../utils/commonFunctions';
import DateAndTimeUtils from '../DateAndTime/DateAndTimeUtils';
import {
  TOKEN,
  SUBDOMAIN,
  BASEURL,
  HEADERS,
  PAGE_DETAILS,
  APP_VERSION,
  BRAND_ID,
  MESSENGER_APP_ID,
} from '../../../utils/constants';

const reminderOnSubmit = (checked) => {
  let reminderConfirmation = [];
  if (checked) {
    reminderConfirmation = ['sms', 'email'];
  }
  return reminderConfirmation;
};

const getServiceList = (selectedService) => {
  const serviceList = [];
  let isVideoAvailableForService = false;
  if (selectedService) {
    selectedService.forEach((service) => {
      serviceList.push(service.id);
      if (service.location?.isVideoAvailable === true) {
        isVideoAvailableForService = true;
      }
    });
  }
  return {
    serviceList,
    isVideoAvailableForService,
  };
};

const getPriceOfService = (selectedService) => {
  let price = 0;
  selectedService.forEach((service) => {
    price += service.price;
  });
  return price;
};

const sourceOfBooking = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get('rwg_token')) {
    return 'google_reserve';
  }
  const UTMSource = params.get('source');
  if (UTMSource) {
    if (UTMSource === 'easyshare') {
      if (!CommonFunctions.isNullOrEmpty(SUBDOMAIN)) {
        if (PAGE_DETAILS.isStaffPage) {
          return 'Custom Staff Booking Page - EasyShare';
        }
        if (PAGE_DETAILS.isServicePage) {
          return 'Custom Service Booking Page - EasyShare';
        }
        if (PAGE_DETAILS.isClassPage) {
          return 'Custom Class Booking Page - EasyShare';
        }
        return 'Custom Booking Page - EasyShare';
      }
      if (PAGE_DETAILS.isStaffPage) {
        return 'Staff Booking Page - EasyShare';
      }
      if (PAGE_DETAILS.isServicePage) {
        return 'Service Booking Page - EasyShare';
      }
      if (PAGE_DETAILS.isClassPage) {
        return 'Class Booking Page - EasyShare';
      }
      return 'Booking Page - EasyShare';
    }
    if (UTMSource === 'qrcode') {
      return SUBDOMAIN
        ? 'Custom Booking Page - QR code'
        : 'Booking Page - QR code';
    }

    if (UTMSource === 'wordpress') {
      return SUBDOMAIN
        ? 'Custom Booking Page - Wordpress'
        : 'Booking Page - Wordpress';
    }

    if (UTMSource === 'freshdesk') {
      return SUBDOMAIN
        ? 'Custom Booking Page - Freshdesk'
        : 'Booking Page - Freshdesk';
    }

    if (UTMSource === 'facebook') {
      return SUBDOMAIN
        ? 'Custom Booking Page - FaceBook'
        : 'Booking Page - FaceBook';
    }

    if (UTMSource === 'instagram') {
      return SUBDOMAIN
        ? 'Custom Booking Page - Instagram'
        : 'Booking Page - Instagram';
    }
    if (UTMSource === 'intercom') {
      if (!CommonFunctions.isNullOrEmpty(SUBDOMAIN)) {
        if (PAGE_DETAILS.isStaffPage) {
          return 'Custom Staff Booking Page - Intercom';
        }
        if (PAGE_DETAILS.isServicePage) {
          return 'Custom Service Booking Page - Intercom';
        }
        if (PAGE_DETAILS.isClassPage) {
          return 'Custom Class Booking Page - Intercom';
        }
        return 'Custom Booking Page - Intercom';
      }
      if (PAGE_DETAILS.isStaffPage) {
        return 'Staff Booking Page - Intercom';
      }
      if (PAGE_DETAILS.isServicePage) {
        return 'Service Booking Page - Intercom';
      }
      if (PAGE_DETAILS.isClassPage) {
        return 'Class Booking Page - Intercom';
      }
      return 'Booking Page - Intercom';
    }
  }
  if (params.get('instant_experiences_enabled')) {
    return SUBDOMAIN
      ? 'Custom Booking Page - Instagram'
      : 'Booking Page - Instagram';
  }
  if (params.get('fbclid')) {
    return SUBDOMAIN
      ? 'Custom Booking Page - FaceBook'
      : 'Booking Page - FaceBook';
  }

  if (!CommonFunctions.isNullOrEmpty(SUBDOMAIN)) {
    if (PAGE_DETAILS.isStaffPage) {
      return 'Custom Staff Booking Page';
    }
    if (PAGE_DETAILS.isServicePage) {
      return 'Custom Service Booking Page';
    }
    if (PAGE_DETAILS.isClassPage) {
      return 'Custom Class Booking Page';
    }
    if (PAGE_DETAILS.isSessionPage) {
      return 'Custom Session Booking Page';
    }
    return 'Custom Booking Page';
  }

  if (PAGE_DETAILS.isStaffPage) {
    return 'Staff Booking Page';
  }
  if (PAGE_DETAILS.isServicePage) {
    return 'Service Booking Page';
  }
  if (PAGE_DETAILS.isClassPage) {
    return 'Class Booking Page';
  }
  if (PAGE_DETAILS.isSessionPage) {
    return 'Session Booking Page';
  }

  return 'Booking Page';
};

const getMetaData = (props, reminderTitle, checked) => {
  const metaData = {
    reminders: reminderTitle ? reminderOnSubmit(checked) : ['sms', 'email'],
    tc: ['terms&conditions', 'cancellation_policy'],
    consumerTimezone:
      props.companyDetails.timeZone === props.localTimeZone
        ? ''
        : props.localTimeZone,
  };
  let bookingId = '';
  if (props.selectedAppointment) {
    bookingId =
      props.isApptReschedule &&
      props.selectedAppointment.metaData &&
      props.selectedAppointment.metaData.bookingId
        ? props.selectedAppointment.metaData.bookingId
        : props.selectedAppointment.bookingId;
  }

  if (APP_VERSION === 'v1') {
    metaData.bookingId = bookingId;
  }
  if (
    !CommonFunctions.isNullOrEmpty(props.facebookPageId) &&
    !window.isFBOrInstagram
  ) {
    metaData.fbUserRef = props.fbCheckboxState ? props.userRef : '';
  }

  return metaData;
};

const constructAppointmentModel = (
  props,
  startTime,
  endTime,
  reminderTitle,
  checked,
  isPayment
) => {
  if (props?.customer && props.viewSettings && props.companyDetails) {
    const serviceAndVideo = getServiceList(props.selectedService);
    let label = 'No Label';
    if (isPayment) {
      label = APP_VERSION === 'v2' ? '' : 'Pending';
    }
    label = props.selectedAppointment?.label || label;
    return {
      id:
        props.isApptReschedule && props.selectedAppointment
          ? props.selectedAppointment.id
          : null,
      calendar: TOKEN,
      startDateTime: startTime,
      endDateTime: endTime,
      recurringId: '',
      rRule: '',
      leaseId: props.leaseObj ? props.leaseObj : '',
      provider: [
        props.viewSettings.bookingOption &&
        props.viewSettings.bookingOption.isSkipStaff &&
        !PAGE_DETAILS.isStaffPage &&
        !props.isApptReschedule
          ? `${props.selectedStaff}`
          : `${props.selectedStaff.key}`,
      ],
      consumer: [`${props.customer.key}`],
      service: serviceAndVideo.serviceList,
      title: props.selectedService[0].title,
      cost: getPriceOfService(props.selectedService),
      status: '',
      bookingId:
        props.isApptReschedule && props.selectedAppointment
          ? props.selectedAppointment.bookingId
          : '',
      type: 'APPOINTMENT',
      source: sourceOfBooking(),
      maxSeats: 0,
      label,
      timezone: props.companyDetails.timeZone,
      metaData: getMetaData(props, reminderTitle, checked),
      createdBy: `${props.customer.key}`,
      notes: props.appointmentComments,
      external: '',
      merchant: TOKEN,
      location: props.isApptReschedule
        ? props.selectedAppointment.location
        : {},
      isVideoEnabled: serviceAndVideo.isVideoAvailableForService,
      isSlotCheck: true,
      brand: BRAND_ID,
    };
  }
  return null;
};
const confirmOptIn = (resp, userRef, facebookPageId) => {
  try {
    if (
      !CommonFunctions.isNullOrEmpty(resp.id) &&
      !CommonFunctions.isNullOrEmpty(facebookPageId) &&
      !CommonFunctions.isNullOrEmpty(userRef) &&
      !window.isFBOrInstagram
    ) {
      window?.FB?.AppEvents?.logEvent(
        'MessengerCheckboxUserConfirmation',
        null,
        {
          app_id: MESSENGER_APP_ID,
          page_id: facebookPageId,
          ref: resp.id,
          user_ref: userRef,
        }
      );
    }
  } catch (error) {
    console.log('FB Opt In Error', error);
  }
};
const saveAppointment = async (
  props,
  appointment,
  setResponseMsg,
  setShowSnackbar,
  userRef,
  facebookPageId,
  isPayment
) => {
  let response;
  try {
    response = await axios.post(
      `${BASEURL}/bookingpage/events/appointment`,
      appointment,
      HEADERS
    );

    if (response.status === 200) {
      props.setAppointment(response.data.data);
      props.setIsPaymentDone(isPayment ? 'Skipped' : '');
      props.stepProgress('confirmation');

      if (response && response.data && response.data.data.events[0]) {
        const bookingId =
          APP_VERSION === 'v2'
            ? response.data.data.events[0].bookingId
            : response.data.data.events[0].metaData.bookingId;
        props.setBookingId(bookingId);
        confirmOptIn(response.data.data.events[0], userRef, facebookPageId);
      }
    }
  } catch (error) {
    console.log(error);
    if (isPayment && error.response) {
      props.pageLoaderHandler({ loader: true, errorMessage: true });
    } else if (error.response) {
      setResponseMsg({
        msg: error.response.data.msg,
        error: error.response.data.error,
      });
      setShowSnackbar(true);
    }
  }
  return '';
};

const saveClass = async (props, setResponseMsg, setShowSnackbar, isPayment) => {
  if (
    props &&
    props.viewSettings &&
    props.customer &&
    DateAndTimeUtils.computeAdvancedLeadTimeDate(
      props.schedulingPolicy.leadTime,
      props.selectedDateAndTimeForClass
    )
  ) {
    const timeZone =
      props.viewSettings.viewOptions &&
      props.viewSettings.viewOptions.isLocalTime &&
      !CommonFunctions.isNullOrEmpty(props.localTimeZone)
        ? props.localTimeZone
        : props.companyDetails.timeZone;

    const classSession = {
      customerKey: props.customer.key,
      eventKey: props.selectedSessionKey,
      custLocalTimeZone: timeZone,
      isPaymentEnabled: false,
      source: sourceOfBooking(),
      haveEmailReminderEnabled: props.customer.haveEmailReminderEnabled,
      haveTextReminderEnabled: props.customer.haveTextReminderEnabled,
    };

    let response;
    try {
      response = await axios.post(
        `${BASEURL}/newbookingpage/addAttendee`,
        classSession,
        HEADERS
      );

      if (response.status === 200) {
        if (response.data && response.data.error) {
          if (isPayment) {
            props.pageLoaderHandler({
              loader: true,
              errorMessage: true,
              responseErrMsg: response.data.error,
            });
          } else {
            setResponseMsg({
              msg: response.data.msg,
              error: response.data.error,
            });
            setShowSnackbar(true);
          }
        } else {
          props.classStepProgress('confirmation');

          if (response && response.data && response.data.data) {
            props.setClassBookingId(
              CommonFunctions.getClassBookingId(response.data.data)
            );
          }
          if (isPayment) {
            props.pageLoaderHandler({ loader: false, errorMessage: false });
            props.setPaymentStatus('Skipped');
          }
        }
      }
    } catch (error) {
      if (isPayment) {
        props.pageLoaderHandler({
          loader: true,
          errorMessage: true,
        });
      } else if (error.response) {
        setResponseMsg({
          msg: error.response.data.msg,
          error: error.response.data.error,
        });
        setShowSnackbar(true);
      }
    }
  } else {
    setResponseMsg({
      msg: 'lead_time_experied',
    });
    setShowSnackbar(true);
  }
};

const rescheduleSession = async (props, setResponseMsg, setShowSnackbar) => {
  if (props && props.viewSettings && props.customer) {
    const timeZone =
      props.viewSettings.viewOptions.isLocalTime &&
      !CommonFunctions.isNullOrEmpty(props.localTimeZone)
        ? props.localTimeZone
        : props.companyDetails.timeZone;

    const data = {
      customerKey: props.customer.key,
      oldEventKey: props.previousSessionKey,
      eventKey: props.selectedSessionKey,
      custLocalTimeZone: timeZone,
      isPaymentEnabled: false,
      source: sourceOfBooking(),
      haveEmailReminderEnabled: props.customer.haveEmailReminderEnabled,
      haveTextReminderEnabled: props.customer.haveTextReminderEnabled,
    };

    let response;
    try {
      response = await axios.put(
        `${BASEURL}/newbookingpage/updateClassSession`,
        data,
        HEADERS
      );

      if (response.status === 200) {
        if (response.data && response.data.error) {
          setResponseMsg({
            msg: response.data.msg,
            error: response.data.error,
          });
          setShowSnackbar(true);
        } else {
          props.setIsPaymentDone('');
          props.classStepProgress('confirmation');
          if (response && response.data && response.data.data) {
            props.setClassBookingId(
              CommonFunctions.getClassBookingId(response.data.data)
            );
          }
        }
      }
    } catch (error) {
      if (error.response) {
        setResponseMsg({
          msg: error.response.data.msg,
          error: error.response.data.error,
        });
        setShowSnackbar(true);
      }
    }
  }
};

const generateReminderTitle = (
  reminderSettings,
  customer,
  companyDetails,
  activeTab
) => {
  let reminderTitle = '';
  if (reminderSettings && reminderSettings.reminder) {
    let isSMSReminderForClass;
    if (activeTab === 'Book Class') {
      isSMSReminderForClass =
        ['usa', 'can'].indexOf(companyDetails.addressDetails.country) !== -1;
    } else {
      isSMSReminderForClass = true;
    }
    if (customer) {
      if (
        isSMSReminderForClass &&
        reminderSettings.reminder.text &&
        reminderSettings.reminder.text.length > 0 &&
        reminderSettings.reminder.email &&
        reminderSettings.reminder.email.length > 0 &&
        customer.LoginId &&
        customer.MobileNo
      ) {
        reminderTitle = window.lang.email_and_text;
      } else if (
        isSMSReminderForClass &&
        reminderSettings.reminder.text &&
        reminderSettings.reminder.text.length > 0 &&
        customer.MobileNo
      ) {
        reminderTitle = window.lang.send_text;
      } else if (
        reminderSettings.reminder.email &&
        reminderSettings.reminder.email.length > 0 &&
        customer.LoginId
      ) {
        reminderTitle = window.lang.send_email;
      }
    }
  }
  return reminderTitle;
};

const getStartTime = (
  timeZone,
  activeTab,
  selectedDateAndTime,
  selectedDateAndTimeForClass
) => {
  let startTime;
  if (activeTab === 'Book Appointment') {
    startTime = DateAndTimeUtils.getIso(
      DateTime.fromMillis(parseInt(selectedDateAndTime, 10))
        .setZone(timeZone)
        .toISO()
    );
  } else {
    startTime = DateAndTimeUtils.getIso(
      DateTime.fromMillis(parseInt(selectedDateAndTimeForClass, 10))
        .setZone(timeZone)
        .toISO()
    );
  }
  return startTime;
};

const getEndTime = (
  timeZone,
  activeTab,
  selectedService,
  selectedDateAndTime
) => {
  let duration = 0;
  const radixParam = 10;
  if (activeTab === 'Book Appointment') {
    selectedService.forEach((service) => {
      duration += service.duration;
    });
  }
  duration = parseInt(duration, radixParam) * 60 * 1000;

  let end;
  let endTime;
  if (activeTab === 'Book Appointment') {
    end = parseInt(selectedDateAndTime, radixParam) + duration;
    endTime =
      duration > 0
        ? DateAndTimeUtils.getIso(
            DateTime.fromMillis(end).setZone(timeZone).toISO()
          )
        : 0;
  }
  return endTime;
};

const getAppointmentObject = (companyTimeZone, checked, props) => {
  const startTime = getStartTime(
    companyTimeZone,
    props.activeTab,
    props.selectedDateAndTime,
    props.selectedDateAndTimeForClass
  );
  const endTime = getEndTime(
    companyTimeZone,
    props.activeTab,
    props.selectedService,
    props.selectedDateAndTime
  );

  const reminderTitle = generateReminderTitle(
    props.reminderSettings,
    props.customer,
    props.companyDetails,
    props.activeTab
  );

  return constructAppointmentModel(
    props,
    startTime,
    endTime,
    reminderTitle,
    checked,
    true
  );
};

const PreConfirmationUtils = {
  constructAppointmentModel,
  saveAppointment,
  saveClass,
  rescheduleSession,
  generateReminderTitle,
  getStartTime,
  getEndTime,
  sourceOfBooking,
  getPriceOfService,
  confirmOptIn,
  getAppointmentObject,
};

export default PreConfirmationUtils;
