const defaultCountriesData = [
  ['Afghanistan', 'af', 'afg', '93'],
  ['Albania', 'al', 'alb', '355'],
  ['Algeria', 'dz', 'dza', '213'],
  ['American Samoa', 'as', 'asm', '1', 5, ['684']],
  ['Andorra', 'ad', 'and', '376'],
  ['Angola', 'ao', 'ago', '244'],
  ['Anguilla', 'ai', 'aia', '1', 6, ['264']],
  ['Antigua and Barbuda', 'ag', 'atg', '1', 7, ['268']],
  ['Argentina', 'ar', 'arg', '54'],
  ['Armenia', 'am', 'arm', '374'],
  ['Aruba', 'aw', 'abw', '297'],
  ['Ascension Island', 'ac', 'asc', '247'],
  ['Australia', 'au', 'aus', '61', 0],
  ['Austria', 'at', 'aut', '43'],
  ['Azerbaijan', 'az', 'aze', '994'],
  ['Bahamas', 'bs', 'bhs', '1', 8, ['242']],
  ['Bahrain', 'bh', 'bhr', '973'],
  ['Bangladesh', 'bd', 'bgd', '880'],
  ['Barbados', 'bb', 'brb', '1', 9, ['246']],
  ['Belarus', 'by', 'blr', '375'],
  ['Belgium', 'be', 'bel', '32'],
  ['Belize', 'bz', 'blz', '501'],
  ['Benin', 'bj', 'ben', '229'],
  ['Bermuda', 'bm', 'bmu', '1', 10, ['441']],
  ['Bhutan', 'bt', 'btn', '975'],
  ['Bolivia', 'bo', 'bol', '591'],
  ['Bosnia and Herzegovina', 'ba', 'bih', '387'],
  ['Botswana', 'bw', 'bwa', '267'],
  ['Brazil', 'br', 'bra', '55'],
  ['British Indian Ocean Territory', 'io', 'iot', '246'],
  ['British Virgin Islands', 'vg', 'vgb', '1', 11, ['284']],
  ['Brunei', 'bn', 'brn', '673'],
  ['Bulgaria', 'bg', 'bgr', '359'],
  ['Burkina Faso', 'bf', 'bfa', '226'],
  ['Burundi', 'bi', 'bdi', '257'],
  ['Cambodia', 'kh', 'khm', '855'],
  ['Cameroon', 'cm', 'cmr', '237'],
  [
    'Canada',
    'ca',
    'can',
    '1',
    1,
    [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
  ],
  ['Cape Verde', 'cv', 'cpv', '238'],
  ['Caribbean Netherlands', 'bq', 'bes', '599', 1, ['3', '4', '7']],
  ['Cayman Islands', 'ky', 'cym', '1', 12, ['345']],
  ['Central African Republic', 'cf', 'caf', '236'],
  ['Chad', 'td', 'tcd', '235'],
  ['Chile', 'cl', 'chl', '56'],
  ['China', 'cn', 'chn', '86'],
  ['Christmas Island', 'cx', 'cxr', '61', 2, ['89164']],
  ['Cocos Islands', 'cc', 'cck', '61', 1, ['89162']],
  ['Colombia', 'co', 'col', '57'],
  ['Comoros', 'km', 'com', '269'],
  ['Congo (DRC)', 'cd', 'cod', '243'],
  ['Congo (Republic)', 'cg', 'cog', '242'],
  ['Cook Islands', 'ck', 'cok', '682'],
  ['Costa Rica', 'cr', 'cri', '506'],
  ['Côte d’Ivoire', 'ci', 'civ', '225'],
  ['Croatia', 'hr', 'hrv', '385'],
  ['Cuba', 'cu', 'cub', '53'],
  ['Curaçao', 'cw', 'cuw', '599', 0],
  ['Cyprus', 'cy', 'cyp', '357'],
  ['Czech Republic', 'cz', 'cze', '420'],
  ['Denmark', 'dk', 'dnk', '45'],
  ['Djibouti', 'dj', 'dji', '253'],
  ['Dominica', 'dm', 'dma', '1', 13, ['767']],
  ['Dominican Republic', 'do', 'dom', '1', 2, ['809', '829', '849']],
  ['Ecuador', 'ec', 'ecu', '593'],
  ['Egypt', 'eg', 'egy', '20'],
  ['El Salvador', 'sv', 'slv', '503'],
  ['Equatorial Guinea', 'gq', 'gnq', '240'],
  ['Eritrea', 'er', 'eri', '291'],
  ['Estonia', 'ee', 'est', '372'],
  ['Eswatini', 'sz', 'swz', '268'],
  ['Ethiopia', 'et', 'eth', '251'],
  ['Falkland Islands', 'fk', 'flk', '500'],
  ['Faroe Islands', 'fo', 'fro', '298'],
  ['Fiji', 'fj', 'fji', '679'],
  ['Finland', 'fi', 'fin', '358', 0],
  ['France', 'fr', 'fra', '33'],
  ['French Guiana', 'gf', 'guf', '594'],
  ['French Polynesia', 'pf', 'pyf', '689'],
  ['Gabon', 'ga', 'gab', '241'],
  ['Gambia', 'gm', 'gmb', '220'],
  ['Georgia', 'ge', 'geo', '995'],
  ['Germany', 'de', 'deu', '49'],
  ['Ghana', 'gh', 'gha', '233'],
  ['Gibraltar', 'gi', 'gib', '350'],
  ['Greece', 'gr', 'grc', '30'],
  ['Greenland', 'gl', 'grl', '299'],
  ['Grenada', 'gd', 'grd', '1', 14, ['473']],
  ['Guadeloupe', 'gp', 'glp', '590', 0],
  ['Guam', 'gu', 'gum', '1', 15, ['671']],
  ['Guatemala', 'gt', 'gtm', '502'],
  ['Guernsey', 'gg', 'ggy', '44', 1, ['1481', '7781', '7839', '7911']],
  ['Guinea', 'gn', 'gin', '224'],
  ['Guinea-Bissau', 'gw', 'gnb', '245'],
  ['Guyana', 'gy', 'guy', '592'],
  ['Haiti', 'ht', 'hti', '509'],
  ['Honduras', 'hn', 'hnd', '504'],
  ['Hong Kong', 'hk', 'hkg', '852'],
  ['Hungary', 'hu', 'hun', '36'],
  ['Iceland', 'is', 'isl', '354'],
  ['India', 'in', 'ind', '91'],
  ['Indonesia', 'id', 'idn', '62'],
  ['Iran', 'ir', 'irn', '98'],
  ['Iraq', 'iq', 'irq', '964'],
  ['Ireland', 'ie', 'irl', '353'],
  [
    'Isle of Man',
    'im',
    'imn',
    '44',
    2,
    ['1624', '74576', '7524', '7924', '7624'],
  ],
  ['Israel', 'il', 'isr', '972'],
  ['Italy', 'it', 'ita', '39', 0],
  ['Jamaica', 'jm', 'jam', '1', 4, ['876', '658']],
  ['Japan', 'jp', 'jpn', '81'],
  [
    'Jersey',
    'je',
    'jey',
    '44',
    3,
    ['1534', '7509', '7700', '7797', '7829', '7937'],
  ],
  ['Jordan', 'jo', 'jor', '962'],
  ['Kazakhstan', 'kz', 'kaz', '7', 1, ['33', '7']],
  ['Kenya', 'ke', 'ken', '254'],
  ['Kiribati', 'ki', 'kir', '686'],
  ['Kosovo', 'xk', 'xkx', '383'],
  ['Kuwait', 'kw', 'kwt', '965'],
  ['Kyrgyzstan', 'kg', 'kgz', '996'],
  ['Laos', 'la', 'lao', '856'],
  ['Latvia', 'lv', 'lva', '371'],
  ['Lebanon', 'lb', 'lbn', '961'],
  ['Lesotho', 'ls', 'lso', '266'],
  ['Liberia', 'lr', 'lbr', '231'],
  ['Libya', 'ly', 'lby', '218'],
  ['Liechtenstein', 'li', 'lie', '423'],
  ['Lithuania', 'lt', 'ltu', '370'],
  ['Luxembourg', 'lu', 'lux', '352'],
  ['Macau', 'mo', 'mac', '853'],
  ['Macedonia', 'mk', 'mkd', '389'],
  ['Madagascar', 'mg', 'mdg', '261'],
  ['Malawi', 'mw', 'mwi', '265'],
  ['Malaysia', 'my', 'mys', '60'],
  ['Maldives', 'mv', 'mdv', '960'],
  ['Mali', 'ml', 'mli', '223'],
  ['Malta', 'mt', 'mlt', '356'],
  ['Marshall Islands', 'mh', 'mhl', '692'],
  ['Martinique', 'mq', 'mtq', '596'],
  ['Mauritania', 'mr', 'mrt', '222'],
  ['Mauritius', 'mu', 'mus', '230'],
  ['Mayotte', 'yt', 'myt', '262', 1, ['269', '639']],
  ['Mexico', 'mx', 'mex', '52'],
  ['Micronesia', 'fm', 'fsm', '691'],
  ['Moldova', 'md', 'mda', '373'],
  ['Monaco', 'mc', 'mco', '377'],
  ['Mongolia', 'mn', 'mng', '976'],
  ['Montenegro', 'me', 'mne', '382'],
  ['Montserrat', 'ms', 'msr', '1', 16, ['664']],
  ['Morocco', 'ma', 'mar', '212', 0],
  ['Mozambique', 'mz', 'moz', '258'],
  ['Myanmar', 'mm', 'mmr', '95'],
  ['Namibia', 'na', 'nam', '264'],
  ['Nauru', 'nr', 'nru', '674'],
  ['Nepal', 'np', 'npl', '977'],
  ['Netherlands', 'nl', 'nld', '31'],
  ['New Caledonia', 'nc', 'ncl', '687'],
  ['New Zealand', 'nz', 'nzl', '64'],
  ['Nicaragua', 'ni', 'nic', '505'],
  ['Niger', 'ne', 'ner', '227'],
  ['Nigeria', 'ng', 'nga', '234'],
  ['Niue', 'nu', 'niu', '683'],
  ['Norfolk Island', 'nf', 'nfk', '672'],
  ['North Korea', 'kp', 'prk', '850'],
  ['Northern Mariana Islands', 'mp', 'mnp', '1', 17, ['670']],
  ['Norway', 'no', 'nor', '47', 0],
  ['Oman', 'om', 'omn', '968'],
  ['Pakistan', 'pk', 'pak', '92'],
  ['Palau', 'pw', 'plw', '680'],
  ['Palestine', 'ps', 'pse', '970'],
  ['Panama', 'pa', 'pan', '507'],
  ['Papua New Guinea', 'pg', 'png', '675'],
  ['Paraguay', 'py', 'pry', '595'],
  ['Peru', 'pe', 'per', '51'],
  ['Philippines', 'ph', 'phl', '63'],
  ['Poland', 'pl', 'pol', '48'],
  ['Portugal', 'pt', 'prt', '351'],
  ['Puerto Rico', 'pr', 'pri', '1', 3, ['787', '939']],
  ['Qatar', 'qa', 'qat', '974'],
  ['Réunion', 're', 'reu', '262', 0],
  ['Romania', 'ro', 'rou', '40'],
  ['Russia', 'ru', 'rus', '7', 0],
  ['Rwanda', 'rw', 'rwa', '250'],
  ['Saint Barthélemy', 'bl', 'blm', '590', 1],
  ['Saint Helena', 'sh', 'shn', '290'],
  ['Saint Kitts and Nevis', 'kn', 'kna', '1', 18, ['869']],
  ['Saint Lucia', 'lc', 'lca', '1', 19, ['758']],
  ['Saint Martin', 'mf', 'maf', '590', 2],
  ['Saint Pierre and Miquelon', 'pm', 'spm', '508'],
  ['Saint Vincent and the Grenadines', 'vc', 'vct', '1', 20, ['784']],
  ['Samoa', 'ws', 'wsm', '685'],
  ['San Marino', 'sm', 'smr', '378'],
  ['São Tomé and Príncipe', 'st', 'stp', '239'],
  ['Saudi Arabia', 'sa', 'sau', '966'],
  ['Senegal', 'sn', 'sen', '221'],
  ['Serbia', 'rs', 'srb', '381'],
  ['Seychelles', 'sc', 'syc', '248'],
  ['Sierra Leone', 'sl', 'sle', '232'],
  ['Singapore', 'sg', 'sgp', '65'],
  ['Sint Maarten', 'sx', 'sxm', '1', 21, ['721']],
  ['Slovakia', 'sk', 'svk', '421'],
  ['Slovenia', 'si', 'svn', '386'],
  ['Solomon Islands', 'sb', 'slb', '677'],
  ['Somalia', 'so', 'som', '252'],
  ['South Africa', 'za', 'zaf', '27'],
  ['South Korea', 'kr', 'kor', '82'],
  ['South Sudan', 'ss', 'ssd', '211'],
  ['Spain', 'es', 'esp', '34'],
  ['Sri Lanka', 'lk', 'lka', '94'],
  ['Sudan', 'sd', 'sdn', '249'],
  ['Suriname', 'sr', 'sur', '597'],
  ['Svalbard and Jan Mayen', 'sj', 'sjm', '47', 1, ['79']],
  ['Sweden', 'se', 'swe', '46'],
  ['Switzerland', 'ch', 'che', '41'],
  ['Syria', 'sy', 'syr', '963'],
  ['Taiwan', 'tw', 'twn', '886'],
  ['Tajikistan', 'tj', 'tjk', '992'],
  ['Tanzania', 'tz', 'tza', '255'],
  ['Thailand', 'th', 'tha', '66'],
  ['Timor-Leste', 'tl', 'tls', '670'],
  ['Togo', 'tg', 'tgo', '228'],
  ['Tokelau', 'tk', 'tkl', '690'],
  ['Tonga', 'to', 'ton', '676'],
  ['Trinidad and Tobago', 'tt', 'tto', '1', 22, ['868']],
  ['Tunisia', 'tn', 'tun', '216'],
  ['Turkey', 'tr', 'tur', '90'],
  ['Turkmenistan', 'tm', 'tkm', '993'],
  ['Turks and Caicos Islands', 'tc', 'tca', '1', 23, ['649']],
  ['Tuvalu', 'tv', 'tuv', '688'],
  ['U.S. Virgin Islands', 'vi', 'vir', '1', 24, ['340']],
  ['Uganda', 'ug', 'uga', '256'],
  ['Ukraine', 'ua', 'ukr', '380'],
  ['United Arab Emirates', 'ae', 'are', '971'],
  ['United Kingdom', 'gb', 'gbr', '44', 0],
  ['United States', 'us', 'usa', '1', 0],
  ['Uruguay', 'uy', 'ury', '598'],
  ['Uzbekistan', 'uz', 'uzb', '998'],
  ['Vanuatu', 'vu', 'vut', '678'],
  ['Vatican City', 'va', 'va', '39', 1, ['06698']],
  ['Venezuela', 've', 'ven', '58'],
  ['Vietnam', 'vn', 'vnm', '84'],
  ['Wallis and Futuna', 'wf', 'wlf', '681'],
  ['Western Sahara', 'eh', 'esh', '212', 1, ['5288', '5289']],
  ['Yemen', 'ye', 'yem', '967'],
  ['Zambia', 'zm', 'zmb', '260'],
  ['Zimbabwe', 'zw', 'zwe', '263'],
  ['Åland Islands', 'ax', 'ala', '358', 1, ['18']],
];

let countries;

function formatCountriesData(countriesData) {
  return countriesData.map((country) => ({
    name: country[0],
    iso2: country[1],
    iso3: country[2],
    dialCode: country[3],
    priority: country[4] || 0,
    areaCodes: country[5] || null,
  }));
}

function initialize() {
  countries = formatCountriesData(defaultCountriesData);
}

function getCountries() {
  if (!countries) {
    initialize();
  }

  return countries;
}

const getCountryFromCode = (countryCode = '') => {
  const countryList = getCountries();
  let iso;
  let country = countryCode;
  if (country) {
    iso = country.length === 2 ? 'iso2' : 'iso3';
  } else {
    country = 'us';
    iso = 'iso2';
  }
  if (countryCode && countryCode.length > 3) {
    for (let i = 0; i < countryList.length; i += 1) {
      if (countryList[i].name.toLowerCase() === country.toLowerCase()) {
        return countryList[i];
      }
    }
  } else {
    for (let i = 0; i < countryList.length; i += 1) {
      if (countryList[i][iso] === country.toLowerCase()) {
        return countryList[i];
      }
    }
  }
  return null;
};

const getCountry = (countryCode) => {
  let country = getCountryFromCode(countryCode);
  if (country === null) country = getCountryFromCode('us');
  return country;
};

const getCountryForCountryWithDialCode = (number, dialCode) => {
  const countryList = getCountries();
  const code = dialCode.replace('+', '');
  const countryWithSameCountryCode = [];
  for (let i = 0; i < countryList.length; i += 1) {
    if (countryList[i].dialCode === code) {
      countryWithSameCountryCode.push(countryList[i]);
    }
  }
  if (countryWithSameCountryCode.length > 1) {
    const stateCode = number.substr(0, 3);
    let countryWithHighProirity;
    // for(let countryWithSameCountryCode)
    for (const countryValue of countryWithSameCountryCode) {
      if (
        countryValue.areaCodes !== null &&
        countryValue.areaCodes.indexOf(stateCode) !== -1
      ) {
        return countryValue;
      }
      if (countryValue.priority === 0) {
        countryWithHighProirity = countryValue;
      }
    }
    return countryWithHighProirity;
  }
  return countryWithSameCountryCode[0];
};

const getCountryFromDialCode = (dialCode) => {
  const countryList = getCountries();
  const code = dialCode.replace('+', '');
  for (let i = 0; i < countryList.length; i += 1) {
    if (countryList[i].dialCode === code) {
      return countryList[i];
    }
  }
  return null;
};

const isCompanyOrCustomerUnderGDPR = (country) => {
  const gdprCountries = [
    'at',
    'be',
    'bg',
    'hr',
    'cy',
    'cz',
    'dk',
    'ee',
    'fi',
    'fr',
    'de',
    'gr',
    'hu',
    'ie',
    'it',
    'lv',
    'lt',
    'lu',
    'mt',
    'nl',
    'pl',
    'pt',
    'ro',
    'sk',
    'si',
    'es',
    'se',
    'gb',
    'im',
    'no',
    'is',
    'us',
  ];
  if (country && country.length === 2) {
    if (gdprCountries.indexOf(country) !== -1) {
      return true;
    }
  } else {
    const countryCodeFromCountry = getCountryFromCode(country);
    if (countryCodeFromCountry !== null) {
      if (gdprCountries.indexOf(countryCodeFromCountry.iso2) !== -1) {
        return true;
      }
    }
  }
  return false;
};

const AllCountries = {
  getCountry,
  getCountryFromCode,
  getCountries,
  getCountryForCountryWithDialCode,
  getCountryFromDialCode,
  isCompanyOrCustomerUnderGDPR,
};

export default AllCountries;
