import axios from 'axios';
import ISO6391 from 'iso-639-1';
import jstz from 'jstimezonedetect';
import { DateTime } from 'luxon';

import { LANGUAGE_LIST, BASEURL, HEADERS } from './constants';
import CommonFunctions from './commonFunctions';

const fetchLanguageFile = async (language, setFetchLanguage) => {
  try {
    let preferredLanguage = language;
    if (CommonFunctions.isNullOrEmpty(preferredLanguage)) {
      preferredLanguage = 'en';
    }
    const url = `${BASEURL}/bookingpage/fetchLanguage?language=${preferredLanguage}`;
    const response = await axios.get(url, HEADERS);
    if (response.status === 200 && response.data.data.language !== undefined) {
      window.lang = response.data.data.language;
      setFetchLanguage(true);
    } else {
      fetchLanguageFile('en');
    }
    return response;
  } catch (err) {
    return err.response;
  }
};

const fetchLanguage = (viewSettings, setFetchLanguage) => {
  let preferredLanguage =
    viewSettings &&
    viewSettings.customization.preferredLanguage &&
    viewSettings.customization.preferredLanguage !== 'null'
      ? viewSettings.customization.preferredLanguage.toLowerCase()
      : 'english';
  if (preferredLanguage === 'croatia') {
    preferredLanguage = 'croatian';
  }
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    const language = params.get('lang');
    if (language) {
      preferredLanguage =
        language && LANGUAGE_LIST.indexOf(language.toLowerCase()) !== -1
          ? language.trim()
          : preferredLanguage;
    }
  }
  if (preferredLanguage.length !== 2) {
    preferredLanguage = ISO6391.getCode(preferredLanguage);
  }
  fetchLanguageFile(preferredLanguage, setFetchLanguage);
};

const getLocalTimeZone = () => {
  let localTimeZoneID;
  try {
    const tz = jstz.determine();
    localTimeZoneID = tz.name();
  } catch (e) {
    localTimeZoneID = DateTime.local().zoneName;
    if (CommonFunctions.isNullOrEmpty(localTimeZoneID)) {
      localTimeZoneID = window.companyJson.timeZone;
    }
  }
  if (localTimeZoneID === 'Asia/Kolkata') {
    localTimeZoneID = 'Asia/Calcutta';
  }
  if (localTimeZoneID === 'UTC') {
    localTimeZoneID = 'Etc/GMT';
  }

  const etcGmtRegex = /^Etc\/GMT[+-]\d+$/;
  if (etcGmtRegex.test(localTimeZoneID)) {
    try {
      localTimeZoneID = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!localTimeZoneID) {
        localTimeZoneID = window.companyJson.timeZone;
      }
    } catch (e) {
      localTimeZoneID = window.companyJson.timeZone;
    }
  }
  return localTimeZoneID;
};

const AppUtils = {
  fetchLanguage,
  getLocalTimeZone,
  fetchLanguageFile,
};

export default AppUtils;
