import UAParser from 'ua-parser-js';

const isNullOrEmpty = (string) => {
  try {
    if (string) {
      let str = string;
      if (typeof str !== 'undefined' && typeof str === 'string')
        str = str.trim().toLowerCase();
      if (typeof str === 'object' && Object.keys(JSON.parse(str)).length === 0)
        str = '';
      if (str !== '' && str !== 'null' && str !== 'undefined') return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};
export const TOKEN =
  window.token === '{token}'
    ? window.location.pathname.split('/').length > 1 &&
      window.location.pathname.split('/')[2]
    : window.token;

export const SUBDOMAIN =
  window.customDomain !== '@@customDomain@@' &&
  !isNullOrEmpty(window.customDomain)
    ? window.customDomain
    : '';

if (
  window.location.hostname === 'localhost' &&
  window.location.port === '3000'
) {
  window.pageDetails = {
    isServicePage: false,
    serviceKey: '',
    isStaffPage: false,
    staffKey: '',
    isClassPage: false,
    classKey: '',
    isReschedulePage: false,
    appointmentKey: '',
    writeReview: false,
    isSessionPage: false,
    sessionKey: '',
    isCategoryPage: false,
    categoryKey: '',
  };
}

export const STAFFKEY =
  window.pageDetails.staffKey !== '@@staffKey@@' &&
  !isNullOrEmpty(window.pageDetails.staffKey)
    ? window.pageDetails.staffKey
    : '';

export const UK_THERAPY_KEY = 'ec7ec5b6-7de1-4155-9206-703dfa6b9a3c';
export const UK_THERAPY_GDPR =
  'When registering with UK Therapy Guide, you may receive appointment-specific communication. This includes booking and cancelation confirmations, payment receipts and appointment reminders  & discount code via email or SMS';

const baseURL = () => {
  if (
    window.APP_MODE === '@@APP_MODE@@' ||
    window.APP_MODE === 'LOCALHOST' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('ngrok') ||
    window.APP_MODE === 'STAGING'
  ) {
    return 'https://api.setmore.info/api/internal/v1';
  }
  if (window.APP_MODE === 'STAGING-SETMORE') {
    return 'https://testing.setmore.com/api/internal/v1';
  }
  return 'https://api.setmore.com/api/internal/v1';
};

const seBaseUrl = () => {
  if (
    window.APP_MODE === '@@APP_MODE@@' ||
    window.APP_MODE === 'LOCALHOST' ||
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('ngrok') ||
    window.APP_MODE === 'STAGING' ||
    window.APP_MODE === 'STAGING-SETMORE'
  ) {
    return 'https://dev.setmore.info/api/v1';
  }
  return 'https://schedule.setmore.com/api/v1';
};

export const BASEURL = baseURL();

export const SE_BASE_URL = seBaseUrl();

export const APPT_KEY = window.pageDetails.appointmentKey;

export const MAP_KEY = window.mapKey;

export const isFirefox = typeof InstallTrigger !== 'undefined';

export const DATE_MONTH_YEAR_FORMAT = isFirefox ? 'd/MMM/yyyy' : 'd-MMM-yyyy';

export const MONTH_YEAR_FORMAT = isFirefox ? 'MMM/yyyy' : 'MMM-yyyy';

export const APP_VERSION =
  window.companyJson && window.companyJson.version
    ? window.companyJson.version
    : 'v1';

export const IS_SE_SLOT =
  window.companyJson && window.companyJson.isSESlots
    ? window.companyJson.isSESlots
    : false;

export const HEADERS = {
  headers: {
    authorization: `Bearer BP-${TOKEN}`,
    'Content-Type': 'application/json',
    'x-user-mode': APP_VERSION,
  },
};

export const SE_HEADERS = {
  headers: {
    authorization: `BP-${TOKEN}`,
    'Content-Type': 'application/json',
  },
};

export const PAGE_DETAILS = window.pageDetails;
export const BRAND_ID = window.brandId ? window.brandId : '';

export const SE_HEADERS_NEW = { merchantid: TOKEN, brandId: BRAND_ID };

export const PAYMENT_ERROR_MESSAGES = [
  'Slot is not available',
  'Sorry! this slot is booked by another user, please select a different slot',
  'Lease already expires, Please try again',
  'customer cannot be empty or inactive',
  'currently staff doesnt take this service',
  'customer is already in this session.',
  'Lease Already expired',
  'payment failed',
  'This session is not available now',
  'The class not available now',
  'The class session is not available now',
];

export const PAYMENT_SLOT_ERROR_MESSAGES = [
  'Slot is not available',
  'Sorry! this slot is booked by another user, please select a different slot',
  'Lease already expires, Please try again',
];

export const LANGUAGE_LIST = [
  'arabic',
  'albanian',
  'bulgarian',
  'chinese',
  'croatia',
  'croatian',
  'czech',
  'english',
  'estonian',
  'french',
  'japanese',
  'serbian',
  'slovenian',
  'spanish',
  'swedish',
  'german',
  'greek',
  'dutch',
  'danish',
  'latin',
  'italian',
  'icelandic',
  'korean',
  'lithuanian',
  'latvian',
  'hebrew',
  'hungarian',
  'portuguese',
  'polish',
  'romanian',
  'finnish',
  'russian',
  'turkish',
  'norwegian',
  'ukrainian',
  'ar',
  'bg',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'et',
  'fi',
  'fr',
  'he',
  'hr',
  'hu',
  'is',
  'it',
  'ja',
  'ko',
  'la',
  'lt',
  'lv',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'ru',
  'sl',
  'sq',
  'sr',
  'sv',
  'tr',
  'uk',
  'zh',
];

export const MESSENGER_APP_ID = window.messengerAppId;
export const INCH_BRAND_ID = 'c5fd52b0-71e6-489f-b044-52390210a437';
export const IS_PWA_ENABLED = window.self === window.top && window.isPWAEnabled;
export const IS_STANDALONE_LOGIN_ALLOWED = window.isStandaloneLoginAllowed;

const parser = new UAParser();
const result = parser.getResult();
export const BROWSER_NAME = result?.browser?.name
  ? result.browser.name.toLowerCase()
  : '';

export const PWACONFIG = window.pwa;

const isConfirmationPopup = () => {
  if (window.pwa || IS_PWA_ENABLED) {
    if (
      window.pwa.appDetails.popup === 'CONFIRMATION' ||
      window.pwa.appDetails.popup === 'BOTH' ||
      window.isPWARedirect
    ) {
      return true;
    }
  }
  return false;
};

const isHomePWAPopup = () => {
  if (window.pwa || IS_PWA_ENABLED) {
    if (
      window.pwa.appDetails.popup === 'HOME' ||
      window.pwa.appDetails.popup === 'BOTH' ||
      window.isPWARedirect
    ) {
      return true;
    }
  }
  return false;
};

export const SHOW_PWA_POPUP_CONFIRMATION = isConfirmationPopup();
export const SHOW_PWA_POPUP_HOME = isHomePWAPopup();

window.token = null;
window.customDomain = null;
window.resKey = null;
window.apptKey = null;
window.MAP_KEY = null;
window.pageDetails = null;
window.messengerAppId = null;
window.isPWAEnabled = null;
window.isStandaloneLoginAllowed = null;
window.pwa = '';
