import React from 'react';
import PropTypes from 'prop-types';
import Image from '../genericComponents/Image';

export default function BannerImage({ bannerUrl, companyDetails }) {
  const bannerImg = bannerUrl.img_serve_url;

  if (
    bannerImg === 'NA' ||
    bannerImg ===
      'https://assets.setmore.com/setmore/images/hero-placeholder.webp'
  ) {
    return null;
  }

  const url = bannerImg && new URL(bannerImg);
  if (url && !url.searchParams.has('crop')) {
    url.searchParams.set('s', 1600);
  }

  return (
    <section className="banner-image">
      {url && (
        <Image src={url.href} alt={companyDetails.name} type="bannerImg" />
      )}
    </section>
  );
}

BannerImage.propTypes = {
  bannerUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
