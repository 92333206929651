import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ContactInfo from './ContactInfo';
import BusinessHours from './BusinessHours';
import Map from './Map';

import CommonFunctions from '../../utils/commonFunctions';
import {
  fetchBusinessHours,
  fetchStaffWorkingHours,
} from '../../redux/actions';
import { H3Title } from '../genericComponents/Title';
import { PAGE_DETAILS, STAFFKEY } from '../../utils/constants';

const CompanyDetails = (props) => {
  useEffect(() => {
    if (!props.businessHours) {
      if (PAGE_DETAILS.isStaffPage && !props.staffWorkingHours) {
        props.fetchStaffWorkingHours(STAFFKEY);
      } else if (
        !PAGE_DETAILS.isStaffPage &&
        props.viewSettings.viewOptions.isHours
      ) {
        props.fetchBusinessHours();
      }
    }
  }, [props.isHours]);

  const address = CommonFunctions.getCompleteAddressWithCommaSeparated(
    props.companyDetails
  );

  const companyDetails = CommonFunctions.getCompanyContactInfo(
    props.companyDetails,
    props.viewSettings.meta.socialMediaLinks
  );

  return (
    !window.isMobile.matches && (
      <section className="information">
        {(!CommonFunctions.isNullOrEmpty(companyDetails) ||
          !CommonFunctions.isNullOrEmpty(address)) && (
          <H3Title title={window.lang.contact_info} />
        )}
        {navigator.userAgent.indexOf('Prerender') === -1 &&
          CommonFunctions.isAddressNotEmpty(
            props.companyDetails.addressDetails
          ) && (
            <Map
              coordinates={props.viewSettings.meta.mapCoordinates}
              address={address}
            />
          )}

        {props.companyDetails && (
          <ContactInfo companyDetails={companyDetails} />
        )}

        {props.viewSettings.viewOptions.isHours &&
          (props.businessHours ||
            (PAGE_DETAILS.isStaffPage && props.staffWorkingHours)) && (
            <BusinessHours
              businessHours={CommonFunctions.constructWorkingHours(
                PAGE_DETAILS.isStaffPage
                  ? props.staffWorkingHours[0]
                  : props.businessHours,
                PAGE_DETAILS.isStaffPage
              )}
            />
          )}
      </section>
    )
  );
};

CompanyDetails.propTypes = {
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isHours: PropTypes.bool,
  staffWorkingHours: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  businessHours: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fetchBusinessHours: PropTypes.func,
  fetchStaffWorkingHours: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    companyDetails: state.companyDetails,
    viewSettings: state.viewSettings,
    staffWorkingHours: state.staffWorkingHours,
    businessHours: state.businessHours,
  };
};
export default connect(mapStateToProps, {
  fetchBusinessHours,
  fetchStaffWorkingHours,
})(CompanyDetails);
