import React from 'react';
import PropTypes from 'prop-types';

import CommonFunctions from '../../utils/commonFunctions';
import Image from '../genericComponents/Image';

import { MAP_KEY } from '../../utils/constants';

const Map = (props) => {
  const url = CommonFunctions.getMapURL(
    props.address,
    props.coordinates,
    MAP_KEY,
    window.isMobile.matches ? '350x142' : '180x120'
  );
  return (
    url && (
      <div className="business-map" data-testid="businessMap">
        <a
          href={CommonFunctions.constructHrefWithAddress(
            props.address,
            props.coordinates
          )}
          rel="noreferrer"
          target="_blank"
        >
          <Image
            src={CommonFunctions.getMapURL(
              props.address,
              props.coordinates,
              MAP_KEY,
              window.isMobile.matches ? '350x142' : '180x120'
            )}
            width={'220px'}
            height={'145px'}
            defaultImage=""
          />
        </a>
      </div>
    )
  );
};

Map.propTypes = {
  coordinates: PropTypes.string,
  address: PropTypes.string,
};
export default Map;
