/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import {
  SUBDOMAIN,
  APPT_KEY,
  PAGE_DETAILS,
  IS_PWA_ENABLED,
} from '../utils/constants';
import Menu from './menu/Menu';
import '../styles/scss/main.scss';
import CompanyDetails from './companyContactDetails/CompanyDetails';
import Loader from './genericComponents/Loader';
import DesktopHeader from './mainContainer/DesktopHeader';
import BannerImage from './mainContainer/BannerImage';
import Image from './genericComponents/Image';
import { H1Title } from './genericComponents/Title';

import {
  fetchViewSettings,
  fetchCompanyDetails,
  fetchSchedulingPolicy,
  fetchCustomer,
  getProvider,
  setSortedStaffList,
  fetchPaymentGatewayDetails,
  fetchEventsByURL,
  setCustomerProfileStep,
  setEventFetchInProgress,
  setLocalTimeZone,
  fetchTimeZoneObjByID,
  fetchAllTimezones,
  // fetchInstagramUserDetails,
  fetchReviewRating,
  // fetchEventTrackingIntegration,
  fetchReminderSettings,
  // fetchBookingPageIntegrations,
  setBookingPath,
  fetchBySessionKey,
  fetchCategory,
  setBookingPage,
  fetchStaffWorkingHours,
  setIsStandaloneApp,
  setIsPWAAlreadyInstalled,
  getAssignedProviders,
  fetchAllBookingPageIntegrations,
} from '../redux/actions';

import Footer from './genericComponents/Footer';
import Sprite from './genericComponents/Sprite';
import CommonFunctions from '../utils/commonFunctions';
import AppUtils from '../utils/appUtils';
import Header from './genericComponents/Header';
import EventTracking from '../utils/eventTracking';
import CustomOutsideClickHandler from './genericComponents/CustomOutsideClickHandler';

const RouteComponent = React.lazy(() => import('./RouteComponent'));

const CustomerProfile = React.lazy(() =>
  import('./customerProfile/CustomerProfile'),
);
const CustomerProfileResponsiveMenu = React.lazy(() =>
  import('./customerProfile/CustomerProfileResponsiveMenu'),
);

const App = (props) => {
  props.setIsStandaloneApp(
    navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches,
  );
  if (!props.bookingPath) {
    props.setBookingPath(CommonFunctions.getBookingPath(SUBDOMAIN));
  }

  useEffect(() => {
    if (PAGE_DETAILS.isSessionPage && !props.classSession) {
      props.fetchBySessionKey(PAGE_DETAILS.sessionKey);
    } else if (
      PAGE_DETAILS.isCategoryPage &&
      (!props.serviceCategory || !props.classCategoryList)
    ) {
      props.fetchCategory(PAGE_DETAILS.categoryKey);
      props.setBookingPage('category');
    }
    if (IS_PWA_ENABLED) {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      window
        .matchMedia('(display-mode: standalone)')
        .addEventListener('change', (evt) => {
          if (evt.matches) {
            props.setIsStandaloneApp(true);
          }
        });

      if (!isIOS) {
        props.setIsPWAAlreadyInstalled(true);

        window.addEventListener('beforeinstallprompt', (e) => {
          e.preventDefault();
          window.deferredPWAEvent = e;
          props.setIsPWAAlreadyInstalled(false);
        });

        window.addEventListener('appinstalled', (e) => {
          e.preventDefault();
          props.setIsPWAAlreadyInstalled(true);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props.isTrackingFetched) {
      if (IS_PWA_ENABLED) {
        const eventParams = {};
        let eventName = 'adp_page_view_source';
        if (!PAGE_DETAILS.isReschedulePage) {
          eventName = 'bp_hp_page_view_source';
          eventParams.pwa_source = CommonFunctions.getEventSource();
        }
        EventTracking.userEngagementTracking(eventName, eventParams);
      }
    }
  }, [props.isTrackingFetched]);

  useEffect(() => {
    props.fetchCompanyDetails(window.companyJson, window.bannerUrl);

    props.fetchViewSettings();
    props.fetchSchedulingPolicy();
    props.fetchReminderSettings();
    props.fetchPaymentGatewayDetails();

    // props.fetchEventTrackingIntegration();
    // props.fetchBookingPageIntegrations();
    props.fetchAllBookingPageIntegrations();
    props.fetchReviewRating(window.rating);
    if (
      (PAGE_DETAILS.isReschedulePage || PAGE_DETAILS.writeReview) &&
      APPT_KEY
    ) {
      props.setEventFetchInProgress(true);
      const url = PAGE_DETAILS.writeReview
        ? `/bookingpage/events/`
        : `/bookingpage/events/manage/`;
      props.fetchEventsByURL(`${url}${APPT_KEY}`, '', [], []);
    }

    // props.fetchInstagramUserDetails();
    if (
      !props.staffList &&
      !PAGE_DETAILS.isReschedulePage &&
      !PAGE_DETAILS.isSessionPage &&
      !PAGE_DETAILS.isServicePage &&
      !PAGE_DETAILS.isClassPage &&
      !PAGE_DETAILS.isCategoryPage
    ) {
      props.getProvider('');
    }
  }, []);

  const [fetchLanguage, setfetchLanguage] = useState(false);

  useEffect(() => {
    if (props.viewSettings) {
      AppUtils.fetchLanguage(props.viewSettings, setfetchLanguage);

      if (
        window.cKey.indexOf('@') === -1 &&
        window.cKey &&
        !PAGE_DETAILS.isReschedulePage
      ) {
        if (props.viewSettings.bookingOption.customerLogin.isActive) {
          props.fetchCustomer(window.cKey);
        } else {
          CommonFunctions.invalidateSession();
        }
      }

      if (props.viewSettings.viewOptions.isLocalTime) {
        props.fetchAllTimezones();
      }
      if (
        !PAGE_DETAILS.isServicePage &&
        !PAGE_DETAILS.isClassPage &&
        !PAGE_DETAILS.isCategoryPage &&
        !PAGE_DETAILS.isSessionPage &&
        !PAGE_DETAILS.isStaffPage
      ) {
        props.fetchStaffWorkingHours(
          '',
          props.viewSettings.meta.resourcesInOrder,
        );
      }
    }
  }, [props.viewSettings]);

  useEffect(() => {
    if (props.classSession && !props.staffList) {
      const staffKey =
        props.classSession.createdBy &&
        props.classSession.provider.indexOf(props.classSession.createdBy) !== -1
          ? props.classSession.createdBy
          : props.classSession.provider[0];
      props.getProvider('', staffKey);
      props.fetchStaffWorkingHours(staffKey);
    }
  }, [props.classSession]);

  useEffect(() => {
    if (props.staffCursor) {
      props.getProvider(props.staffCursor);
    }
  }, [props.staffCursor]);

  useEffect(() => {
    if (
      !CommonFunctions.isNullOrEmpty(props.timeZones) &&
      props.timeZones.length > 0 &&
      props.viewSettings &&
      props.viewSettings.viewOptions.isLocalTime
    ) {
      const localTimeZoneID = AppUtils.getLocalTimeZone();
      props.setLocalTimeZone(localTimeZoneID);

      if (localTimeZoneID) {
        const timeZoneObj =
          !CommonFunctions.isNullOrEmpty(props.timeZones) &&
          props.timeZones.length > 0 &&
          props.timeZones.filter(
            (timezoneobj) =>
              timezoneobj && timezoneobj.timeZoneID === localTimeZoneID,
          );
        if (CommonFunctions.isNullOrEmpty(timeZoneObj)) {
          props.fetchTimeZoneObjByID(localTimeZoneID);
        }
      }
    } else if (!props.timeZones && props.companyDetails) {
      props.fetchTimeZoneObjByID(props.companyDetails.timeZone);
    }
  }, [props.timeZones, props.companyDetails]);

  const [isStaffOrdered, setStaffOrdered] = useState(false);

  useEffect(() => {
    if (
      props.viewSettings &&
      props.serviceList !== 'fetching' &&
      props.staffList.length > 0
    )
      if (
        (props.viewSettings.menu.indexOf('bookAppt') !== -1 &&
          props.serviceList &&
          CommonFunctions.isNullOrEmpty(props.serviceCursor) &&
          isStaffOrdered) ||
        (props.viewSettings.menu.indexOf('bookClass') !== -1 &&
          props.classInventoryRequestProcessed)
      ) {
        props.getAssignedProviders(
          props.serviceList,
          props.classInventoryList,
          props.staffList,
        );
      }
  }, [
    props.serviceList,
    props.serviceCursor,
    isStaffOrdered,
    props.classInventoryRequestProcessed,
    props.viewSettings,
  ]);

  useEffect(() => {
    if (
      CommonFunctions.isNullOrEmpty(props.staffCursor) &&
      props.staffList &&
      props.viewSettings &&
      props.viewSettings.meta.resourcesInOrder &&
      !PAGE_DETAILS.isReschedulePage
    ) {
      const providerList = CommonFunctions.sortByOrder(
        props.staffList,
        props.viewSettings.meta.resourcesInOrder,
        'key',
      );
      props.setSortedStaffList(providerList);
      setStaffOrdered(true);
    }
  }, [props.staffList, props.viewSettings]);

  const hideDropdown = () => {
    document.querySelectorAll('.user-account-dtls').forEach(function (element) {
      element.classList.remove('open');
    });
  };

  return (
    <Router>
      <>
        <div className="App">
          <Sprite />
          {props.companyDetails &&
          props.viewSettings &&
          props.schedulingPolicy &&
          window.lang &&
          fetchLanguage &&
          (!props.isEventFetchInProgress || !PAGE_DETAILS.isReschedulePage) ? (
            <>
              {/* <NotificationBar /> */}
              {!PAGE_DETAILS.isReschedulePage && (
                <CustomOutsideClickHandler
                  onOutsideClick={() => hideDropdown()}
                >
                  <div>
                    <DesktopHeader />
                    <Header />
                  </div>
                </CustomOutsideClickHandler>
              )}

              {!window.isMobile.matches &&
                PAGE_DETAILS.isReschedulePage &&
                (props.customerProfileStep === 'appointmentDetails' ||
                  props.customerProfileStep === 'confirmation') && (
                  <section className="header-rescdl-cancel hide-mob">
                    {props.companyDetails.logo && (
                      <figure className="g-avatar">
                        <Image
                          src={props.companyDetails.logo}
                          alt={props.companyDetails.name}
                        />
                      </figure>
                    )}
                    <article>
                      <H1Title title={props.companyDetails.name} />
                      <address>
                        {CommonFunctions.constructCompanyAddress(
                          props.companyDetails,
                        )}
                      </address>
                    </article>
                  </section>
                )}
              {!window.isMobile.matches &&
                !PAGE_DETAILS.isStaffPage &&
                !props.customerProfileStep &&
                props.customerProfileStep !== 'upcomingAppts' &&
                props.banner &&
                props.banner.size &&
                !CommonFunctions.isNullOrEmpty(props.banner.size) && (
                  <BannerImage
                    bannerUrl={props.banner}
                    companyDetails={props.companyDetails}
                  />
                )}
              <main
                className={`container fx ${
                  props.customerProfileStep &&
                  !window.isMobile.matches &&
                  'mt-4'
                }`}
              >
                {props.customerProfileStep &&
                  props.customerProfileStep !==
                    'customerProfileResponsiveMenu' && (
                    <Suspense fallback={<Loader />}>
                      <CustomerProfile />
                    </Suspense>
                  )}
                {props.customerProfileStep ===
                  'customerProfileResponsiveMenu' && (
                  <Suspense fallback={<Loader />}>
                    <CustomerProfileResponsiveMenu />
                  </Suspense>
                )}

                {props.customerProfileStep === '' && (
                  <>
                    <Menu />
                    <Suspense
                      fallback={
                        <section className="app-container">
                          <article className="main-container about-us">
                            <Loader />
                          </article>
                        </section>
                      }
                    >
                      <RouteComponent />
                    </Suspense>
                    <CompanyDetails />
                  </>
                )}
              </main>
            </>
          ) : (
            <Loader
              type={
                !window.location.hostname.includes('inthechair')
                  ? 'primary'
                  : ''
              }
            />
          )}
        </div>
        <Footer />
      </>
    </Router>
  );
};

App.propTypes = {
  fetchCompanyDetails: PropTypes.func,
  fetchViewSettings: PropTypes.func,
  fetchSchedulingPolicy: PropTypes.func,
  companyDetails: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  viewSettings: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fetchCustomer: PropTypes.func,
  displayResponsiveMenu: PropTypes.bool,
  customerProfileStep: PropTypes.string,
  getProvider: PropTypes.func,
  setSortedStaffList: PropTypes.func,
  staffList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  staffCursor: PropTypes.string,
  fetchPaymentGatewayDetails: PropTypes.func,
  upcomingEvents: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pastEvents: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fetchEventsByURL: PropTypes.func,
  setCustomerProfileStep: PropTypes.func,
  setEventFetchInProgress: PropTypes.func,
  isEventFetchInProgress: PropTypes.bool,
  setLocalTimeZone: PropTypes.func,
  fetchTimeZoneObjByID: PropTypes.func,
  timeZones: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fetchAllTimezones: PropTypes.func,
  // fetchInstagramUserDetails: PropTypes.func,
  clientHashTags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  serviceList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fetchReviewRating: PropTypes.func,
  // fetchEventTrackingIntegration: PropTypes.func,
  fetchReminderSettings: PropTypes.func,
  // fetchBookingPageIntegrations: PropTypes.func,
  setBookingPath: PropTypes.func,
  bookingPath: PropTypes.string,
  schedulingPolicy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fetchBySessionKey: PropTypes.func,
  classSession: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSessionReschedule: PropTypes.bool,
  setBookingPage: PropTypes.func,
  fetchCategory: PropTypes.func,
  bookingPage: PropTypes.string,
  serviceCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  classCategoryList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fetchStaffWorkingHours: PropTypes.func,
  banner: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setIsStandaloneApp: PropTypes.func,
  setIsPWAAlreadyInstalled: PropTypes.func,
  isTrackingFetched: PropTypes.bool,
  getAssignedProviders: PropTypes.func,
  serviceCursor: PropTypes.string,
  fetchAllBookingPageIntegrations: PropTypes.func,
  classInventoryRequestProcessed: PropTypes.bool,
  classInventoryList: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const mapStateToProps = (state) => {
  return {
    viewSettings: state.viewSettings,
    companyDetails: state.companyDetails,
    displayResponsiveMenu: state.displayResponsiveMenu,
    customerProfileStep: state.customerProfileStep,
    staffList: state.staffList,
    staffCursor: state.staffCursor,
    upcomingEvents: state.upcomingEvents ? state.upcomingEvents : [],
    pastEvents: state.pastEvents ? state.pastEvents : [],
    customer: state.customer,
    isEventFetchInProgress: state.isEventFetchInProgress,
    timeZones: state.timeZones,
    clientHashTags: state.clientHashTags,
    serviceList: state.serviceList,
    bookingPath: state.bookingPath,
    schedulingPolicy: state.schedulingPolicy,
    classSession: state.classSession,
    isSessionReschedule: state.isSessionReschedule,
    bookingPage: state.bookingPage,
    serviceCategory: state.serviceCategory,
    classCategoryList: state.classCategoryList,
    banner: state.banner,
    classInventoryList: state.classInventoryList,
    isTrackingFetched: state.isTrackingFetched,
    serviceCursor: state.serviceCursor,
    classInventoryRequestProcessed: state.classInventoryRequestProcessed,
    assignedProviders: state.assignedProviders,
  };
};

export default Sentry.withProfiler(
  connect(mapStateToProps, {
    fetchViewSettings,
    fetchCompanyDetails,
    fetchPaymentGatewayDetails,
    fetchSchedulingPolicy,
    fetchCustomer,
    getProvider,
    setSortedStaffList,
    fetchEventsByURL,
    setCustomerProfileStep,
    setEventFetchInProgress,
    setLocalTimeZone,
    fetchTimeZoneObjByID,
    fetchAllTimezones,
    // fetchInstagramUserDetails,
    fetchReviewRating,
    // fetchEventTrackingIntegration,
    fetchReminderSettings,
    // fetchBookingPageIntegrations,
    setBookingPath,
    fetchBySessionKey,
    fetchCategory,
    setBookingPage,
    fetchStaffWorkingHours,
    setIsStandaloneApp,
    setIsPWAAlreadyInstalled,
    getAssignedProviders,
    fetchAllBookingPageIntegrations,
  })(App),
);
