import React from 'react';
import PropTypes from 'prop-types';
import CommonFunctions from '../../utils/commonFunctions';

export const H1Title = (props) => {
  return <h1 data-testid={props.dataTestId}>{CommonFunctions.htmlDecode(props.title)}</h1>;
};

export const H2Title = (props) => {
  return (
    <h2 data-testid={props.dataTestId ? props.dataTestId : ''}>
      {CommonFunctions.htmlDecode(props.title)}
    </h2>
  );
};

export const H3Title = (props) => {
  return <h3>{CommonFunctions.htmlDecode(props.title)}</h3>;
};

export const H4Title = (props) => {
  return (
    <h4 className={props.selector}>
      {CommonFunctions.htmlDecode(props.title)}
    </h4>
  );
};

export const H5Title = (props) => {
  return <h5>{CommonFunctions.htmlDecode(props.title)}</h5>;
};

export const H6Title = (props) => {
  return <h6>{CommonFunctions.htmlDecode(props.title)}</h6>;
};

H1Title.propTypes = {
  title: PropTypes.string,
  dataTestId: PropTypes.string,
};

H3Title.propTypes = {
  title: PropTypes.string,
  selector: PropTypes.string,
};

H2Title.propTypes = {
  title: PropTypes.string,
  dataTestId: PropTypes.string,
};

H4Title.propTypes = {
  title: PropTypes.string,
  selector: PropTypes.string,
};

H5Title.propTypes = {
  title: PropTypes.string,
};

H6Title.propTypes = {
  title: PropTypes.string,
};
