import axios from 'axios';
import { BASEURL, SE_BASE_URL, SE_HEADERS, HEADERS } from './constants';

const useAPI = async (endPoint, method, payload, baseURL, isSEEndpoint) => {
  const baseEndpoint = isSEEndpoint ? SE_BASE_URL : BASEURL;
  const headers = isSEEndpoint ? SE_HEADERS : HEADERS;
  const requestURL = `${baseURL || baseEndpoint}${endPoint}`;

  if (method === 'GET') {
    try {
      const response = await axios.get(requestURL, headers);
      if (response.status === 200) {
        return response.data.data ? response.data.data : response.data;
      }
    } catch (err) {
      if (endPoint === '/reviewsettings') {
        return { reviewSettings: { showReview: false } };
      }
      return err;
    }
  } else if (method === 'POST') {
    try {
      const response = await axios.post(requestURL, payload, headers);
      if (response.status === 200) {
        if (response.data.response === true) {
          return response.data.data;
        }
        return response;
      }
    } catch (err) {
      return err.response;
    }
  } else if (method === 'PUT') {
    try {
      const response = await axios.put(requestURL, payload, headers);
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (err) {
      return err.response;
    }
  } else if (method === 'DELETE') {
    try {
      const response = await axios.delete(requestURL, headers);
      if (response.status === 200 && response.data.data) {
        return response.data.data;
      }
      return response.data;
    } catch (err) {
      return err;
    }
  }
  return null;
};

export default useAPI;
