import axios from 'axios';

import CommonFunctions from '../../../utils/commonFunctions';
import PreConfirmationUtils from '../preconfirmation/PreConfirmationUtils';
import {
  TOKEN,
  STAFFKEY,
  PAGE_DETAILS,
  APP_VERSION,
} from '../../../utils/constants';

const getInputFields = (field, customisedLabels) => {
  const getInputObj = (label, placeholder, type, errorMsg, warningMsg) => {
    let fieldName = CommonFunctions.htmlDecode(field.title);
    const fieldTitle = CommonFunctions.htmlDecode(field.title);

    if (
      !CommonFunctions.isNullOrEmpty(fieldName) &&
      fieldName.match(/[."|'[]|\]/g)
    ) {
      fieldName = fieldName.replace(/[."|'[]|\]/g, '');
    }

    let isFieldRequired = field.isRequired;
    if (typeof isFieldRequired !== 'boolean') {
      isFieldRequired = false;
    }
    return {
      name: fieldName,
      label: CommonFunctions.htmlDecode(label).trim(),
      placeholder: CommonFunctions.htmlDecode(placeholder),
      type,
      isRequired: isFieldRequired,
      title: fieldTitle,
      errorMsg,
      warningMsg,
    };
  };

  if (field && field.title) {
    const name =
      customisedLabels && customisedLabels.CustomerName
        ? customisedLabels.CustomerName
        : `${window.lang.your_name}`;
    const city =
      customisedLabels && customisedLabels.City
        ? customisedLabels.City
        : `${window.lang.city}`;
    const state =
      customisedLabels && customisedLabels.State
        ? customisedLabels.State
        : `${window.lang.state}`;
    const zip =
      customisedLabels && customisedLabels.Zip
        ? customisedLabels.Zip
        : `${window.lang.zip}`;

    switch (field.title) {
      case 'name':
        return getInputObj(
          name,
          window.lang.first_last_name,
          'text',
          `${window.lang.please_provide} ${name.toLowerCase()}`
        );
      case 'email':
        return getInputObj(
          window.lang.email,
          window.lang.email_domain,
          'email',
          window.lang.email_req,
          window.lang.invalid_email
        );
      case 'phone':
        return getInputObj(
          window.lang.phone,
          'Phone number',
          'tel',
          window.lang.phone_req,
          window.lang.invalid_number
        );
      case 'comments':
        return getInputObj(
          window.lang.comments,
          window.lang.add_info,
          'text',
          ''
        );
      case 'address':
        return getInputObj(
          window.lang.addr,
          window.lang.building_street,
          'text',
          window.lang.addr_req
        );
      case 'city':
        return getInputObj(
          city,
          window.lang.city_info,
          'text',
          `${city} ${window.lang.req}`
        );
      case 'state':
        return getInputObj(
          state,
          window.lang.state_info,
          'text',
          `${state} ${window.lang.req}`
        );
      case 'zip':
        return getInputObj(
          zip,
          'XXXXXX',
          'text',
          `${zip} ${window.lang.req}`,
          'Invalid zip code'
        );
      case 'password':
        return getInputObj(
          window.lang.password,
          field.placeholder,
          'password',
          window.lang.pwd_req
        );
      default:
        return getInputObj(
          CommonFunctions.htmlDecode(field.title),
          CommonFunctions.htmlDecode(field.title),
          'text',
          `${CommonFunctions.htmlDecode(field.title)} ${window.lang.req}`
        );
    }
  }
  return null;
};

const checkForDefaultInputFeilds = (inputFields) => {
  const defaultFeilds = ['name', 'phone', 'email', 'address'];
  const required = (field) => {
    if (field === 'name') return true;
    return false;
  };
  let index = 0;

  for (const field of defaultFeilds) {
    if (inputFields.findIndex((e) => e.title === field) === -1) {
      inputFields.splice(index, 0, {
        title: field,
        canView: true,
        isRequired: required(field),
      });
    }
    index += 1;
  }
  return inputFields;
};

const constructInputFieldsForCustomer = (inputFields, customisedLabels) => {
  if (inputFields && inputFields.length > 0) {
    checkForDefaultInputFeilds(inputFields);
    let addressIndex = inputFields.findIndex((e) => e.title === 'address') + 1;
    const address = inputFields.filter((e) => e.title === 'address');
    const required =
      address && address.length > 0 ? address[0].isRequired : false;
    if (
      address.length === 1 &&
      address[0].canView &&
      inputFields.filter((e) => e.title === 'city').length === 0
    ) {
      inputFields.splice(addressIndex, 0, {
        title: 'city',
        canView: true,
        isRequired: required,
      });
      addressIndex += 1;
      inputFields.splice(addressIndex, 0, {
        title: 'state',
        canView: true,
        isRequired: required,
      });
      addressIndex += 1;
      inputFields.splice(addressIndex, 0, {
        title: 'zip',
        canView: true,
        isRequired: required,
      });
    }

    return inputFields.map((field) => {
      return field.canView && getInputFields(field, customisedLabels);
    });
  }
  return null;
};

const constructInputFields = (inputFields, customisedLabels) => {
  if (inputFields) {
    const addressIndex =
      inputFields.findIndex((e) => e.title === 'address') + 1;

    if (inputFields.filter((e) => e.title === 'comments').length === 0) {
      inputFields.splice(addressIndex, 0, {
        title: 'comments',
        canView: true,
      });
    }
  }
  return constructInputFieldsForCustomer(inputFields, customisedLabels);
};

const updateAdditionalFields = (customerObj, fields) => {
  const additionalFields = {};
  if (fields) {
    const allFields = fields.map((field) => {
      return CommonFunctions.htmlDecode(field.title);
    });
    Object.keys(customerObj).forEach((key) => {
      if (
        key !== 'name' &&
        key !== 'email' &&
        key !== 'address' &&
        key !== 'city' &&
        key !== 'state' &&
        key !== 'zip' &&
        key !== 'comments'
      ) {
        if (allFields.indexOf(key) !== -1) {
          additionalFields[CommonFunctions.htmlEscape(key)] = customerObj[key];
        } else {
          const fieldsWithSplChar = allFields.filter((field) => {
            const match = field.match(/[."|'[]|\]/g, '');
            return field.indexOf(match && match[0]) !== -1;
          });

          const fieldWithSplChar = fieldsWithSplChar.filter(
            (fiel) => fiel.replace(/[."|'[]|\]/g, '') === key
          );

          additionalFields[CommonFunctions.htmlEscape(fieldWithSplChar)] =
            customerObj[key];
        }
      }
    });
  }
  return additionalFields;
};

const constructCustomerObj = (
  customerObj,
  number,
  country,
  customer,
  trim,
  fields
) => {
  let previousCust = {};
  if (customerObj) {
    if (customer && (window.cKey || customer.key)) {
      previousCust = { ...customer };
    }
    const email = customerObj.email
      ? customerObj.email
      : (previousCust.key && previousCust.LoginId) || '';
    const firstName = customerObj.name
      ? customerObj.name.trim().split(' ')[0]
      : (previousCust.key &&
          previousCust.FirstName &&
          previousCust.FirstName) ||
        '';
    const lastName = customerObj.name
      ? customerObj.name.replace(firstName, '')
      : (previousCust.key && previousCust.LastName) || '';
    const additionalFields = updateAdditionalFields(customerObj, fields);

    if (customer && (window.cKey || customer.key)) {
      previousCust.FirstName = firstName.trim();
      previousCust.LastName = lastName.trim();
      previousCust.LoginId = email.trim();
      previousCust.MobileNo = number;
      previousCust.Address = customerObj.address;
      previousCust.City = customerObj.city;
      previousCust.State = customerObj.state;
      previousCust.Zip = customerObj.zip;
      previousCust.additionalFields = additionalFields && {
        value: JSON.stringify(additionalFields),
      };
      previousCust.Country = country && country.name ? country.name : 'usa';
      previousCust.countryCode =
        country && country.dialCode ? `+${country.dialCode}` : '+1';
      return previousCust;
    }

    const createdCustomer = {
      key: previousCust.key || null,
      FirstName: firstName,
      LastName: lastName,
      LoginId: email,
      MobileNo: number,
      Address: customerObj.address || '',
      F_Key: TOKEN,
      ContactType: 'Customer',
      Status: 'Active',
      CompanyLogoPath: (previousCust.key && previousCust.CompanyLogoPath) || '',
      Location: (previousCust.key && previousCust.Location) || '',
      City: customerObj.city || '',
      State: customerObj.state || '',
      Zip: customerObj.zip || '',
      Country: country?.name ? country.name : 'usa',
      countryCode: country?.dialCode ? `+${country.dialCode}` : '+1',
      additionalFields: additionalFields && {
        value: JSON.stringify(additionalFields),
      },
      EncryptedPassword:
        (previousCust.key && previousCust.EncryptedPassword) || '',
      isNewCustomer: !previousCust.key || true,
      loginType: (previousCust.key && previousCust.loginType) || [],
      haveEmailReminderEnabled: customer.haveEmailReminderEnabled,
      haveTextReminderEnabled: customer.haveTextReminderEnabled,
      isCustomerUpdated: !!previousCust.key,
      // isNewCustomerTemp: true,
    };
    if (APP_VERSION === 'v2') {
      createdCustomer.source = PreConfirmationUtils.sourceOfBooking();
    }
    if (trim) {
      createdCustomer.FirstName = createdCustomer.FirstName.trim();
      createdCustomer.LastName = createdCustomer.LastName.trim();
      createdCustomer.Address = createdCustomer.Address.trim();
      createdCustomer.City = createdCustomer.City.trim();
      createdCustomer.State = createdCustomer.State.trim();
      createdCustomer.Zip = createdCustomer.Zip.trim();
    }
    return createdCustomer;
  }
  return null;
};

const compareCustomer = (customer, previousCustomer) => {
  const newCustomer = {};
  let isSame = false;

  if (previousCustomer) {
    const checkAndSetValue = (field) => {
      let prevValue = previousCustomer[field];
      let currentValue = customer[field];
      if (CommonFunctions.isNullOrEmpty(previousCustomer[field])) {
        prevValue = '';
      }
      if (CommonFunctions.isNullOrEmpty(customer[field])) {
        currentValue = '';
      }
      if (
        !CommonFunctions.isNullOrEmpty(previousCustomer[field]) &&
        typeof previousCustomer[field] === 'string'
      ) {
        prevValue = previousCustomer[field].trim();
      }
      if (
        !CommonFunctions.isNullOrEmpty(customer[field]) &&
        typeof customer[field] === 'string'
      ) {
        currentValue = customer[field].trim();
      }
      if (prevValue !== currentValue) {
        newCustomer[field] = currentValue;
        isSame = false;
      }
    };

    isSame = true;
    checkAndSetValue('FirstName');
    checkAndSetValue('LastName');
    checkAndSetValue('LoginId');
    checkAndSetValue('MobileNo');
    checkAndSetValue('Address');
    checkAndSetValue('City');
    checkAndSetValue('State');
    checkAndSetValue('Zip');
    checkAndSetValue('Country');
    checkAndSetValue('countryCode');
    checkAndSetValue('CompanyLogoPath');
    checkAndSetValue('Location');
    checkAndSetValue('loginType');
    checkAndSetValue('haveEmailReminderEnabled');
    checkAndSetValue('haveTextReminderEnabled');

    if (customer.additionalFields) {
      if (previousCustomer.additionalFields) {
        if (
          previousCustomer.additionalFields.value !==
          customer.additionalFields.value
        ) {
          newCustomer.additionalFields = customer.additionalFields;
          isSame = false;
        }
      } else if (
        Object.keys(JSON.parse(customer.additionalFields.value)).length > 0
      ) {
        newCustomer.additionalFields = customer.additionalFields;
        isSame = false;
      }
    }
  }
  if (customer && customer.loginType)
    newCustomer.loginType = customer.loginType;

  return { newCustomer, isSame };
};

const setSession = async (customerKey) => {
  window.cKey = customerKey;
  let setSessionRequestURL = `${window.location.origin}/scheduleappointment/setSession?customerKey=${customerKey}&companyKey=${TOKEN}`;
  setSessionRequestURL = PAGE_DETAILS.isStaffPage
    ? `${setSessionRequestURL}&staffKey=${STAFFKEY}`
    : setSessionRequestURL;
  await axios.get(setSessionRequestURL);
};

const CustomerUtils = {
  getInputFields,
  constructInputFieldsForCustomer,
  constructInputFields,
  constructCustomerObj,
  compareCustomer,
  setSession,
};

export default CustomerUtils;
