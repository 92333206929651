import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import { H3Title } from '../genericComponents/Title';
import { PAGE_DETAILS } from '../../utils/constants';
import CommonFunctions from '../../utils/commonFunctions';

const BusinessHours = (props) => {
  const tzShortName =
    props.viewSettings.viewOptions.isLocalTime &&
    props.timeZones &&
    CommonFunctions.getObject(
      props.timeZones,
      props.companyDetails.timeZone,
      'timeZoneID'
    );

  const timezoneShrtName = tzShortName
    ? ` (${tzShortName.timeZoneShortName})`
    : '';

  const title = PAGE_DETAILS.isStaffPage
    ? window.lang.work_hours
    : window.lang.business_hours;

  return (
    <div className="business-hours" data-testid="businessHours">
      <H3Title title={`${title}${timezoneShrtName}`} />
      <ul>
        {props.businessHours &&
          props.businessHours.map((workingDays, index) => (
            <li
              key={workingDays.title}
              className={
                DateTime.fromJSDate(new Date()).setZone(
                  props.companyDetails.timeZone
                ).weekday ===
                index + 1
                  ? 'active'
                  : ''
              }
            >
              <label>{workingDays.title}</label>
              <span>
                {props.timeFormat === 24 &&
                workingDays.value !== window.lang.closed &&
                workingDays.value !== window.lang.day_off
                  ? `${DateTime.fromFormat(
                      workingDays.value.split('-')[0].trim(),
                      'h:mm a'
                    ).toFormat('T')} - ${DateTime.fromFormat(
                      workingDays.value.split('-')[1].trim(),
                      'h:mm a'
                    ).toFormat('T')}`
                  : workingDays.value}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

BusinessHours.propTypes = {
  businessHours: PropTypes.array.isRequired,
  timeFormat: PropTypes.number,
  companyDetails: PropTypes.object,
  viewSettings: PropTypes.object,
  timeZones: PropTypes.array,
};
const mapStateToProps = (state) => {
  return {
    timeFormat: state.viewSettings.viewOptions.timeFormat,
    companyDetails: state.companyDetails,
    viewSettings: state.viewSettings,
    timeZones: state.timeZones,
  };
};

export default connect(mapStateToProps)(BusinessHours);
